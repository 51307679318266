import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import TooltipComponent from 'components/popover/TooltipComponent';

const useStyles = createUseStyles((theme) => ({
    container: {
        background: ({ background, secondary }) =>
            secondary ? '#ffffff' : background || theme.color.sidebarBackground,
        border: ({ background, secondary }) =>
            !background || secondary ? '1px solid #c0c0c0' : 'none',
        borderRadius: 10,
        padding: ({ padding }) => padding || 12,
        cursor: 'pointer',
        lineHeight: '26px',
        outline: 'none !important',
        height: ({ height }) => height,
        width: ({ width }) => width,
        transition: 'background-color 0.2s',
        '&:hover': {
            background: ({ backgroundHover, secondary }) =>
                backgroundHover || (!secondary ? '#ffffff' : theme.color.sidebarBackground),
            border: ({ background, secondary }) =>
                !background && !secondary ? '1px solid #c0c0c0' : 'none',
            color: ({ labelColor }) => labelColor || '#424147'
        },
        ...theme.typography.button,
        color: ({ labelColor }) => labelColor || ''
    },
    disabled: {
        background: '#ffffff',
        border: '1px solid #c0c0c0',
        borderRadius: 10,
        padding: 12,
        lineHeight: '26px',
        cursor: 'not-allowed',
        outline: 'none',
        color: 'rgba(0, 0, 0, 0.3)',
        height: ({ height }) => height,
        width: ({ width }) => width
    },
    disabledForFiFree: {
        color: '#302e37',
        fontSize: 18,
        fontWeight: '500',
        lineHeight: '25.74px',
        backgroundColor: '#d9d9d9 !important',
        border: 'none',
        borderRadius: 10,
        padding: '8px 20px !important',
        cursor: 'not-allowed',
        outline: 'none',
        height: ({ height }) => height,
        width: ({ width }) => width
    },
    tooltipDiv: {
        width: '100%',
        height: '100%',
        zIndex: 10,
        '& > .bapple-tooltip-container': {
            width: '100%',
            '& > span': {
                float: 'right'
            }
        }
    },
    tooltip: {
        paddingLeft: 10,
        width: '300px',
        top: '60%',
        left: 'unset',
        right: 0,
        transform: 'translateY(calc(-100% - 40px)) translateX(calc(30px))',
        '& > .bapple-tooltip-arrow': {
            left: '260px'
        }
    }
}));

function ButtonComponent({
    className = '',
    children,
    height = 50,
    width,
    secondary,
    background,
    backgroundHover,
    labelColor,
    disabled,
    isReportDisabled,
    disabledMessage,
    disabledClassName = '',
    padding,
    ...props
}) {
    const theme = useTheme();
    const classes = useStyles({
        theme,
        height,
        labelColor,
        secondary,
        background,
        backgroundHover,
        width,
        padding
    });

    const classNameButton =
        disabledClassName !== ''
            ? [disabledClassName, className].join(' ')
            : disabled
            ? [classes.disabled, className].join(' ')
            : isReportDisabled
            ? [classes.disabledForFiFree, className].join(' ')
            : [className, classes.container].join(' ');

    const btnDisabled = disabled || isReportDisabled ? true : false;

    return (
        <button className={classNameButton} disabled={btnDisabled} {...props}>
            {!isReportDisabled && children}
            {isReportDisabled && (
                <div className={classes.tooltipDiv}>
                    <TooltipComponent
                        type='lock'
                        content={children}
                        message={disabledMessage}
                        tooltipClassName={classes.tooltip}
                        isContentWithIcon={true}
                        iconStyleName={classes.iconStyle}
                        size={20}
                    />
                </div>
            )}
        </button>
    );
}

export default ButtonComponent;
