import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 42}
        height={props.height || 42}
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <circle cx='19.3918' cy='19.3918' r='19.3918' fill='#FFB282' />
        <line
            x1='18.9526'
            y1='12.7185'
            x2='18.9526'
            y2='26.3247'
            stroke='white'
            strokeWidth='3'
            strokeLinecap='round'
        />
        <line
            x1='25.7397'
            y1='16.0396'
            x2='25.7397'
            y2='23.0033'
            stroke='white'
            strokeWidth='3'
            strokeLinecap='round'
        />
        <line
            x1='12.1655'
            y1='16.0396'
            x2='12.1655'
            y2='23.0033'
            stroke='white'
            strokeWidth='3'
            strokeLinecap='round'
        />
    </svg>
);

export default forwardRef(Icon);
