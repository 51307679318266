export default function initializeDelighted() {
    const initialize = function (e, t, r, n) {
        if (!e[n]) {
            const a = (e[n] = []);
            const i = [
                'survey',
                'reset',
                'config',
                'init',
                'set',
                'get',
                'event',
                'identify',
                'track',
                'page',
                'screen',
                'group',
                'alias'
            ];

            for (let s = 0; s < i.length; s++) {
                const c = i[s];
                a[c] =
                    a[c] ||
                    (function (e) {
                        return function () {
                            const t = Array.prototype.slice.call(arguments);
                            a.push([e, t]);
                        };
                    })(c);
            }

            a.SNIPPET_VERSION = '1.0.1';

            const o = t.createElement('script');
            o.type = 'text/javascript';
            o.async = true;
            o.src =
                'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
                r +
                '/' +
                n +
                '.js';

            const p = t.getElementsByTagName('script')[0];
            p.parentNode.insertBefore(o, p);
        }
    };

    initialize(window, document, 'KJvFNPewIFn1xXzU', 'delighted');
}
