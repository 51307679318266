import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import useUsefulLinks from 'hooks/useUsefulLinks';
import SLUGS from 'resources/slugs';

function usePredictions(isUsingDemoData) {
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    const {
        data = {},
        error,
        isLoading
    } = useQuery(
        ['predictionInsights', isUsingDemoData],
        () => fetchAuth(`/elements?type=prediction_insights&is_demo_data=${isUsingDemoData}`),
        {
            retry: false,
            refetchOnMount: true,
            onError: (err) => {
                Sentry.captureException(err, {
                    tags: ['prediction_insights', isUsingDemoData]
                });
                console.log(err);
                push(SLUGS.error404);
            }
        }
    );
    return {
        isLoading,
        error,
        data
    };
}

export default function usePredictionsInsights(isUsingDemoData) {
    const { data: predictionResult = {}, isLoading: isLoadingWelcome } =
        usePredictions(isUsingDemoData);
    const { data: { links = [] } = {}, isLoading: isLoadingLinks } =
        useUsefulLinks('prediction_insights');
    const isLoading = isLoadingWelcome || isLoadingLinks;
    const { insight_items } = predictionResult || {};
    return {
        isLoading,
        data: {
            links,
            insight_items
        }
    };
}
