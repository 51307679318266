import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column } from 'components/simpleFlexbox';

const useStyles = createUseStyles({
    '@keyframes loadingSpin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
    },
    containerSpinner: {
        background: 'none',
        height: '100%',
        minHeight: '100%',
        width: '100%',
        position: 'relative',
        top: 0,
        left: 0,
        zIndex: ({ zIndex }) => zIndex
    },
    loadingSpinner: {
        border: ({ theme, borderWidth, secondaryColor }) =>
            `${Math.round(borderWidth)}px solid ${secondaryColor || theme.color.lightGrayishBlue}`,
        borderRadius: '50%',
        borderTop: ({ theme, borderWidth, primaryColor }) =>
            `${Math.round(borderWidth)}px solid ${primaryColor || theme.color.sidebarBackground}`,
        width: ({ height, width }) => width || height,
        height: ({ height, width }) => height || width,
        animationName: '$loadingSpin',
        animationTimingFunction: 'linear',
        animationDuration: '2s',
        animationIterationCount: 'infinite'
    }
});

function SpinnerComponent({ height, loading, primaryColor, secondaryColor, width, zIndex }) {
    const theme = useTheme();
    const classes = useStyles({
        borderWidth: Math.round((height || width || 40) / 5),
        height,
        primaryColor,
        secondaryColor,
        theme,
        width,
        zIndex
    });
    return loading ? (
        <div style={{ position: 'relative', height, width }}>
            <Column className={classes.containerSpinner} horizontal='center' vertical='center'>
                <div className={classes.loadingSpinner}></div>
            </Column>
        </div>
    ) : null;
}

export default SpinnerComponent;
