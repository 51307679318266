import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import LoadingComponent from 'components/loading';
import CalendarIframeModal from 'components/hubspotCalendar/CalendarIframeModal';
import JoyrideComponent from './onboarding/tour/JoyrideComponent';
import FiFreeWelcomeModal from './onboarding/welcome/FiFreeWelcomeModal';
import NonFiFreeWelcomeModal from './onboarding/welcome/NonFiFreeWelcomeModal';
import InitialProductModal from './onboarding/welcome/InitialProductModal';
import GoToCalendarIframeModal from './onboarding/welcome/GoToCalendarIframeModal';
import AIAssistWelcomeModal from './onboarding/welcome/AIAssistWelcomeModal';

function MainModal() {
    const { replace } = useHistory();
    const settingsParams = [
        'onboarding_demo_completed',
        'client_name',
        'client_status',
        'signup_page',
        'crm_type',
        'declared_organisation_name',
        'estimated_donors_count',
        'initial_product_preference'
    ];
    const {
        data: {
            client_name,
            onboarding_demo_completed,
            signup_page,
            client_status,
            crm_type,
            declared_organisation_name,
            estimated_donors_count,
            initial_product_preference
        } = {},
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });

    const { search } = useLocation();
    const {
        showOnboardingTour,
        showSelectProduct: showSelectProductQuery,
        showFiFreeWelcomeModal,
        showCalendar
    } = queryString.parse(search);
    const { isLoading: isLoadingCRMTypes, data: crm_types = [] } = useCRMTypes();
    const [skipCalendar, setSkipCalendar] = useState(false);
    const [showSelectMeeting, setShowSelectMeeting] = useState(false);
    const [modalType, setModalType] = useState(null);
    const crms_with_fi_free = crm_types
        .filter((t) => t.self_onboarding_fi_free === true && !!t.onboarding_order)
        .map((t) => t.crm_type);

    const crms_we_support = ['bb_crm', 'bb_re7', 'bb_nxt', 'dynamics', 'donorfy', 'sf_npsp'];
    const shouldShowCalendarIframe =
        declared_organisation_name &&
        client_name === 'DEMO' &&
        crms_we_support.includes(crm_type) &&
        estimated_donors_count >= 10000 &&
        !initial_product_preference;
    const showSelectProduct =
        showSelectProductQuery ||
        (declared_organisation_name &&
            client_name === 'DEMO' &&
            estimated_donors_count < 10000 &&
            !initial_product_preference);
    const showAIAssistWelcomeModal =
        !showSelectProductQuery && !showOnboardingTour && signup_page === 'ai_assist';
    const isLoading = isLoadingClientSettings || isLoadingCRMTypes;

    useEffect(() => {
        if (!crm_types.length) {
            return;
        }
        if (onboarding_demo_completed === false) {
            if (client_status !== 'INTEGRATION_COMPLETED' && client_name !== 'DEMO') {
                if (signup_page === 'fi_free') {
                    const selectedCRMIsIncluded = crms_with_fi_free.includes(crm_type);
                    if (selectedCRMIsIncluded) {
                        replace(convertSlugToUrl(SLUGS.accountSetup, { step: 0 }));
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboarding_demo_completed, client_status, client_name, crm_type]);

    useEffect(() => {
        if (onboarding_demo_completed === false) {
            if (showAIAssistWelcomeModal) {
                setModalType('AIAssistWelcomeModal');
            } else if (showSelectProduct) {
                setModalType('InitialProductModal');
                if (!showSelectProductQuery) {
                    replace(SLUGS.home);
                }
            } else if (showOnboardingTour) {
                setModalType('JoyrideComponent');
            } else if (!declared_organisation_name && client_name === 'DEMO') {
                setModalType('NonFiFreeWelcomeModal');
            } else if (!skipCalendar && shouldShowCalendarIframe && !showSelectMeeting) {
                setModalType('GoToCalendarIframeModal');
            } else if (!skipCalendar && shouldShowCalendarIframe && showSelectMeeting) {
                setModalType('CalendarIframeModal');
            } else if (skipCalendar && shouldShowCalendarIframe) {
                setModalType('InitialProductModal');
                replace(SLUGS.home);
            } else {
                setModalType('JoyrideComponent');
            }
        } else if (showSelectProduct) {
            setModalType('InitialProductModal');
        } else if (showOnboardingTour) {
            setModalType('JoyrideComponent');
        } else if (showCalendar) {
            setModalType('CalendarIframeModal');
        } else if (showFiFreeWelcomeModal) {
            setModalType('FiFreeWelcomeModal');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        crm_type,
        skipCalendar,
        shouldShowCalendarIframe,
        onboarding_demo_completed,
        showSelectProduct,
        initial_product_preference,
        showOnboardingTour,
        showCalendar,
        showFiFreeWelcomeModal,
        showSelectMeeting
    ]);

    function onSkipCalendarClick(e) {
        e.preventDefault();
        setSkipCalendar(true);
        setShowSelectMeeting(false);
    }

    function onGoToCalendarClick(e) {
        e.preventDefault();
        setShowSelectMeeting(true);
    }

    let Modal = null;
    switch (modalType) {
        case 'InitialProductModal':
            Modal = InitialProductModal;
            break;
        case 'JoyrideComponent':
            Modal = JoyrideComponent;
            break;
        case 'FiFreeWelcomeModal':
            Modal = FiFreeWelcomeModal;
            break;
        case 'NonFiFreeWelcomeModal':
            Modal = NonFiFreeWelcomeModal;
            break;
        case 'CalendarIframeModal':
            Modal = CalendarIframeModal;
            break;
        case 'GoToCalendarIframeModal':
            Modal = GoToCalendarIframeModal;
            break;
        case 'AIAssistWelcomeModal':
            Modal = AIAssistWelcomeModal;
            break;
        default:
            Modal = null;
    }

    return (
        <>
            <LoadingComponent loading={isLoading} />
            {Modal && <Modal onNext={onSkipCalendarClick} onGoToCalendar={onGoToCalendarClick} />}
        </>
    );
}

export default MainModal;
