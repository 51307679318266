import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
    arrow: {
        borderBottom: '10px solid transparent',
        borderRight: ({ theme }) => `10px solid ${theme.color.sidebarIconColor}`,
        borderTop: '10px solid transparent',
        height: 0,
        width: 0
    },
    arrowDown: {
        position: 'absolute',
        bottom: '-10px',
        left: '20px',
        borderLeft: '10px solid transparent',
        borderTop: ({ theme }) => `10px solid ${theme.color.sidebarIconColor}`,
        borderRight: '10px solid transparent',
        height: 0,
        width: 0
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        top: ({ top }) => top,
        left: ({ left }) => left,
        position: 'absolute',
        width: ({ width }) => width,
        zIndex: 11
    },
    content: {
        backgroundColor: ({ theme }) => theme.color.sidebarIconColor,
        borderRadius: 5,
        color: 'white',
        marginTop: 4,
        padding: '10px 12px'
    }
});

function PopoverComponent({
    itemRef,
    text,
    left = '115%',
    top = '-45%',
    width = '450px',
    arrowPosition = 'left',
    children,
    onChange,
    hideOnClose = false
}) {
    const theme = useTheme();
    const classes = useStyles({ theme, left, top, width });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const localItemRef = itemRef.current;
        const handleMouseOver = () => {
            onChange && onChange(true);
            setOpen(true);
        };
        const handleMouseLeave = () => {
            onChange && onChange(false);
            setOpen(false);
        };
        if (localItemRef) {
            localItemRef.addEventListener('mouseover', handleMouseOver);
            localItemRef.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                if (localItemRef) {
                    localItemRef.removeEventListener('mouseover', handleMouseOver);
                    localItemRef.removeEventListener('mouseleave', handleMouseLeave);
                }
            };
        }
    }, [itemRef, onChange]);

    function onClick() {
        setOpen(false);
    }

    if (!open && !hideOnClose) {
        return null;
    }

    if (children) {
        return (
            <div onClick={onClick} style={open ? {} : { display: 'none' }}>
                {children}
            </div>
        );
    }

    return children ? (
        children
    ) : (
        <div className={classes.container}>
            {arrowPosition === 'left' && <div className={classes.arrow} />}
            <div className={classes.content}>
                <span>{text}</span>
            </div>
            {arrowPosition === 'bottom' && <div className={classes.arrowDown} />}
        </div>
    );
}

export default PopoverComponent;
