import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='10 10 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g transform='translate(12, 13) scale(0.06)'>
            <path
                d='M469.2,0.396H20.8c-11.4,0-20.8,9.4-20.8,20.8v283c0,11.4,9.4,20.8,20.8,20.8h184.3l-57.4,135.3c-5.2,10.4,0,22.9,10.4,27
			c10.4,5.2,22.9,0,27-10.4l59.2-139.6l59.4,140.1c5.2,10.4,16.6,14.6,27,10.4c10.4-5.2,14.6-16.6,10.4-27l-57.6-135.7H468
			c11.4,0,20.8-9.4,21.8-20.8v-283C490,9.796,480.6,0.396,469.2,0.396z M448.4,283.396H40.6v-242.4h407.8V283.396z'
                fill={props.fill || '#424147'}
            />
            <path
                d='M74.9,256.296c6,5.2,18,9.2,26-1l55.1-62.4l54.1,49.9c9.9,6.5,21.3,8.5,30.2-2.1l82.2-96.8l16.6,29.1
			c8,14,25.6,13.1,34.3,2.1l42.7-55.1c7.3-8.3,6.2-20.8-3.1-28.1c-8.3-7.3-20.8-6.2-28.1,3.1l-23.9,30.3l-15.6-28.1
			c-8.9-16.4-28.1-10.4-33.3-3.1l-87.4,103l-54.1-49.9c-7-6.7-20.8-7.9-29.1,2.1l-68.7,78C65.5,236.596,66.6,249.096,74.9,256.296z'
                fill={props.fill || '#424147'}
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
