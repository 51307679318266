import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import ButtonComponent from 'components/form/ButtonComponent';
import ThumbsupImg from 'assets/thumbsup.png';
import useUserActivity from 'hooks/useUserActivity';

const useStyles = createUseStyles((theme) => ({
    leftBar: {
        width: 100,
        background: '#FFEBE8',
        borderRadius: '10px 0px 0px 10px'
    },
    container: {
        padding: '90px 70px 35px 70px',
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: '100%'
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center',
        marginBottom: 20
    },
    modalText: {
        textAlign: 'center',
        marginBottom: 30
    },
    thumbsupImg: {
        width: '100%',
        marginBottom: 30,
        '& > img': {
            margin: '0 auto'
        }
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        container: {
            padding: '40px 30px 30px 40px'
        },
        '#go_to_meeting_iframe': {
            width: '80%'
        },
        leftBar: {
            width: 75
        }
    }
}));

function GoToCalendarIframeModal({ onGoToCalendar }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });

    const { addUserActivity } = useUserActivity();
    useEffect(() => {
        try {
            addUserActivity({
                event_name: 'onboarding_tour',
                event_payload: {
                    modal_name: 'offer_free_onboarding_session',
                    modal_component: 'GoToCalendarIframeModal'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            isOpen
            onRequestClose={() => {}}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 968,
                    maxHeight: 880,
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            id='go_to_meeting_iframe'
            ariaHideApp={false}
        >
            <Row>
                <div className={classes.leftBar}></div>
                <Column className={classes.container}>
                    <h2 className={classes.title} id='go_to_meeting_iframe_title'>
                        Congratulations
                    </h2>
                    <div className={classes.modalText}>
                        Based on your responses, we think you would be a great fit and would like to
                        offer you a free onboarding session with one of our nonprofit specialists.
                        Please book a time suitable to you in the page below, or click Next.
                    </div>
                    <div className={classes.thumbsupImg}>
                        <img src={ThumbsupImg} alt='dataro thumbs up' />
                    </div>
                    <Row horizontal='center' className={classes.buttonContainer}>
                        <ButtonComponent width={164} onClick={onGoToCalendar}>
                            Next
                        </ButtonComponent>
                    </Row>
                </Column>
            </Row>
        </Modal>
    );
}

export default GoToCalendarIframeModal;
