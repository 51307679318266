const defaultFormValues = {
    chart_data: {},
    agency_cost: 0,
    appeal_type: 'default',
    average_cost_per_connected_call: 0,
    average_gift_size_from_past_campaign: 0,
    average_number_of_future_upgrade_gifts: 12,
    average_propensity_score: 0,
    average_upgrade_value: 0,
    campaign_cost: 0,
    campaign_size: 1,
    campaign_size_range: 1,
    campaign_type: 'Loading...',
    connected_call_cost: 0,
    cost_per_piece: 0,
    expected_net_return: 0,
    expected_response_rate: 0,
    max_campaign_size: 10000,
    max_cost_per_piece: 10.0,
    method: 'default',
    model_predicted_gift_size: 0,
    predicted_campaign_return: 0,
    rate_of_connected_calls: 0,
    reference_past_campaign: 'default'
};

const defaultFormDisplay = {
    agency_cost: false,
    appeal_type: false,
    average_cost_per_connected_call: false,
    average_gift_size_from_past_campaign: false,
    average_number_of_future_upgrade_gifts: false,
    average_propensity_score: false,
    average_upgrade_value: false,
    campaign_cost: false,
    campaign_size: true,
    campaign_size_range: false,
    campaign_type: true,
    connected_call_cost: false,
    cost_per_piece: false,
    expected_net_return: true,
    expected_response_rate: true,
    method: true,
    model_predicted_gift_size: false,
    predicted_campaign_return: true,
    rate_of_connected_calls: false,
    reference_past_campaign: false
};

const twentyFourMLapsedDisplay = {
    ...defaultFormDisplay
};

const convertToRgDisplayDM = {
    ...defaultFormDisplay,
    appeal_type: true,
    cost_per_piece: true
    // TODO add to BE / FE
    // expected_gift_size: true,
    // expected_number_of_recurring_gifts: true,
};

const convertToRgDisplayTM = {
    ...defaultFormDisplay,
    appeal_type: true,
    connected_call_cost: true,
    rate_of_connected_calls: true,
    average_upgrade_value: true,
    average_number_of_future_upgrade_gifts: true,
    cost_per_piece: true
    // TODO add to BE / FE
    // expected_gift_size: true,
    // expected_number_of_recurring_gifts: true,
};

const dmAppealOverFiveHundredDisplay = {
    ...defaultFormDisplay
};

const dmAppealPastFormDisplay = {
    ...defaultFormDisplay,
    average_gift_size_from_past_campaign: true,
    campaign_cost: true,
    cost_per_piece: true,
    reference_past_campaign: true
};

const dmAppealROIFormDisplay = {
    ...defaultFormDisplay,
    campaign_cost: true,
    cost_per_piece: true,
    model_predicted_gift_size: true
};

const giwConfirmerDisplay = {
    ...defaultFormDisplay
};

const majorGiverDisplay = {
    ...defaultFormDisplay
};

const midValueGiverDisplay = {
    ...defaultFormDisplay
};

const rgChurnDisplay = {
    ...defaultFormDisplay,
    appeal_type: true,
    connected_call_cost: true,
    rate_of_connected_calls: true,
    // TODO add to BE / FE
    // estimated_churn_rate: true,
    // expected_save_rate: true,
    // average_reecurring_gift_value: true,
    average_number_of_future_upgrade_gifts: true
};

const rgReactivationDisplay = {
    ...defaultFormDisplay
};

const rgUpgradeDisplayDM = {
    ...defaultFormDisplay,
    appeal_type: true,
    cost_per_piece: true,
    average_upgrade_value: true,
    average_number_of_future_upgrade_gifts: true,
    campaign_cost: true
};

const rgUpgradeDisplayTM = {
    ...defaultFormDisplay,
    appeal_type: true,
    connected_call_cost: true,
    rate_of_connected_calls: true,
    average_upgrade_value: true,
    average_number_of_future_upgrade_gifts: true,
    campaign_cost: true
};

export {
    defaultFormValues,
    convertToRgDisplayDM,
    convertToRgDisplayTM,
    defaultFormDisplay,
    dmAppealOverFiveHundredDisplay,
    dmAppealPastFormDisplay,
    dmAppealROIFormDisplay,
    giwConfirmerDisplay,
    majorGiverDisplay,
    midValueGiverDisplay,
    rgChurnDisplay,
    rgReactivationDisplay,
    rgUpgradeDisplayDM,
    rgUpgradeDisplayTM,
    twentyFourMLapsedDisplay
};
