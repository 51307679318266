import React from 'react';
import { Column } from 'components/simpleFlexbox';
import { enableJustOneMenuItem, enableAllMenuItems } from '../utilities';

const functionsAttached = {};

function CRMVideo({ crm_type }) {
    let src;
    let dataUUID;
    switch (crm_type) {
        case 'bb_nxt':
            src = 'https://play.vidyard.com/VWG8ckbyNXPhk9Sz2CVnHp.jpg';
            dataUUID = 'VWG8ckbyNXPhk9Sz2CVnHp';
            break;
        case 'sf_npsp':
            src = 'https://play.vidyard.com/hcfpcWZxnEF7csmZuFfiFQ.jpg';
            dataUUID = 'hcfpcWZxnEF7csmZuFfiFQ';
            break;
        default:
            src = 'https://play.vidyard.com/KdhDy5STfwSbvDaoZkwgjd.jpg';
            dataUUID = 'KdhDy5STfwSbvDaoZkwgjd';
            break;
    }

    return (
        <img
            alt='BB NXT'
            style={{ width: '100%', margin: 'auto', display: 'block' }}
            className='vidyard-player-embed'
            src={src}
            data-uuid={dataUUID}
            data-v='4'
            data-type='inline'
        />
    );
}

export function getCampaignSizeSteps({ classes, tourType, crm_type, crm_types }) {
    const crm_name = (crm_types.find((c) => c.crm_type === crm_type) || {}).crm_name;
    const steps = [
        {
            content: (
                <Column>
                    <p style={{ textAlign: 'left', marginBottom: 6 }}>
                        You can access your donor scores and ranks inside your {crm_name} system.
                        This video shows you where and how to easily access your scores!
                    </p>
                    <Column horizontal='center' style={{ margin: '30px 0px -10px 0px' }}>
                        <CRMVideo crm_type={crm_type} />
                    </Column>
                </Column>
            ),
            nextText: 'Next step',
            placement: 'center',
            target: 'body',
            containerType: 'LARGE',
            id: 'predict_0',
            stepStyles: {
                buttonContainerHorizontal: 'center',
                buttonWidth: 245,
                leftBarWidth: 100,
                containerPadding: '60px 90px 120px 90px',
                containerWidth: null,
                buttonContainerTop: 'calc(100% + 40px)',
                skipButtonRight: 0,
                buttonNextStyles: {
                    height: 50
                }
            }
        },
        {
            content: (
                <Column>
                    <h2 className={classes.title}>Let’s run a campaign</h2>
                    <p style={{ textAlign: 'left', marginTop: 24, marginBottom: 24 }}>
                        Let’s plan a mail campaign together using your new donor propensity and
                        prediction data.
                    </p>
                    <div style={{ textAlign: 'left' }}>
                        In a few steps we will show you how to:
                        <ul style={{ marginTop: 4 }}>
                            <li>Make your donor selections</li>
                            <li>Optimise your campaign size</li>
                            <li>Analyse your campaign results</li>
                        </ul>
                    </div>
                </Column>
            ),
            nextText: 'Next step',
            placement: 'center',
            target: 'body',
            id: 'predict_1',
            containerType: 'LARGE',
            stepStyles: {
                buttonContainerHorizontal: 'center',
                buttonWidth: 245,
                leftBarWidth: 100,
                containerPadding: '60px 90px 120px 90px',
                containerWidth: null,
                buttonContainerTop: 'calc(100% + 40px)',
                skipButtonRight: 0,
                buttonNextStyles: {
                    height: 50
                }
            }
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Optimise your campaign size</div>
                    <div>Go to the Campaign Suggestions to start planning your campaign.</div>
                </div>
            ),
            placement: 'top',
            target: '#menu_predict',
            nextText: 'Next',
            backText: 'Back',
            id: 'campaign_size_1',
            disableScrolling: true,
            disableOverlay: true,
            hasSpotlightClicks: true
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Recommendations for each program</div>
                    <div>
                        Here’s where you find your ideal campaign size.
                        <br />
                        Our AI has predicted you should send your DM appeal to 5,000 donors to
                        maximise ROI
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'bottom',
            target: '#campaign_size_suggestions',
            nextText: 'Next',
            backText: 'Back',
            id: 'campaign_size_3',
            disableScrolling: true
        }
    ];
    return steps;
}

export function onCampaignSizeStepEffect({ steps = [], stepIndex, localState, theme }) {
    const currentStep = steps[stepIndex] || {};
    const btnNextSelector = '#btn_onboarding_next';
    const subMenuCampaignSizeSelector = '#submenu_campaign_size';

    function onNextClick() {
        const nextBtnElement = document.querySelector(btnNextSelector);
        nextBtnElement && nextBtnElement.click();
    }

    return [
        () => {
            if (currentStep.id === 'predict_0' && localState['predict_0']) {
                if (window.vidyardEmbed) {
                    window.vidyardEmbed.api.renderDOMPlayers();
                    setInterval(() => window.vidyardEmbed.api.renderDOMPlayers(), 1000);
                }
            }
            if (currentStep.id === 'campaign_size_1' && localState['campaign_size_1']) {
                window.scrollTo(0, 0);
                enableJustOneMenuItem({
                    mainSelector: '#menu_predict',
                    selector: subMenuCampaignSizeSelector,
                    onNextClick,
                    functionsAttached
                });
            }
        },
        () => {
            if (currentStep.id === 'campaign_size_3') {
                enableAllMenuItems({
                    mainSelector: '#menu_predict',
                    selector: subMenuCampaignSizeSelector,
                    functionsAttached
                });
            }
        }
    ];
}
