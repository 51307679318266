import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import useUpgradeAccount from 'hooks/useUpgradeAccount';
import { IconError } from 'assets/icons';
import COUNTRIES from 'resources/countries';
import FormComponent from 'components/form/FormComponent';
import FormRadioComponent from 'components/form/radio/FormRadioComponent';
import FormInputComponent from 'components/form/input/FormInputComponent';
import FormSelectComponent from 'components/form/select/FormSelectComponent';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';
import './upgrade-popup.css';

function UpgradeAccountPopup() {
    const { push } = useHistory();
    const { search } = useLocation();
    const {
        data: { has_contract, client_name, phone_number, crm_type } = {},
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: 'has_contract,client_name,phone_number,crm_type'
    });
    const { isLoading: isLoadingCRMTypes, data: crm_types_prop = [] } = useCRMTypes();
    const {
        isLoading: isLoadingUpgradeAccount,
        errorUpgradeAccount,
        upgradeAccount
    } = useUpgradeAccount();
    const [selectedCRM, setSelectedCRM] = useState('');
    const crm_types = crm_types_prop.filter((t) => !!t.onboarding_order);
    const isLoading = isLoadingClientSettings || isLoadingUpgradeAccount || isLoadingCRMTypes;
    const parsed = queryString.parse(search);
    const open = parsed.show_upgrade === 'true';
    const isFiFree = parsed.is_fi_free === 'true';
    const upgradeError = parsed.upgrade_error === 'true';
    const showError = errorUpgradeAccount;
    const crms_with_self_onboarding = crm_types
        .filter((t) => t.self_onboarding === true)
        .map((t) => t.crm_type);
    const selectedCRMIsIncluded = ['', ...crms_with_self_onboarding].includes(selectedCRM);
    const isDemoClient = client_name === 'DEMO';
    const showAddDetailsSection = selectedCRM === 'other' || isFiFree;
    let isSelfOnboarding = selectedCRMIsIncluded;
    const modalTitle = !isFiFree ? 'Connect Your CRM' : 'Upgrade your account';

    if (selectedCRM === 'sf_npsp') {
        if (isDemoClient || !has_contract) {
            isSelfOnboarding = false;
        }
    }

    function onSubmit({
        client_name_external,
        crm_type,
        details,
        email_or_phone,
        country: countryCode
    }) {
        const {
            name: country,
            default_currency,
            market,
            tz: timezone
        } = COUNTRIES.find((c) => c.code === countryCode);
        const payload = {
            crm_type,
            details: details || '_',
            client_name_external,
            client_name: client_name_external
                .toLowerCase()
                .replace(/'/g, '')
                .replace(/[^A-Z0-9]+/gi, '_'),
            country,
            countryCode: country,
            timezone,
            default_currency,
            market,
            preventRedirectToSetup: true
        };
        payload.email = false;
        payload.phone = false;
        if (email_or_phone) {
            if (email_or_phone === 'email') {
                payload.email = true;
                payload.phone = false;
            } else {
                payload.email = false;
                payload.phone = true;
            }
        }

        if (isDemoClient) {
            upgradeAccount(payload);
        }
    }

    function onRequestClose() {
        push({ search: '' });
    }

    function onChangeCRM(e) {
        setSelectedCRM(e.target.value);
    }

    useEffect(() => {
        if (crm_type && client_name !== 'DEMO') {
            setSelectedCRM(crm_type);
        }
    }, [client_name, crm_type]);

    return (
        <Modal
            isOpen={open}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 1059,
                    maxHeight: 880,
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            ariaHideApp={false}
        >
            <FormComponent onSubmit={onSubmit}>
                <Row style={{ height: '100%' }}>
                    <Column vertical='center' className='onboarding-banner-popup-img'>
                        <div className='onboarding-banner-popup-img-inner'></div>
                    </Column>

                    <Column horizontal='center' vertical='center' style={{ width: '100%' }}>
                        <div className='onboarding-banner-popup-cnt'>
                            <h2
                                className='font-bold text-5xl text-dataro-black'
                                id='connect_crm_title'
                            >
                                {modalTitle}
                            </h2>
                            {!upgradeError ? (
                                <>
                                    {isDemoClient && (
                                        <>
                                            <p>
                                                Follow the below steps to connect your CRM and see
                                                your own organisation's data in Dataro!
                                            </p>
                                            <FormInputComponent
                                                label='Organization name'
                                                required='Please provide your organization name'
                                                name='client_name_external'
                                                type='text'
                                            />
                                            <Column style={{ marginTop: 20 }}>
                                                <FormSelectComponent
                                                    label='Select your CRM'
                                                    name='crm_type'
                                                    required='Please select a CRM'
                                                    autoComplete='off'
                                                    onChange={onChangeCRM}
                                                >
                                                    {[
                                                        { label: '', value: '' },
                                                        ...crm_types.map(
                                                            ({ crm_type, crm_name }) => ({
                                                                label: crm_name,
                                                                value: crm_type
                                                            })
                                                        ),
                                                        { label: 'Other', value: 'other' }
                                                    ].map((p) => (
                                                        <option
                                                            key={`status-${p.value}`}
                                                            value={p.value}
                                                        >
                                                            {p.label}
                                                        </option>
                                                    ))}
                                                </FormSelectComponent>
                                            </Column>
                                            <Column style={{ marginTop: 20, marginBottom: 30 }}>
                                                <FormSelectComponent
                                                    label='Select your Country'
                                                    name='country'
                                                    required='Please select a Country'
                                                    autoComplete='off'
                                                >
                                                    {[
                                                        { label: '', value: '' },
                                                        ...COUNTRIES.map(
                                                            ({ name: label, code: value }) => ({
                                                                label,
                                                                value
                                                            })
                                                        )
                                                    ].map((p) => (
                                                        <option
                                                            key={`country-${p.value}`}
                                                            value={p.value}
                                                        >
                                                            {p.label}
                                                        </option>
                                                    ))}
                                                </FormSelectComponent>
                                            </Column>
                                        </>
                                    )}
                                    {!isSelfOnboarding && (
                                        <Column>
                                            <p>
                                                We'll need to discuss a few details before we
                                                proceed. Please fill out the form below and we'll be
                                                in touch.
                                            </p>
                                            <h3 style={{ marginTop: 0 }}>
                                                How would you like to get in touch?
                                            </h3>
                                            <div className='flex items-center'>
                                                {!!phone_number && (
                                                    <FormRadioComponent
                                                        id='Phone'
                                                        name='email_or_phone'
                                                        label='Phone'
                                                        defaultChecked={true}
                                                        value='phone'
                                                    />
                                                )}
                                                <FormRadioComponent
                                                    id='Email'
                                                    name='email_or_phone'
                                                    label='Email'
                                                    defaultChecked={!phone_number}
                                                    value='email'
                                                />
                                            </div>
                                            {showAddDetailsSection && (
                                                <>
                                                    {selectedCRM === 'other' && (
                                                        <h3>What CRM are you currently using</h3>
                                                    )}
                                                    <p
                                                        style={{
                                                            marginTop:
                                                                selectedCRM === 'other' ? 0 : 40
                                                        }}
                                                    >
                                                        Before we talk, tell us a bit about your
                                                        organisation and why you’re interested in
                                                        using Dataro.
                                                    </p>
                                                    <FormInputComponent
                                                        required='Please provide details'
                                                        name='details'
                                                        type='text'
                                                        textArea
                                                        rows={4}
                                                    />
                                                </>
                                            )}
                                        </Column>
                                    )}
                                    <Row
                                        horizontal='space-between'
                                        vertical='center'
                                        style={{ marginTop: 12 }}
                                    >
                                        <div className='upgrade-error-message'>
                                            {showError && (
                                                <span>
                                                    {showError} <IconError />
                                                </span>
                                            )}
                                        </div>
                                        <ButtonComponent id='btn_next' width={245} type='submit'>
                                            {isSelfOnboarding ? 'Next' : 'Get in touch'}
                                        </ButtonComponent>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <div className='upgrade-error-message'>
                                        We are not able to create an account for this organisation
                                        at this time. <br /> Please contact integrations@dataro.io
                                        <span style={{ marginLeft: 8 }}>
                                            <IconError />
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </Column>
                </Row>
            </FormComponent>
            <LoadingComponent loading={isLoading} />
        </Modal>
    );
}

export default UpgradeAccountPopup;
