import React, { forwardRef } from 'react';
const Icon = (props, ref) => (
    <svg
        id={props.id}
        width={props.width || '28'}
        height={props.height || '28'}
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g>
            <path
                d='M24.345 0.430908L13.9784 10.695L10.557 7.2736L0.292969 17.6403L3.71432 21.0616L10.557 14.1163L13.9784 17.5377L27.6638 3.85226L24.345 0.430908Z'
                fill={props.fill || 'black'}
            />
        </g>
    </svg>
);
export default forwardRef(Icon);
