import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 22 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M21.3707 13.14C21.7727 12.4593 22 11.7333 22 10.8556C22 8.83827 20.2942 6.93321 18.0666 6.93321H16.3946C16.6201 6.34608 16.8002 5.64392 16.8002 4.80013C16.8002 1.46373 15.0728 0 12.4337 0C9.61001 0 9.77107 4.3511 9.14467 4.9775C8.1021 6.02007 6.87065 8.02299 5.99317 8.8H1.46667C0.656654 8.8 0 9.45665 0 10.2667V21.2667C0 22.0767 0.656654 22.7333 1.46667 22.7333H4.4C5.0826 22.7333 5.6562 22.267 5.81983 21.6356C7.85982 21.6815 9.26008 23.4662 13.9691 23.4662C14.3 23.4662 14.6667 23.4667 14.9875 23.4667C18.522 23.4667 20.1202 21.6598 20.1639 19.0974C20.7744 18.2529 21.0943 17.121 20.9587 16.027C21.4103 15.1813 21.5849 14.1779 21.3707 13.14ZM18.5405 15.6072C19.1162 16.5756 18.5982 17.8718 17.9016 18.2458C18.2545 20.4815 17.0945 21.2662 15.4669 21.2662H13.7335C10.45 21.2662 8.32384 19.5328 5.86667 19.5328V11H6.36717C7.667 11 9.48292 7.75087 10.7003 6.53308C12.0001 5.23325 11.567 3.06671 12.4337 2.2C14.6002 2.2 14.6002 3.71158 14.6002 4.80013C14.6002 6.59542 13.3004 7.39979 13.3004 9.13321H18.0666C19.0341 9.13321 19.7959 9.99992 19.8 10.8666C19.8041 11.7329 19.2124 12.5996 18.7793 12.5996C19.3975 13.2667 19.5296 14.6729 18.5405 15.6072ZM4.03333 19.8C4.03333 20.4075 3.54085 20.9 2.93333 20.9C2.32581 20.9 1.83333 20.4075 1.83333 19.8C1.83333 19.1925 2.32581 18.7 2.93333 18.7C3.54085 18.7 4.03333 19.1925 4.03333 19.8Z'
            fill='#45BD99'
        />
    </svg>
);

export default forwardRef(Icon);
