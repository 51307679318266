import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 64}
        height={props.height || 76}
        viewBox='0 0 64 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21 19.8296C25.3442 19.8296 29 16.1914 29 11.5359C29 6.8804 25.3442 3.24219 21 3.24219C16.6558 3.24219 13 6.8804 13 11.5359C13 16.1914 16.6558 19.8296 21 19.8296ZM21 22.8296C27.0751 22.8296 32 17.7733 32 11.5359C32 5.29856 27.0751 0.242188 21 0.242188C14.9249 0.242188 10 5.29856 10 11.5359C10 17.7733 14.9249 22.8296 21 22.8296Z'
            fill='#908D9E'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.70502 28.8974C11.0095 24.3817 15.5881 20.3477 20.828 20.3477C25.1733 20.3477 29.1226 22.8766 32.2563 26.2344C35.413 29.6168 37.93 34.0311 39.4157 38.2642L36.585 39.2577C35.2195 35.367 32.9033 31.3246 30.0631 28.2813C27.1999 25.2134 23.9887 23.3477 20.828 23.3477C17.1097 23.3477 13.3134 26.3134 10.126 30.669C6.99257 34.9509 4.79142 40.1191 4.12576 43.6136C3.39723 47.4381 2.98177 51.031 4.75611 53.7219C6.47179 56.3237 10.6736 58.6481 20.828 58.6481C23.4903 58.6481 25.7648 58.5137 27.7045 58.2655L28.0853 61.2412C25.9923 61.509 23.5872 61.6481 20.828 61.6481C10.445 61.6481 4.84589 59.3077 2.25159 55.3733C-0.284043 51.528 0.481363 46.7133 1.17875 43.0522C1.93901 39.0611 4.34663 33.4867 7.70502 28.8974Z'
            fill='#908D9E'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.5 72.9148C53.5923 72.9148 61 65.4847 61 56.2736C61 47.0624 53.5923 39.6323 44.5 39.6323C35.4077 39.6323 28 47.0624 28 56.2736C28 65.4847 35.4077 72.9148 44.5 72.9148ZM44.5 75.9148C55.2696 75.9148 64 67.1211 64 56.2736C64 45.426 55.2696 36.6323 44.5 36.6323C33.7304 36.6323 25 45.426 25 56.2736C25 67.1211 33.7304 75.9148 44.5 75.9148Z'
            fill='#8564E7'
        />
        <path
            d='M 43.746 43.628 C 44.161 43.309 44.723 43.287 45.16 43.558 L 45.266 43.632 L 56.232 52.129 C 56.783 52.556 56.888 53.355 56.467 53.914 C 56.07 54.439 55.349 54.564 54.806 54.222 L 54.707 54.152 L 44.499 46.242 L 34.178 54.155 C 33.658 54.555 32.929 54.48 32.496 54.003 L 32.419 53.909 C 32.024 53.38 32.098 52.643 32.57 52.204 L 32.663 52.126 L 43.746 43.628 Z'
            fill='#8564E7'
        />
        <path
            d='M 51.479 55.04 C 52.113 55.04 52.635 55.532 52.694 56.16 L 52.699 56.279 L 52.699 65.082 C 52.699 65.726 52.215 66.257 51.597 66.317 L 51.479 66.322 L 37.381 66.322 C 36.746 66.322 36.226 65.83 36.167 65.202 L 36.161 65.082 L 36.161 56.279 C 36.161 55.595 36.707 55.04 37.381 55.04 C 38.015 55.04 38.536 55.532 38.595 56.16 L 38.6 56.279 L 38.6 63.841 L 50.259 63.841 L 50.259 56.279 C 50.259 55.636 50.743 55.105 51.361 55.045 L 51.479 55.04 Z'
            fill='#8564E7'
        />
    </svg>
);

export default forwardRef(Icon);
