import { useEffect } from 'react';
import initializeDelighted from 'resources/jsScripts/delighted/delighted';
import { compareDates } from 'resources/utilities';

export default function useDelighted() {
    useEffect(() => {
        const isDelightedEnabled = process.env.REACT_APP_DELIGHTED_ENABLED === 'true';
        if (isDelightedEnabled) {
            initializeDelighted();
        }
    }, []);

    const delighted = window.delighted;

    function sendSurvey(client_name, email, full_name, addedAt, lastNPS) {
        if (!delighted || !delighted.survey) {
            return null;
        }
        if (!email.includes('@dataro.awsapps.com')) {
            delighted.survey({
                email: email,
                name: full_name,
                createdAt: compareDates(addedAt, lastNPS, 'last'),
                properties: {
                    client_name: client_name
                }
            });
        }
    }

    return {
        sendSurvey
    };
}
