const color = {
    background: '#E5E5E5',
    error: '#e20000',
    sidebarBackground: '#FFB282',
    sidebarIconColor: '#424147',
    backgroundWelcome: '#D6C6FF',
    backgroundWelcomeGradient:
        'radial-gradient(143.05% 119.89% at 44.63% 60.32%, #E8E1FF 0%, #C6B4FF 100%)',
    veryDarkGrayishBlue: '#373a47',
    lightGrayishBlue: '#F7F8FC',
    brightBlue: '#3498db',
    grey: '#F9F9F9',
    grey2: '#E8E8E8',
    greyMid: '#7C7C7C',
    darkGray: '#5D5D5D',
    resultsIconColor: '#8564E7',
    positiveGreen: '#45bd99',
    negativeRed: '#e1535c',
    orangeYellow: '#ffda3d',
    chartsLine: '#9D82EB',
    blue: '#257BC8',
    orange: '#FF9900',
    purple: '#800080',

    // dataro colors
    lightLilac: '#D5C7FF',
    softOrange: '#FFB282',
    lightGreyStrok: '#E8E8E8',
    charcoal: '#5D5D5D',
    purpleHeaders: '#8564E7',
    dataroBlack: '#343434',
    darkOrange: '#FF9900',
    midGrey: '#8F8C9D',
    keylineGrey: '#F9F9F9',
    statsGreen: '#45BD99',
    statsRed: '#E1535C',
    errorRed: '#E20000',
    softBalck: '#424147',
    mediumLilac: '#C6B4FF',
    pink: '#ffebe8',
    brightGreen: '#0fd618',
    tropicalIndigo: '#9D82EB'
};

const THEME = {
    color,
    typography: {
        name: {
            fontWeight: 700,
            fontSize: 70,
            lineHeight: '68px',
            letterSpacing: '0.145833px'
        },
        title: {
            fontWeight: 700,
            fontSize: 40,
            lineHeight: '57px',
            letterSpacing: '-0.473214px'
        },
        heading: {
            fontWeight: 500,
            fontSize: 30,
            lineHeight: '122%'
        },
        subHeading: {
            fontWeight: 500,
            fontSize: 25,
            lineHeight: '31px',
            letterSpacing: '-0.35px'
        },

        body1: {
            fontWeight: 700,
            fontSize: 20,
            lineHeight: '31px',
            letterSpacing: '-0.35px'
        },
        body2: {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '31px',
            letterSpacing: '-0.35px'
        },
        button: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: '25.74px',
            letterSpacing: '-0.5px',
            textAlign: 'center'
        },
        link: {
            fontWeight: 390,
            fontSize: 20,
            lineHeight: '26px',
            textAlign: 'right',
            letterSpacing: '-0.4px',
            textDecorationLine: 'underline',
            color: '#5d5d5d',
            '&:hover': {
                color: '#FFB282'
            }
        },
        secondaryLink: {
            fontWeight: 450,
            fontSize: 18,
            lineHeight: '26px',
            textAlign: 'right',
            letterSpacing: '-0.4px',
            textDecorationLine: 'none',
            color: color.purpleHeaders,
            '&:hover': {
                color: color.darkOrange
            }
        }
    },
    structure: {
        sectionContainer: {
            padding: 48
        },
        greyBox: {
            background: color.keylineGrey,
            borderRadius: 10,
            padding: '30px 50px 37px',
            marginTop: 23
        }
    }
};

export default THEME;
