import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M14.6258 5.05161C13.3652 3.87129 11.7294 3.22427 9.99387 3.22581C6.87056 3.22855 4.17419 5.37008 3.42972 8.34073C3.37552 8.55698 3.18286 8.70968 2.95992 8.70968H0.649315C0.346976 8.70968 0.117298 8.4352 0.173226 8.13806C1.04569 3.505 5.11343 0 10 0C12.6794 0 15.1125 1.05387 16.9079 2.76956L18.348 1.32944C18.9576 0.719798 20 1.15157 20 2.01375V7.41935C20 7.95383 19.5667 8.3871 19.0323 8.3871H13.6267C12.7645 8.3871 12.3327 7.34472 12.9423 6.73504L14.6258 5.05161ZM0.967742 11.6129H6.37335C7.23552 11.6129 7.6673 12.6553 7.05766 13.265L5.37419 14.9484C6.63476 16.1288 8.27077 16.7758 10.0063 16.7742C13.128 16.7714 15.8254 14.6313 16.5703 11.6594C16.6245 11.4431 16.8171 11.2904 17.0401 11.2904H19.3507C19.6531 11.2904 19.8827 11.5649 19.8268 11.862C18.9543 16.495 14.8866 20 10 20C7.32064 20 4.88746 18.9461 3.09214 17.2304L1.65202 18.6706C1.04238 19.2802 0 18.8484 0 17.9862V12.5806C0 12.0462 0.433266 11.6129 0.967742 11.6129Z'
            fill='black'
        />
    </svg>
);

export default forwardRef(Icon);
