import React, { forwardRef } from 'react';

const Icon = (props = {}, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))' }}
    >
        <circle cx='10' cy='10' r='9' fill='white' strokeWidth='2' />
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(4,15.8) scale(0.048,-0.05)'
            fill='#000000'
            stroke='none'
        >
            <path d='M90 240 c-11 -11 -20 -32 -20 -48 0 -16 -8 -32 -20 -40 -16 -10 -20 -23 -20 -70 0 -32 5 -63 12 -70 16 -16 160 -16 176 0 7 7 12 38 12 70 0 47 -4 60 -20 70 -12 8 -20 24 -20 40 0 33 -31 68 -60 68 -11 0 -29 -9 -40 -20z m68 -12 c7 -7 12 -25 12 -40 0 -26 -3 -28 -40 -28 -37 0 -40 2 -40 28 0 31 16 52 40 52 9 0 21 -5 28 -12z m-14 -136 c-1 -7 -2 -21 -2 -32 -1 -11 -6 -20 -11 -20 -10 0 -22 51 -14 63 7 11 28 3 27 -11z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
