import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 26}
        height={props.height || 25}
        viewBox='0 0 26 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M1 23.1846L9.18455 15'
            strokeWidth='2'
            strokeLinecap='round'
            stroke={props.fill || '#C0C0C0'}
        />
        <circle cx='16.5' cy='9.5' r='8.5' stroke={props.fill || '#C0C0C0'} strokeWidth='2' />
    </svg>
);

export default forwardRef(Icon);
