import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || '27'}
        height={props.height || '18'}
        viewBox='0 0 27 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M2 2L13.6476 16L25 2'
            stroke={props.fill || '#8564E7'}
            strokeWidth={props.strokeWidth || '4'}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default forwardRef(Icon);
