import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 29}
        viewBox='0 0 467.689 467.689'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fill={props.fill || '#BFBFBF'}
            d='M153.721,319.489c20.321-13.714,33.71-36.951,33.71-63.257c0-42.052-34.211-76.263-76.263-76.263
		s-76.263,34.211-76.263,76.263c0,26.306,13.389,49.543,33.71,63.257c-21.713,6.195-37.663,26.201-37.663,49.875v90.825
		c0,4.142,3.358,7.5,7.5,7.5h145.432c4.142,0,7.5-3.358,7.5-7.5v-90.825C191.384,345.69,175.435,325.684,153.721,319.489z
		 M128.461,342.869l-9.109-10.374h18.218L128.461,342.869z M99.39,332.495l18.076,20.585l-16.921,12.622l-8.158-33.207H99.39z
		 M154.662,335.764c0.954,0.432,1.887,0.9,2.796,1.408l-2.669,27.345l-15.332-11.437L154.662,335.764z M49.905,256.232
		c0-33.781,27.482-61.263,61.263-61.263s61.263,27.482,61.263,61.263s-27.482,61.263-61.263,61.263S49.905,290.013,49.905,256.232z
		 M45.952,369.364c0-18.367,13.502-33.637,31.101-36.415l11.625,47.318c0.6,2.442,2.383,4.42,4.75,5.27
		c0.824,0.296,1.68,0.441,2.532,0.441c1.594,0,3.171-0.508,4.485-1.488l20.516-15.304v83.503H82.984v-74.346
		c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v74.346H45.952V369.364z M176.384,452.689h-40.422v-83.503l20.516,15.304
		c1.318,0.983,2.895,1.488,4.485,1.488c1.027,0,2.06-0.211,3.032-0.641c2.475-1.094,4.169-3.437,4.432-6.131l2.795-28.63
		c3.275,5.506,5.163,11.929,5.163,18.788V452.689z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M152.717,390.424c-1.193-2.828-4.026-4.704-7.109-4.625c-3.274,0.084-6.157,2.347-7.039,5.495
		c-0.86,3.067,0.403,6.477,3.058,8.241c2.626,1.744,6.179,1.644,8.69-0.261C152.995,397.24,154.036,393.537,152.717,390.424
		C152.527,389.974,152.908,390.874,152.717,390.424z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M108.487,242.273c-1.169-2.767-3.904-4.63-6.925-4.63c-3.01,0-5.769,1.862-6.925,4.63
		c-1.232,2.951-0.384,6.499,2.054,8.571c2.48,2.107,6.132,2.369,8.88,0.627C108.601,249.552,109.889,245.595,108.487,242.273
		C108.297,241.824,108.677,242.724,108.487,242.273z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M143.688,248.014c1.193,2.824,4.03,4.704,7.112,4.625c3.327-0.085,6.281-2.446,7.086-5.677
		c0.718-2.883-0.382-6.001-2.747-7.797c-2.598-1.972-6.297-2.012-8.932-0.09C143.427,241.102,142.374,244.834,143.688,248.014
		C143.877,248.463,143.498,247.554,143.688,248.014z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M337.142,175.164c1.226,0.781,2.626,1.174,4.029,1.174c1.24,0,2.481-0.307,3.607-0.925c2.4-1.317,3.893-3.837,3.893-6.575
		v-17.443h7.631c12.847,0,23.299-10.452,23.299-23.298V49.688c0-12.847-10.452-23.299-23.299-23.299h-77.698V23.3
		c0-12.848-10.452-23.3-23.3-23.3h-137.64c-12.848,0-23.3,10.453-23.3,23.3v78.4c0,12.848,10.453,23.3,23.3,23.3h7.63v17.45
		c0,2.738,1.492,5.259,3.893,6.576c1.126,0.618,2.367,0.924,3.606,0.924c1.404,0,2.805-0.394,4.031-1.175L174.13,125h21.231v3.097
		c0,12.847,10.452,23.298,23.298,23.298h81.171L337.142,175.164z M171.944,110c-1.428,0-2.827,0.408-4.031,1.175l-27.619,17.602
		V117.5c0-4.142-3.358-7.5-7.5-7.5h-15.13c-4.577,0-8.3-3.723-8.3-8.3V23.3c0-4.577,3.724-8.3,8.3-8.3h137.64
		c4.576,0,8.3,3.724,8.3,8.3v3.089H218.66c-10.157,0-18.811,6.536-21.992,15.621h-57.233c-4.142,0-7.5,3.358-7.5,7.5
		s3.358,7.5,7.5,7.5h55.927v12.54h-55.927c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h55.927V110H171.944z M210.361,128.097
		V49.688c0-4.576,3.723-8.299,8.298-8.299h137.642c4.576,0,8.299,3.723,8.299,8.299v78.408c0,4.576-3.723,8.298-8.299,8.298h-15.131
		c-4.143,0-7.5,3.358-7.5,7.5v11.273l-27.625-17.599c-1.204-0.767-2.602-1.174-4.029-1.174H218.66
		C214.084,136.395,210.361,132.672,210.361,128.097z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M240.438,83.4h94.085c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-94.085c-4.143,0-7.5,3.358-7.5,7.5
		S236.296,83.4,240.438,83.4z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M342.023,103.438c0-4.142-3.357-7.5-7.5-7.5h-94.085c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h94.085
		C338.666,110.938,342.023,107.58,342.023,103.438z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M399.074,319.489c20.321-13.714,33.71-36.951,33.71-63.257c0-42.052-34.211-76.263-76.263-76.263
		s-76.264,34.211-76.264,76.263c0,26.306,13.389,49.543,33.71,63.257c-21.714,6.195-37.663,26.201-37.663,49.875v90.825
		c0,4.142,3.357,7.5,7.5,7.5h145.433c4.143,0,7.5-3.358,7.5-7.5v-90.825C436.737,345.69,420.788,325.684,399.074,319.489z
		 M383.26,332.495c-5.698,8.977-15.711,14.751-26.741,14.751s-21.042-5.774-26.741-14.751H383.26z M295.258,256.232
		c0-33.781,27.482-61.263,61.264-61.263c33.78,0,61.263,27.482,61.263,61.263s-27.482,61.263-61.263,61.263
		C322.74,317.495,295.258,290.013,295.258,256.232z M291.305,369.364c0-15.385,9.475-28.593,22.894-34.11
		c7.547,16.208,24.001,26.992,42.321,26.992c18.32,0,34.774-10.785,42.321-26.993c13.42,5.517,22.897,18.726,22.897,34.111v26.622
		h-26.272v-17.642c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v74.346h-89.16V369.364z M395.465,452.689v-41.704h26.272v41.704
		H395.465z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M359.197,248.014c1.323,3.125,4.65,5.056,8.029,4.546c2.966-0.448,5.427-2.672,6.169-5.575
		c0.779-3.047-0.506-6.331-3.108-8.081c-2.95-1.984-6.946-1.524-9.47,0.93C358.724,241.99,358.045,245.217,359.197,248.014
		C359.388,248.463,359.008,247.554,359.197,248.014z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M324.428,243.674c-0.18-0.962-0.589-1.877-1.11-2.7c-3.784-5.537-12.651-3.586-13.655,3.068
		c-0.913,6.044,5.784,10.658,11.109,7.62C323.547,250.078,325.068,246.82,324.428,243.674
		C324.338,243.194,324.527,244.164,324.428,243.674z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M357.996,270.838c-2.342-2.343-6.143-2.343-8.484,0c-2.005,2.004-4.67,3.108-7.504,3.108s-5.499-1.104-7.504-3.108
		c-2.342-2.343-6.143-2.343-8.484,0c-2.344,2.343-2.344,6.142,0,8.485c4.407,4.408,10.198,6.612,15.988,6.612
		s11.581-2.204,15.988-6.612C360.34,276.98,360.34,273.182,357.996,270.838z'
        />
    </svg>
);

export default forwardRef(Icon);
