import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 19}
        height={props.height || 19}
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle
            cx='9.5'
            cy='9.5'
            r='8.5'
            fill='white'
            stroke={props.stroke_color || '#FF9900'}
            strokeWidth='2'
        />
        <path
            d='M9.651 7.227C10.223 7.227 10.691 6.772 10.691 6.2C10.691 5.628 10.223 5.16 9.651 5.16C9.079 5.16 8.624 5.628 8.624 6.2C8.624 6.772 9.079 7.227 9.651 7.227ZM8.702 13.753C8.702 13.883 8.819 14 8.949 14H10.379C10.509 14 10.626 13.883 10.626 13.753V9.047C10.626 8.904 10.509 8.8 10.379 8.8H8.949C8.819 8.8 8.702 8.904 8.702 9.047V13.753Z'
            fill={props.stroke_color || '#FF9900'}
        />
    </svg>
);

export default forwardRef(Icon);
