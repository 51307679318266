import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 65}
        height={props.height || 76}
        viewBox='0 0 65 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.0005 20.4395C24.7463 20.4395 28.5005 16.6753 28.5005 12.1458C28.5005 7.61624 24.7463 3.85205 20.0005 3.85205C15.2546 3.85205 11.5005 7.61624 11.5005 12.1458C11.5005 16.6753 15.2546 20.4395 20.0005 20.4395ZM20.0005 23.4395C26.3518 23.4395 31.5005 18.3831 31.5005 12.1458C31.5005 5.90842 26.3518 0.852051 20.0005 0.852051C13.6492 0.852051 8.50049 5.90842 8.50049 12.1458C8.50049 18.3831 13.6492 23.4395 20.0005 23.4395Z'
            fill='#8F8C9D'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.20502 29.5072C10.5095 24.9916 15.0881 20.9575 20.328 20.9575C24.6733 20.9575 28.6226 23.4865 31.7563 26.8443C34.913 30.2267 37.43 34.6409 38.9157 38.874L36.085 39.8675C34.7195 35.9769 32.4033 31.9345 29.5631 28.8912C26.6999 25.8233 23.4887 23.9575 20.328 23.9575C16.6097 23.9575 12.8134 26.9233 9.62602 31.2789C6.49257 35.5608 4.29142 40.729 3.62576 44.2235C2.89723 48.048 2.48177 51.6409 4.25611 54.3317C5.97179 56.9336 10.1736 59.258 20.328 59.258C22.9903 59.258 25.2648 59.1235 27.2045 58.8753L27.5853 61.8511C25.4923 62.1189 23.0872 62.258 20.328 62.258C9.94495 62.258 4.34589 59.9175 1.75159 55.9832C-0.784043 52.1379 -0.0186374 47.3231 0.678752 43.6621C1.43901 39.671 3.84663 34.0965 7.20502 29.5072Z'
            fill='#8F8C9D'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.5005 72.4888C53.9408 72.4888 61.5005 64.9873 61.5005 55.8476C61.5005 46.7078 53.9408 39.2063 44.5005 39.2063C35.0602 39.2063 27.5005 46.7078 27.5005 55.8476C27.5005 64.9873 35.0602 72.4888 44.5005 72.4888ZM44.5005 75.4888C55.5462 75.4888 64.5005 66.6951 64.5005 55.8476C64.5005 45 55.5462 36.2063 44.5005 36.2063C33.4548 36.2063 24.5005 45 24.5005 55.8476C24.5005 66.6951 33.4548 75.4888 44.5005 75.4888Z'
            fill='#8564E7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M54.5005 45.509C55.3289 45.509 56.0005 46.1806 56.0005 47.009C56.0005 47.7887 55.4056 48.4295 54.6449 48.5022L54.5005 48.509L53.7194 48.5088C55.1853 50.6034 56.0005 53.0949 56.0005 55.713C56.0005 59.461 54.3296 62.9487 51.4737 65.3837C50.8433 65.9211 49.8965 65.8458 49.359 65.2154C48.8216 64.585 48.8969 63.6383 49.5273 63.1008C51.7263 61.2259 53.0005 58.5663 53.0005 55.713C53.0005 54.2175 52.6505 52.7751 52.0005 51.4708V52.4628C52.0005 53.2425 51.4056 53.8832 50.6449 53.9559L50.5005 53.9628C49.7208 53.9628 49.08 53.3679 49.0074 52.6072L49.0005 52.4628V47.009C49.0005 46.2293 49.5954 45.5886 50.356 45.5159L50.5005 45.509H54.5005ZM40.4222 48.3821C41.0517 47.8437 41.1256 46.8968 40.5871 46.2672C40.0487 45.6377 39.1018 45.5638 38.4722 46.1023C35.6522 48.5143 34.0005 51.987 34.0005 55.7196C34.0005 58.6888 35.0458 61.4932 36.8919 63.7297L35.5005 63.7295L35.356 63.7364C34.5954 63.809 34.0005 64.4498 34.0005 65.2295C34.0005 66.0579 34.6721 66.7295 35.5005 66.7295H40.5005L40.6449 66.7226C41.4056 66.6499 42.0005 66.0092 42.0005 65.2295V59.7758L41.9936 59.6313C41.9209 58.8707 41.2802 58.2758 40.5005 58.2758L40.356 58.2826C39.5954 58.3553 39.0005 58.9961 39.0005 59.7758V61.563C37.718 59.8992 37.0005 57.8656 37.0005 55.7196C37.0005 52.8778 38.2585 50.2328 40.4222 48.3821Z'
            fill='#8564E7'
        />
    </svg>
);

export default forwardRef(Icon);
