import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='10 10 35 35'
        fill={props.color || '#424147'}
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g
            transform='translate(10, 44) scale(0.085,-0.085)'
            fill={props.color || '#424147'}
            stroke='none'
        >
            <path d='M115 373 c-46 -24 -76 -54 -96 -98 -75 -162 95 -331 256 -256 152 70 153 275 3 352 -45 23 -122 24 -163 2z m155 -33 c67 -34 104 -113 86 -184 -6 -24 -10 -26 -45 -20 -21 3 -47 1 -59 -5 -15 -8 -27 -7 -49 4 -37 19 -86 19 -124 -1 -17 -8 -34 -13 -37 -9 -4 4 -7 34 -7 68 0 75 27 120 89 148 53 24 97 24 146 -1z m-65 -246 c14 -15 25 -36 25 -47 0 -17 -5 -19 -49 -15 -28 3 -62 14 -80 27 -31 22 -31 24 -15 42 27 29 89 26 119 -7z m120 7 c5 -9 -40 -51 -56 -51 -11 0 -22 38 -14 50 8 13 62 13 70 1z' />
            <path d='M115 298 c-28 -16 -35 -28 -35 -64 0 -79 106 -88 133 -11 9 25 -11 63 -40 76 -29 13 -33 13 -58 -1z m59 -37 c25 -28 5 -63 -32 -59 -21 2 -28 9 -30 32 -5 45 31 61 62 27z' />
            <path d='M249 249 c-14 -14 -19 -28 -15 -42 3 -12 6 -25 6 -28 0 -3 11 -11 25 -17 61 -28 116 47 67 91 -25 23 -58 21 -83 -4z m69 -33 c3 -16 -12 -36 -28 -36 -19 0 -31 20 -25 39 8 27 48 25 53 -3z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
