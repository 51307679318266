import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import SLUGS from 'resources/slugs';

function useFetchUserDeclaredDetails() {
    // TODO: remove this fetch, and use only clientSettings
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    const {
        data = {},
        error,
        isLoading
    } = useQuery(['user_declared_data'], () => fetchAuth('/user_client_details/get'), {
        retry: false,
        refetchOnMount: true,
        onSuccess: (data) => {
            if (!data) {
                Sentry.captureException(new Error('empty result'), {
                    tags: ['user_declared_data']
                });
                push(SLUGS.error404);
            }
        },
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['user_declared_data']
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        isLoading,
        error,
        data
    };
}

function useAddOrgDetailsToUser() {
    const fetchAuth = useFetchAuth();
    const { push } = useHistory();
    const queryCache = useQueryClient();
    function post(payload) {
        return fetchAuth('/user_client_details/update', {
            method: 'PUT',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onSuccess: async () => {
            await queryCache.invalidateQueries('user_declared_data');
            await queryCache.refetchQueries(['user_declared_data']);
            await queryCache.invalidateQueries({ queryKey: 'clientSettings' });
            await queryCache.refetchQueries([{ queryKey: 'clientSettings' }]);
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['user_client_details_update'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

export default function useUserDeclaredDetails() {
    const { data, error, isLoading } = useFetchUserDeclaredDetails();
    const {
        data: dataUpdatingDetails,
        error: errorUpdatingDetails,
        isLoading: isLoadingUpdatingDetails,
        mutate: addToUserOrgDetails
    } = useAddOrgDetailsToUser();

    return {
        isLoading,
        data,
        error,
        isLoadingUpdatingDetails,
        errorUpdatingDetails,
        dataUpdatingDetails,
        addToUserOrgDetails
    };
}
