import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 19}
        viewBox='0 2 26 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M33.9079 12.2761C34.359 11.9348 34.9691 11.9101 35.4431 12.201L35.5586 12.2803L47.4621 21.3815C48.0603 21.8388 48.1744 22.6945 47.7171 23.2927C47.2866 23.8557 46.5033 23.9899 45.914 23.6224L45.8059 23.5477L34.725 15.0757L23.5219 23.5519C22.9568 23.9794 22.1661 23.8996 21.6963 23.388L21.6121 23.2871C21.1845 22.7219 21.2644 21.9312 21.7759 21.4614L21.8769 21.3772L33.9079 12.2761Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.114 0.566735C13.8256 0.566735 14.4025 1.09955 14.4025 1.75682L14.4025 3.3436C14.4025 4.00087 13.8256 4.53369 13.114 4.53369C12.4024 4.53369 11.8255 4.00087 11.8255 3.3436L11.8255 1.75682C11.8255 1.09955 12.4024 0.566735 13.114 0.566735Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.69532 6.58963C2.88912 6.31402 3.28813 6.23568 3.58654 6.41467L5.02742 7.27889C5.32583 7.45788 5.41065 7.82641 5.21685 8.10202C5.02306 8.37764 4.62405 8.45597 4.32563 8.27699L2.88476 7.41276C2.58634 7.23378 2.50153 6.86525 2.69532 6.58963Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.2563 6.58963C23.0625 6.31402 22.6635 6.23568 22.3651 6.41467L20.9242 7.27889C20.6258 7.45788 20.541 7.82641 20.7348 8.10202C20.9286 8.37764 21.3276 8.45597 21.626 8.27699L23.0669 7.41276C23.3653 7.23378 23.4501 6.86525 23.2563 6.58963Z'
            fill={props.fill || '#424147'}
        />
        <path
            d='M14.3969 14.4511C14.3969 14.4511 13.47 14.4511 13.1086 14.4511C11.8202 14.4511 11.8203 14.4511 11.8203 14.4511C11.8203 12.479 12.1282 8.104 13.2924 8.104C14.4567 8.104 14.3969 14.4511 14.3969 14.4511Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.42481 3.9821C5.60214 1.68839 8.8585 0.170166 13.1143 0.170166C17.3702 0.170166 20.6265 1.68839 22.8039 3.9821C24.9581 6.25149 25.9997 9.21775 25.9997 12.071C25.9997 12.1107 25.9997 12.1502 25.9997 12.1894C25.9999 12.7837 26.0001 13.3149 25.95 13.7248C25.8982 14.1481 25.7619 14.7823 25.1612 15.2232C24.6208 15.62 23.9548 15.6591 23.5499 15.6729C23.2808 15.682 22.9295 15.6781 22.5458 15.6739C22.3122 15.6713 22.0667 15.6685 21.8205 15.6685C21.1089 15.6685 20.532 15.1357 20.532 14.4785C20.532 13.8212 21.1089 13.2884 21.8205 13.2884C22.1675 13.2884 22.4475 13.2919 22.6844 13.2948C22.9744 13.2985 23.1997 13.3013 23.4042 13.2959C23.4223 13.0311 23.4226 12.6545 23.4226 12.071C23.4226 9.72702 22.5621 7.3379 20.8661 5.55122C19.1931 3.78886 16.6511 2.55034 13.1143 2.55034C9.57757 2.55034 7.03552 3.78886 5.36259 5.55121C3.66657 7.33789 2.80605 9.72702 2.80605 12.071C2.80605 12.3234 2.80422 12.5359 2.80264 12.7181C2.80059 12.9558 2.79898 13.142 2.80247 13.2983C3.02254 13.3027 3.26834 13.2997 3.57978 13.2958C3.85672 13.2924 4.18556 13.2884 4.59436 13.2884C5.30599 13.2884 5.88289 13.8212 5.88289 14.4785C5.88289 15.1357 5.30599 15.6685 4.59436 15.6685C4.28468 15.6685 3.9839 15.6719 3.70485 15.675C3.2971 15.6795 2.93574 15.6835 2.66083 15.6758C2.25016 15.6644 1.54351 15.6367 0.990749 15.2255C0.357267 14.7542 0.270501 14.0672 0.241497 13.6816C0.219391 13.3876 0.222629 13.0014 0.226176 12.5783C0.227556 12.4137 0.228983 12.2436 0.228983 12.071C0.228983 9.21775 1.27058 6.25149 3.42481 3.9821ZM2.41168 13.2717C2.4117 13.2717 2.41237 13.2718 2.41364 13.2721L2.41168 13.2717ZM2.83008 13.658C2.83226 13.6649 2.8331 13.6687 2.83298 13.669C2.83286 13.6692 2.83177 13.6657 2.83008 13.658ZM23.3634 13.5979C23.3633 13.5978 23.364 13.5956 23.3655 13.5915C23.3642 13.596 23.3634 13.598 23.3634 13.5979Z'
            fill={props.fill || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
