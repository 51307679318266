import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';

export default function useCRMTypes() {
    const fetchAuth = useFetchAuth();
    return useQuery(['crm_types'], () => fetchAuth('/crm_types'), {
        retry: 3,
        retryDelay: 1000,
        refetchOnMount: false,
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['crm_types']
            });
            console.log(err);
        }
    });
}
