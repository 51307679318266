import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import queryString from 'query-string';
import { Column, Row } from 'components/simpleFlexbox';
import Modal from 'react-modal';
import { useMultiOrgUsers } from 'hooks/useClientSettings';
import { timeout } from 'resources/utilities';
import { IconCross } from 'assets/icons';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';
import TooltipComponent from 'components/popover/TooltipComponent';

const useStyles = createUseStyles((theme) => ({
    separator: {
        borderBottom: `2px solid ${theme.color.grey2}`,
        maxWidth: '100%'
    },
    title: {
        fontSize: 30,
        lineHeight: '43px'
    },
    closeButtonContainer: {
        marginTop: 20,
        paddingBottom: 40
    },
    closeButton: {
        outline: 'none',
        width: 178,
        height: 40,
        padding: 0,
        fontSize: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        fontWeight: 250
    },
    organizationItem: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.color.purpleHeaders,
            transition: 'all 0.4s ease-in-out'
        }
    },
    crossContainer: {
        cursor: 'pointer',
        position: 'absolute',
        top: 20,
        right: 20
    },
    noAvailable: {
        fontSize: 22,
        fontWeight: 390,
        marginTop: 10,
        marginBottom: 20
    },
    tooltip: {
        marginLeft: 8,
        marginTop: 8
    },
    helpLink: {
        fontWeight: 400,
        textDecoration: 'underline',
        letterSpacing: '-0.35px',
        color: '#ffffff',
        '&:hover': {
            color: theme.color.purpleHeaders
        }
    }
}));

function SelectOrganization() {
    const { push } = useHistory();
    const { search } = useLocation();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);

    const parsed = queryString.parse(search);
    const open = parsed.select_organization === 'true';
    const {
        response = {},
        isLoading: isLoadingClientSettings,
        changeOrganization
    } = useMultiOrgUsers({ enabled: open });
    const organizations = (response.success && response.data) || [];

    const isLoading = isLoadingClientSettings || isLoadingLocal;

    function onRequestClose() {
        push({ search: '' });
    }

    async function onSubmit({ client_name }) {
        setIsLoadingLocal(true);
        try {
            await changeOrganization({ client_name });
            await timeout(2000);
            onRequestClose();
            await timeout(300);
            window.location.replace('/home');
        } catch (ex) {
            console.log(ex);
        } finally {
            setIsLoadingLocal(false);
            onRequestClose();
        }
    }

    return (
        <>
            <LoadingComponent loading={isLoading} />
            <Modal
                isOpen={open}
                onRequestClose={() => {}}
                style={{
                    overlay: {
                        background: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        padding: '40px 36px 19px 36px',
                        maxWidth: 530,
                        minHeight: 400,
                        borderRadius: 10,
                        position: 'relative',
                        top: '',
                        bottom: '',
                        left: '',
                        right: '',
                        width: '100%',
                        marginTop: 30,
                        marginBottom: 30,
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
                id='select_organization'
                ariaHideApp={false}
            >
                <>
                    <div className={classes.crossContainer} onClick={onRequestClose}>
                        <IconCross width={32} height={32} color={theme.color.charcoal} />
                    </div>
                    <Column style={{ marginBottom: 120, height: '100%' }}>
                        <div className={classes.title} style={{ marginBottom: 50 }}>
                            Select an organization
                        </div>
                        <div className={classes.separator} />
                        {organizations.length ? (
                            <Column>
                                {organizations.map((organization) => (
                                    <React.Fragment
                                        key={`select_organization_${organization.client_name}`}
                                    >
                                        <div
                                            className={[
                                                classes.title,
                                                classes.organizationItem
                                            ].join(' ')}
                                            style={{ marginBottom: 12 }}
                                            onClick={() =>
                                                onSubmit({ client_name: organization.client_name })
                                            }
                                        >
                                            {organization.client_name_external}
                                        </div>
                                        <div className={classes.separator} />
                                    </React.Fragment>
                                ))}
                            </Column>
                        ) : (
                            <React.Fragment>
                                <Row>
                                    <div
                                        className={[classes.noAvailable].join(' ')}
                                        style={{ marginBottom: 12 }}
                                    >
                                        No organizations available
                                    </div>
                                    <TooltipComponent
                                        type='info'
                                        message={
                                            <div>
                                                Contact{' '}
                                                <a
                                                    className={classes.helpLink}
                                                    href={'mailto:support@dataro.io'}
                                                >
                                                    support@dataro.io
                                                </a>{' '}
                                                if you would like an organization to be added to
                                                your account
                                            </div>
                                        }
                                        className={classes.tooltip}
                                        size={24}
                                    />
                                </Row>
                                <div className={classes.separator} />
                            </React.Fragment>
                        )}
                        <Row horizontal='flex-end' className={classes.closeButtonContainer}>
                            <ButtonComponent
                                id='btn_switch'
                                className={classes.closeButton}
                                onClick={onRequestClose}
                            >
                                Close
                            </ButtonComponent>
                        </Row>
                    </Column>
                </>
            </Modal>
        </>
    );
}

export default SelectOrganization;
