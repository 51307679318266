import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 31}
        height={props.height || 32}
        viewBox='0 0 31 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.24093 10.5336C6.82577 13.3662 8.48233 16.5541 11.1007 19.3954C13.7191 22.2366 16.7615 24.1475 19.537 24.9612C22.3293 25.7798 24.7229 25.462 26.2315 24.0717C26.5986 23.7334 26.9037 23.3422 27.1485 22.9039L27.1605 22.8824L27.1742 22.8617C27.2876 22.6901 27.3753 22.5038 27.4355 22.3097C27.625 21.6071 27.4763 20.786 26.9529 20.2523L25.1922 18.4572C24.4115 17.6612 23.1181 17.643 22.2716 18.4231L19.2065 21.2478L18.7938 20.9981C17.0657 19.9526 15.3662 18.5706 13.8183 16.8909C12.2704 15.2113 11.0314 13.4049 10.1302 11.5973L9.91503 11.1656L12.9801 8.34089C13.8267 7.56077 13.9138 6.27022 13.1842 5.42727L11.5385 3.52611C11.0523 2.96446 10.2677 2.74756 9.56146 2.86994L9.53643 2.87545C9.33483 2.91944 9.13889 2.99231 8.95577 3.09291L8.93411 3.10481L8.91164 3.11506C8.45484 3.32338 8.04007 3.59557 7.67303 3.93382C6.16444 5.32411 5.65257 7.68391 6.24093 10.5336ZM9.3322 1.6083L9.33105 1.604C9.30843 1.61045 9.28586 1.617 9.26333 1.62367C8.95715 1.69286 8.65809 1.80398 8.37606 1.95625C7.80504 2.21929 7.27863 2.56516 6.80985 2.99718C4.8221 4.82904 4.34246 7.74517 4.97748 10.8208C5.61601 13.9135 7.39769 17.3002 10.1424 20.2785C12.8872 23.2568 16.1174 25.3086 19.1478 26.197C22.1614 27.0806 25.107 26.8402 27.0947 25.0083C27.5635 24.5763 27.9511 24.0798 28.2598 23.5322C28.4346 23.2635 28.5697 22.9744 28.6636 22.6749C28.6721 22.653 28.6804 22.6311 28.6887 22.609L28.6832 22.6071C28.9675 21.5394 28.7652 20.239 27.8845 19.3411L26.1238 17.5459C24.8579 16.2553 22.7694 16.2321 21.4084 17.4864L19.0725 19.6392C17.5886 18.6901 16.1252 17.4711 14.7766 16.0078C13.428 14.5444 12.3324 12.9865 11.5074 11.4303L13.8433 9.27754C15.2044 8.02325 15.3514 5.93986 14.1683 4.57301L12.5226 2.67186C11.6984 1.71974 10.4256 1.40691 9.3322 1.6083Z'
            fill={props.fill || '#BFBFBF'}
        />
    </svg>
);

export default forwardRef(Icon);
