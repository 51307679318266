import React from 'react';

const functionsAttached = {};

export function getFundraisingIntelligenceSteps({ classes, tourType }) {
    const steps = [
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>
                        Fundraising Intelligence is located here
                    </div>
                    <div>
                        Click on the icon to access your fundraising reports and benchmarking
                        results.
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#menu_fundraising_intelligence_icon',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableScrolling: true,
            id: 'fundraising_intelligence_1'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Select your fundraising program</div>

                    <div>
                        Fundraising analytics and benchmarks are grouped by fundraising programs
                        such as.Single Giving, Recurring Giving etc.
                        <br />
                        <br />
                        <i>
                            Click on <b>“Single Giving”</b> to access key cash giving metrics like
                            Average Gift, Lifetime Value and benchmark your results against industry
                        </i>
                        <br />
                        <br />
                        <span> </span>
                    </div>
                </div>
            ),
            placement: 'right',
            target: '#fundraising-intelligence-box-single-giving',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableScrolling: false,
            id: 'fundraising_intelligence_2'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Select the reports you want</div>
                    <div>
                        Each set of reports comes with a library of helpful analytics and
                        benchmarking features. Let's look at the Acquisition & Conversion report to
                        see how many donors you have acquired and converted.
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'right',
            target: '#link_New_donors_to_program_Acquisition_and_Conversion',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableScrolling: true,
            id: 'fundraising_intelligence_3'
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Explore fundraising results like a pro</div>
                    <div>Use the Groups, Metric and Date Range filters to explore your data.</div>
                </div>
            ),
            placement: 'left-end',
            target: '#fi-filters-metric',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableScrolling: true,
            disableOverlay: true,
            id: 'fundraising_intelligence_4',
            stepStyles: {
                containerWidth: 350,
                containerMaxWidth: 350,
                skipButtonRight: 10
            }
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Compare your results with Benchmarks</div>
                    <div>
                        To benchmark your results against industry data select the Benchmark filter
                        - this will add benchmark data to your report alongside your results. You
                        can also use the Compare filter to compare your results against historical
                        performance.
                        <br />
                        <br />
                        <i>
                            Select ‘All organisations’ from the benchmark filter to compare your
                            performance with others
                        </i>
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '.fundraising-intelligence-filter-benchmark',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableOverlay: true,
            id: 'fundraising_intelligence_6',
            stepStyles: {
                containerWidth: 350,
                containerMaxWidth: 350,
                skipButtonRight: 10
            }
        },
        {
            content: (
                <div>
                    Now you have the results you want. You can share this data as Excel file, PDF
                    report or image to add to your own reporting documents. <br /> <br />
                    <i>Click “Download Results”</i>
                    <br />
                    <br />
                </div>
            ),
            placement: 'left',
            target: '#export-dropdown',
            nextText: 'Next',
            backText: 'Back',
            id: 'fundraising_intelligence_8',
            hasSpotlightClicks: true,
            disableOverlay: true,
            stepStyles: {
                skipButtonRight: 10
            }
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Stay on top with our analysis tips</div>
                    <div>
                        Each report comes with a detailed description and tips for reviewing your
                        data and getting better insights.
                        <br />
                        <br />
                        <i>
                            Click on the Description & Tips link to see a list of questions this
                            report answers
                        </i>
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'left',
            target: '#report-insight',
            nextText: 'Next',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableScrolling: false,
            disableOverlay: true,
            id: 'fundraising_intelligence_9',
            stepStyles: {
                containerWidth: 350,
                containerMaxWidth: 350,
                skipButtonRight: 10
            }
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>You’re all set!</div>
                    <div>
                        Thanks for taking the tour! You are all set to identify which fundraising
                        programs you can improve on. To manage your subscriptions or add more users
                        and propensities go to the Settings tab.
                        <br />
                        If you have any questions or issues, jump onto the chat on the lower right
                        corner of your screen and we would be happy to help
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#menu_settings',
            nextText: 'Start Dataro Predict Tour',
            backText: 'Back',
            hasSpotlightClicks: true,
            disableOverlay: true,
            stepStyles: {
                buttonWidth: 260,
                containerWidth: 500,
                containerMaxWidth: 500,
                buttonNextStyles: {
                    height: 32,
                    padding: '3px 3px'
                }
            },
            id: 'fundraising_intelligence_10'
        }
    ];

    if (tourType === 'dataro_predict') {
        steps[steps.length - 1].nextText = 'Finish tour';
    }
    return steps;
}

export function onFundraisingIntelligenceStepEffect({
    steps = [],
    stepIndex,
    theme,
    localState,
    setSteps
}) {
    const currentStep = steps[stepIndex] || {};

    const btnNextSelector = '#btn_onboarding_next';
    const boxSingleGivingSelector = '#fundraising-intelligence-box-single-giving';
    const fiAvailableReportsSelector = '#link_New_donors_to_program_Acquisition_and_Conversion';

    function onNextClick() {
        const nextBtnElement = document.querySelector(btnNextSelector);
        nextBtnElement && nextBtnElement.click();
    }

    return [
        () => {
            if (
                currentStep.id === 'fundraising_intelligence_1' &&
                localState['fundraising_intelligence_1']
            ) {
                const menuItem = '#menu_fundraising_intelligence_icon';
                const element = document.querySelector(menuItem);
                if (element) {
                    element.addEventListener('click', onNextClick);
                    functionsAttached[menuItem] = onNextClick;
                }
            }
            if (
                currentStep.id === 'fundraising_intelligence_2' &&
                localState['fundraising_intelligence_2']
            ) {
                const element = document.querySelector(boxSingleGivingSelector);
                if (element) {
                    element.addEventListener('click', onNextClick);
                    functionsAttached[boxSingleGivingSelector] = onNextClick;
                    setTimeout(() => {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        setTimeout(() => {
                            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            setTimeout(() => {
                                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }, 500);
                        }, 500);
                    }, 500);
                }
            }
            if (
                currentStep.id === 'fundraising_intelligence_3' &&
                localState['fundraising_intelligence_3']
            ) {
                document.querySelector('#main_container').style.marginTop = '120px';
                const element = document.querySelector(fiAvailableReportsSelector);
                if (element) {
                    element.addEventListener('click', onNextClick);
                    functionsAttached[fiAvailableReportsSelector] = onNextClick;
                }
            }

            if (
                currentStep.id === 'fundraising_intelligence_10' &&
                localState['fundraising_intelligence_10']
            ) {
                document.querySelector('#main_container').style.marginBottom = '150px';
            }
        },
        () => {
            if (currentStep.id === 'fundraising_intelligence_2') {
                const menuItem = '#menu_fundraising_intelligence_icon';
                const menuItemElement = document.querySelector(menuItem);
                if (menuItemElement) {
                    menuItemElement.removeEventListener('click', functionsAttached[menuItem]);
                }
            }
            if (currentStep.id === 'fundraising_intelligence_3') {
                const menuItemElement = document.querySelector(boxSingleGivingSelector);
                if (menuItemElement) {
                    menuItemElement.removeEventListener(
                        'click',
                        functionsAttached[boxSingleGivingSelector]
                    );
                }
            }
            if (currentStep.id === 'fundraising_intelligence_4') {
                const menuItemElement = document.querySelector(fiAvailableReportsSelector);
                if (menuItemElement) {
                    menuItemElement.removeEventListener(
                        'click',
                        functionsAttached[fiAvailableReportsSelector]
                    );
                }
            }
            if (currentStep.id === 'fundraising_intelligence_10') {
                document.querySelector('#main_container').style.marginTop = '60px';
            }
        }
    ];
}
