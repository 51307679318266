import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

function FormComponent({ children, defaultValues, onSubmit, style, className, id, forceReset }) {
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });

    useEffect(() => {
        if (forceReset) {
            methods.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceReset]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={style}
                className={className}
                id={id}
            >
                {children}
            </form>
        </FormProvider>
    );
}

export default FormComponent;
