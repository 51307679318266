import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 31}
        height={props.height || 32}
        viewBox='0 0 256 256'
        fill={props.fill || '#BFBFBF'}
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path d='M231.626,128a16.015,16.015,0,0,1-8.18262,13.96094L54.53027,236.55273a15.87654,15.87654,0,0,1-18.14648-1.74023,15.87132,15.87132,0,0,1-4.74024-17.60156L60.64746,136H136a8,8,0,0,0,0-16H60.64746L31.64355,38.78906A16.00042,16.00042,0,0,1,54.5293,19.44727l168.915,94.59179A16.01613,16.01613,0,0,1,231.626,128Z' />
    </svg>
);

export default forwardRef(Icon);
