import React, { useRef } from 'react';
import { Column, Row } from 'components/simpleFlexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { IconLock, IconNotification, IconArrowRight } from 'assets/icons';
import Popover from 'components/popover/PopoverComponent';
import TooltipComponent from 'components/popover/TooltipComponent';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles((theme) => ({
    separator: {
        borderBottom: '0.8px solid white',
        marginTop: 12,
        marginBottom: 12,
        maxWidth: '100%'
    },
    sub2ItemContainer: {
        backgroundColor: 'white',
        borderLeft: 'none !important',
        borderRadius: '0px 10px 10px 10px',
        left: 'calc(100%)-10px',
        top: ({ hasHeader }) => (!hasHeader ? 40 : 98),
        padding: ({ hasItems, hasHeader }) =>
            hasHeader ? null : hasItems ? '14px 18px 14px 14px' : '14px 14px 14px 24px',
        position: 'absolute',
        zIndex: 12
    },
    subItemList: {
        padding: ({ hasHeader }) => (hasHeader ? 14 : 0),
        minWidth: ({ hasItems }) => (hasItems ? 120 : 'none')
    },
    subItemListTitle: {
        ...theme.typography.subHeading,
        color: 'black',
        fontWeight: 420,
        fontSize: 22,
        marginBottom: ({ hasItems }) => (hasItems ? 8 : 'none'),
        textAlign: 'left',
        whiteSpace: 'nowrap',
        width: '100%'
    },
    subItemListItem: {
        whiteSpace: 'nowrap',
        margin: '0px -14px 0px -14px',
        padding: '1px 14px',
        color: 'black',
        '& > div': {
            ...theme.typography.body2,
            fontWeight: 390,
            fontSize: 18,
            padding: '0px 0px',
            letterSpacing: '-0.12px'
        }
    },
    subItemListItemDisabled: {
        whiteSpace: 'nowrap',
        margin: '2px -14px 0px -14px',
        padding: '0px 14px',
        '& > .bapple-tooltip > .bapple-tooltip-content': {
            filter: 'drop-shadow(2px 4px 3px rgb(0 0 0 / 0.4))'
        }
    },
    subItemListItemDisabledAIAssist: {
        whiteSpace: 'nowrap',
        margin: '2px -14px 0px -14px',
        padding: '0px 14px',
        color: 'grey',
        cursor: 'not-allowed'
    },
    subItemListItemDisabledContent: {
        '& > div': {
            ...theme.typography.body2,
            fontWeight: 390,
            fontSize: 18,
            padding: '2px 0px'
        },
        '& > svg': {
            marginTop: 8,
            marginLeft: 7
        },
        color: 'grey',
        cursor: 'not-allowed'
    },
    tooltipForDisabledItem: {
        width: 'auto',
        left: '82%',
        top: '40px',
        '& > div': {
            left: '63% !important'
        }
    }
}));

function SubItemContent({ hasHeader, item, onSubItemClicked }) {
    const theme = useTheme();
    const classes = useStyles({ theme, hasHeader });
    const { items } = item;
    const hasSubItems = items && items.length > 0;
    const popoverSubItemRef = useRef(null);
    const hideItem = !!item.hideItem ? item.hideItem : false;

    if (hideItem) {
        return null;
    }

    if (!item.isDisabledItem) {
        return (
            <Row
                key={`menu-sub-item-${item.title}`}
                id={`submenu_${item.id}`}
                horizontal='space-between'
                className={classes.subItemListItem}
                onClick={() => onSubItemClicked(item)}
                componentRef={(e) => {
                    popoverSubItemRef.current = e;
                }}
            >
                <Row id={`submenu_title_${item.id}`} flexGrow={1} horizontal='space-between'>
                    <Link
                        style={{
                            color: theme.color.charcoal
                        }}
                        to={item.fullPath ?? ''}
                    >
                        {item.title}
                    </Link>
                    {hasSubItems && (
                        <div className='mt-[10px] ml-[12px]'>
                            <IconArrowRight fill={theme.color.charcoal} width={6} height={8} />
                        </div>
                    )}
                </Row>
                {hasSubItems && (
                    <Popover itemRef={popoverSubItemRef} hideOnClose>
                        <div className={classes.sub2ItemContainer} id={`menu_${item.id}_subitems`}>
                            <Column className={classes.subItemList}>
                                <span className={classes.subItemListTitle}>{item.title}</span>
                                {(items || []).map((subItem, index) => {
                                    if (subItem.isSeparator) {
                                        return (
                                            <div
                                                key={`subitem-${index}`}
                                                className={classes.separator}
                                            />
                                        );
                                    }
                                    return (
                                        <SubItemContent
                                            key={`subitem-${index}`}
                                            classes={classes}
                                            item={subItem}
                                            onSubItemClicked={onSubItemClicked}
                                            sectionTitle={subItem.title}
                                        />
                                    );
                                })}
                            </Column>
                        </div>
                    </Popover>
                )}
            </Row>
        );
    }

    if (item.isDisabledItem && !!item.showDisabledItem && item.showDisabledItem) {
        return (
            <Row
                key={`menu-sub-item-${item.title}`}
                id={`submenu_${item.id}`}
                horizontal='space-between'
                className={classes.subItemListItemDisabledAIAssist}
                onClick={() => {}}
            >
                <div>{item.title}</div>
            </Row>
        );
    }

    return (
        <TooltipComponent
            type={item.tooltipType}
            message={<div style={{ textAlign: 'left' }}>{item.tooltipText}</div>}
            className={classes.subItemListItemDisabled}
            content={
                <Row
                    key={`menu-sub-item-${item.title}`}
                    id={`submenu_${item.id}`}
                    horizontal='space-between'
                    className={classes.subItemListItemDisabledContent}
                    onClick={() => {}}
                >
                    <div>{item.title}</div>
                    {item.tooltipType !== 'info' ? (
                        <IconLock height={20} width={20} />
                    ) : (
                        <IconNotification height={20} width={20} />
                    )}
                </Row>
            }
            tooltipClassName={classes.tooltipForDisabledItem}
            size={25}
        />
    );
}

export default SubItemContent;
