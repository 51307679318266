import { useEffect } from 'react';

export default function useExternalScripts({ id, defer, url, include, content, type }) {
    useEffect(() => {
        if (!include) {
            return () => true;
        }
        const script = document.createElement('script');
        script.id = id;

        if (!content) {
            script.src = url;
        } else {
            script.innerHTML = content();
        }

        if (defer) {
            script.defer = defer;
        }
        script.async = true;
        if (type) {
            script.type = type;
        }
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [defer, id, include, url, content, type]);
}
