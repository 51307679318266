import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 22}
        height={props.height || 22}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <line
            x1='1'
            y1='11'
            x2='21'
            y2='11'
            stroke={props.fill || 'black'}
            strokeWidth='2'
            strokeLinecap='round'
        />
    </svg>
);

export default forwardRef(Icon);
