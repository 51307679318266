import { useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl, timeout } from 'resources/utilities';
import { useUserPool } from './auth/useAuth';

function useFetchAuth() {
    const { push } = useHistory();
    const { getIdToken, isLoggedIn } = useUserPool();
    const baseUrl = process.env.REACT_APP_BASEURL;

    async function fetchAuth(url, options, retries = 3) {
        if (!isLoggedIn()) {
            return push(convertSlugToUrl(SLUGS.login));
        }
        const authToken = await getIdToken();
        try {
            const result = await fetch(`${baseUrl}${url}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authToken}`
                },
                ...options
            });
            const data = await result.json();
            return data;
        } catch (e) {
            if (retries > 0) {
                console.log('retrying');
                await timeout(1000);
                return fetchAuth(url, options, retries - 1);
            }
            console.error(e.message);
            throw e;
        }
    }

    return fetchAuth;
}

export default useFetchAuth;
