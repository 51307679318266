import React from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarProvider } from 'hooks/useSidebar';
import SidebarComponent from './Sidebar';

const SidebarContext = () => {
    const { pathname } = useLocation();
    let defaultPath;
    switch (pathname) {
        case '/campaign_size':
        case '/predictions':
        case '/performance':
        case '/donor_list':
        case '/campaigns':
            defaultPath = `/predict${pathname}`;
            break;
        default:
            defaultPath = pathname;
    }
    return (
        <SidebarProvider defaultPath={defaultPath}>
            <SidebarComponent />
        </SidebarProvider>
    );
};
export default SidebarContext;
