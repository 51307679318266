import React, { forwardRef } from 'react';
import { Column } from 'components/simpleFlexbox';
import TooltipComponent from 'components/popover/TooltipComponent';
import './select.css';

function SelectComponent(
    {
        className,
        label,
        labelClass,
        name,
        errorMessage,
        children,
        showErrorMessageBelow,
        ...otherProps
    },
    ref
) {
    const containerClassName = [
        'select-container',
        className,
        errorMessage ? 'select-container-error' : ''
    ].join(' ');

    return (
        <Column className={containerClassName}>
            {label && (
                <label htmlFor={name} className={labelClass}>
                    {label}
                </label>
            )}
            <select ref={ref} name={name} {...otherProps}>
                {children}
            </select>
            {errorMessage && (
                <>
                    {!showErrorMessageBelow ? (
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 10
                                }}
                            >
                                <TooltipComponent type='error' message={errorMessage} />
                            </div>
                        </div>
                    ) : (
                        <span className='select-container-error-message'>{errorMessage}</span>
                    )}
                </>
            )}
        </Column>
    );
}

export default forwardRef(SelectComponent);
