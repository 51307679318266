import React from 'react';

const FrillWidget = React.memo(() => {
    React.useEffect(() => {
        let widget;

        const config = {
            key: process.env.REACT_APP_FRILL_ID,
            callbacks: {
                onReady: (frillWidget) => {
                    widget = frillWidget;
                }
            }
        };

        if ('Frill' in window) {
            widget = window.Frill.widget(config);
        } else {
            window.Frill_Config = window.Frill_Config || [];
            window.Frill_Config.push(config);
        }

        return () => {
            if (widget) {
                widget.destroy();
            }
            if (window.Frill_Config) {
                window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
            }
        };
    }, []);

    return null;
});

export default FrillWidget;
