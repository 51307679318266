import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'components/simpleFlexbox';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl } from 'resources/utilities';
import useProducts from 'hooks/useProducts';
import useUpgradeAccount from 'hooks/useUpgradeAccount';
import useClientSettings from 'hooks/useClientSettings';
import ButtonComponent from 'components/form/ButtonComponent';
import LoadingComponent from 'components/loading';

const useStyles = createUseStyles((theme) => ({
    button: {
        outline: 'none',
        width: 200
    },
    title: {
        ...theme.typography.heading,
        fontSize: 26,
        marginBottom: 8
    },
    title2: {
        ...theme.typography.body1,
        marginBottom: 8
    },
    subTitle: {
        ...theme.typography.body2,
        color: theme.color.sidebarIconColor,
        fontSize: 18,
        lineHeight: '26px'
    }
}));

function OnboardingBanner() {
    const { push } = useHistory();
    const isHomeRoute = useRouteMatch(SLUGS.home);
    const theme = useTheme();
    const classes = useStyles({ theme, isHome: isHomeRoute });
    const settingsParams = [
        'onboarding_demo_completed',
        'client_name',
        'client_status',
        'initial_product_preference',
        'has_contract',
        'demo_client',
        'upgrade_requested',
        'is_global_parent'
    ];
    const {
        data: {
            client_status,
            has_contract,
            demo_client,
            upgrade_requested,
            initial_product_preference,
            is_global_parent
        } = {},
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });
    const { crmHasFiFree, isLoading: isLoadingProducts, isSalesClient } = useProducts();
    const { isLoading: isLoadingUpgradeAccount, createClient } = useUpgradeAccount();

    const fiText = is_global_parent ? 'Global Intelligence' : 'Fundraising Intelligence';

    const isLoading = isLoadingProducts || isLoadingUpgradeAccount || isLoadingClientSettings;

    const isDemoClient = demo_client;
    const isIntegrationCompleted = client_status === 'INTEGRATION_COMPLETED';

    const showContinueSetupButton =
        !isIntegrationCompleted && !isDemoClient && (has_contract || crmHasFiFree);
    const showConnectCRMButton = !showContinueSetupButton && isDemoClient && !upgrade_requested;

    const showUpgradeButton = !showConnectCRMButton && !isDemoClient && !upgrade_requested;

    function onUpgradeButtonClick() {
        return window.open('https://buy.stripe.com/8wMbLD1dFcY186IeUW');
    }

    function onContinueSetupButtonClick() {
        push(isSalesClient ? SLUGS.accountSetupSales : SLUGS.accountSetup);
    }

    const ContinueButton = () => (
        <Column horizontal='center'>
            <ButtonComponent
                className={classes.button}
                id='btn-continue-setup'
                onClick={onContinueSetupButtonClick}
            >
                Continue Integration
            </ButtonComponent>
        </Column>
    );

    const UpgradeButton = () => (
        <Column horizontal='center'>
            <ButtonComponent
                className={classes.button}
                id='btn-upgrade-account'
                onClick={onUpgradeButtonClick}
            >
                Upgrade Account
            </ButtonComponent>
        </Column>
    );

    const InTouchMessage = () => (
        <span
            id='onboarding-banner-intouch-message'
            className={classes.subTitle}
            style={{ marginTop: 20 }}
        >
            You've requested a full account, we'll be in touch with you shortly.
        </span>
    );

    const ConnectCRMButton = () => {
        if (isDemoClient && !crmHasFiFree) {
            return <ScheduleDemoButton />;
        }
        function onConnectYourDataClick() {
            if (isDemoClient) {
                if (crmHasFiFree) {
                    createClient();
                } else {
                    push({ search: '?showCalendar=true' });
                }
            } else {
                push(convertSlugToUrl(SLUGS.accountSetup, { step: 0 }));
            }
        }
        return (
            <Column horizontal='center'>
                <span className={classes.title2}>Ready to get started?</span>
                <ButtonComponent
                    id='btn_connect_crm'
                    className={classes.button}
                    onClick={onConnectYourDataClick}
                >
                    Connect your data
                </ButtonComponent>
            </Column>
        );
    };

    const ScheduleDemoButton = () => {
        function onScheduleDemoClick() {
            push({ search: '?showCalendar=true' });
        }
        return (
            <Column horizontal='center'>
                <span className={classes.title2}>Ready to get started?</span>
                <ButtonComponent
                    id='btn_schedule_demo'
                    className={classes.button}
                    onClick={onScheduleDemoClick}
                >
                    Schedule a demo
                </ButtonComponent>
            </Column>
        );
    };

    const UpgradeNowButton = () => (
        <Column horizontal='center'>
            <span className={classes.title2}>Ready to get started?</span>
            <ButtonComponent
                id='btn_upgrade_now'
                className={classes.button}
                onClick={onUpgradeButtonClick}
            >
                Upgrade Now
            </ButtonComponent>
        </Column>
    );

    let bannerStyle =
        'bg-grey-nav-bar mt-[25px] mb-[-15px] pl-[18px] pr-[18px] pt-[20px] pb-[20px] rounded-[10px] ';
    if (isHomeRoute) {
        bannerStyle += 'max-w-[calc(100%-50px)]';
    } else {
        bannerStyle += 'mr-[50px]';
    }

    if (isSalesClient) {
        return (
            <>
                <LoadingComponent isLoading={isLoading} />
                <OnboardingBannerContent
                    title='Welcome to your Dataro Demo Account'
                    description={
                        'We are currently setting up your account. In the meantime, you can still explore the demo account. All the data you see is for example purposes only.'
                    }
                    rightContent={<ContinueButton />}
                    bannerStyle={bannerStyle}
                />
            </>
        );
    }

    if (!isIntegrationCompleted && has_contract && !isDemoClient) {
        let buttonToShow = <ConnectCRMButton />;
        let description = `We're so excited to show you what you can do with your Dataro account. Start by connecting your CRM.`;
        if (showContinueSetupButton) {
            buttonToShow = <ContinueButton />;
            description = `We're so excited to show you what you can do with your Dataro account. Let's continue your integration!`;
        } else if (upgrade_requested) {
            buttonToShow = <InTouchMessage />;
        }
        return (
            <>
                <LoadingComponent isLoading={isLoading} />
                <OnboardingBannerContent
                    title='Welcome to your Dataro account'
                    description={description}
                    rightContent={buttonToShow}
                    bannerStyle={bannerStyle}
                />
            </>
        );
    }

    if (initial_product_preference === 'ai_assist') {
        let buttonToShow = <UpgradeNowButton />;
        let description = `With your AI Assist trial you can sample all the features with unlimited queries for 30 days, plus you can take a tour of Dataro Predict and ${fiText} (demo data). If you want to make the most out of AI Assist, upgrade now!`;
        if (showContinueSetupButton) {
            buttonToShow = <ContinueButton />;
            description = `We're so excited to show you what you can do with your Dataro account. Let's continue your integration!`;
        } else if (upgrade_requested) {
            buttonToShow = <InTouchMessage />;
        }
        return (
            <>
                <LoadingComponent isLoading={isLoading} />
                <OnboardingBannerContent
                    title='Welcome to AI Assist'
                    description={description}
                    rightContent={buttonToShow}
                    bannerStyle={bannerStyle}
                />
            </>
        );
    }

    if (initial_product_preference === 'fundraising_intelligence') {
        let buttonToShow = <ConnectCRMButton />;
        let description = `You can now explore Dataro Predict, ${fiText} and trial AI Assist. Where data is shown, it is for demonstration purposes only. If you would like to see live dashboards and benchmarks using your own data, connect now in less than 5 minutes!`;
        if (showContinueSetupButton) {
            buttonToShow = <ContinueButton />;
            description = `We're so excited to show you what you can do with your Dataro account. Let's continue your integration!`;
        } else if (upgrade_requested) {
            buttonToShow = <InTouchMessage />;
        }
        return (
            <>
                <LoadingComponent isLoading={isLoading} />
                <OnboardingBannerContent
                    title='Welcome to your free Dataro account'
                    description={description}
                    rightContent={buttonToShow}
                    bannerStyle={bannerStyle}
                />
            </>
        );
    }

    if (!initial_product_preference || initial_product_preference === 'dataro_predict') {
        let buttonToShow = <ScheduleDemoButton />;
        let description = `You can now explore Dataro Predict, ${fiText} and trial AI Assist. Where data is shown, it is for demonstration purposes only. If you would like to integrate your own data, book a session with our team.`;
        if (showContinueSetupButton) {
            buttonToShow = <ContinueButton />;
            description = `We're so excited to show you what you can do with your Dataro account. Let's continue your integration!`;
        } else if (upgrade_requested) {
            buttonToShow = <InTouchMessage />;
        }
        return (
            <>
                <LoadingComponent isLoading={isLoading} />
                <OnboardingBannerContent
                    title='Welcome to your free Dataro account'
                    description={description}
                    rightContent={buttonToShow}
                    bannerStyle={bannerStyle}
                />
            </>
        );
    }

    return (
        <Row
            flexGrow={1}
            horizontal='space-between'
            vertical='center'
            className={'upgrade-notification ' + bannerStyle}
        >
            <LoadingComponent isLoading={isLoading} />
            <Column flexGrow={1} className='upgrade-notification-box'>
                <span className={classes.title}>Welcome to your Dataro Demo Account</span>
                {!showContinueSetupButton ? (
                    <span className={classes.subTitle}>
                        With your demo account, you can explore the Dataro web app and its features.
                        All the data you see is for example purposes only. If you want to start
                        using Dataro on your own data, connect your CRM.
                    </span>
                ) : (
                    <span className={classes.subTitle}>
                        We are currently setting up your account. In the meantime, you can still
                        explore the demo account. All the data you see is for example purposes only.
                    </span>
                )}
            </Column>
            {showConnectCRMButton ? (
                <ConnectCRMButton />
            ) : showContinueSetupButton ? (
                <ContinueButton />
            ) : showUpgradeButton ? (
                <UpgradeButton />
            ) : (
                <InTouchMessage />
            )}
        </Row>
    );
}

function OnboardingBannerContent({ title, description, rightContent, bannerStyle }) {
    const isHomeRoute = useRouteMatch(SLUGS.home);
    const theme = useTheme();
    const classes = useStyles({ theme, isHome: isHomeRoute });

    return (
        <Row
            horizontal='space-between'
            vertical='center'
            className={'upgrade-notification ' + bannerStyle}
        >
            <Column flexGrow={1} className={`upgrade-notification-box ${classes.firstColumn}`}>
                <span className={classes.title}>{title}</span>
                <span className={classes.subTitle}>{description}</span>
            </Column>
            {rightContent}
        </Row>
    );
}

export default OnboardingBanner;
