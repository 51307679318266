import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import queryString from 'query-string';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import { useUserPool } from 'hooks/auth/useAuth';
import useExternalScripts from 'hooks/useExternalScripts';

const queryClient = new QueryClient();

function Routes() {
    const { isLoggedIn, initAmplify } = useUserPool();
    const { pathname, search } = useLocation();
    useExternalScripts({
        id: 'hs-script-loader',
        defer: true,
        url: '//js.hs-scripts.com/4267685.js',
        include: process.env.REACT_APP_ADD_HUBSPOT === 'true'
    });
    const queryParsed = queryString.parse(search);

    useEffect(() => {
        if (
            ((pathname.indexOf('/settings/') < 0 &&
                pathname.indexOf('/fundraising_intelligence/') < 0) ||
                [
                    '/settings/',
                    '/data_hub/validation',
                    '/data_hub/validation/',
                    '/performance'
                ].includes(pathname)) &&
            !Object.keys(queryParsed).includes('no_scroll')
        ) {
            window.scrollTo(0, 0);
        }
    }, [pathname, queryParsed]);

    useEffect(() => {
        initAmplify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isUserLoggedIn = isLoggedIn();
    return (
        <QueryClientProvider client={queryClient}>
            {isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />}
        </QueryClientProvider>
    );
}

export default Routes;
