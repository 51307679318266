import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg width='34' height='37' viewBox='0 0 34 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M4.3178 0.89053C1.57972 0.578634 -0.0949471 3.81366 1.74045 5.8693L27.8774 35.1427C29.8103 37.3076 33.3847 35.7396 33.1008 32.8512L30.4871 6.25737C30.3488 4.84987 29.2463 3.73015 27.8411 3.57008L4.3178 0.89053Z'
            fill='#424147'
        />
    </svg>
);

export default forwardRef(Icon);
