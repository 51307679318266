import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 23}
        height={props.height || 24}
        viewBox='0 0 23 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.3 3.5C4.47746 3.5 3 4.97746 3 6.8V17.2C3 19.0225 4.47746 20.5 6.3 20.5H16.7C18.5225 20.5 20 19.0225 20 17.2C20 16.3716 20.6716 15.7 21.5 15.7C22.3284 15.7 23 16.3716 23 17.2C23 20.6794 20.1794 23.5 16.7 23.5H6.3C2.82061 23.5 0 20.6794 0 17.2V6.8C0 3.32061 2.82061 0.5 6.3 0.5H16.7C20.1794 0.5 23 3.32061 23 6.8C23 7.62843 22.3284 8.3 21.5 8.3C20.6716 8.3 20 7.62843 20 6.8C20 4.97746 18.5225 3.5 16.7 3.5H6.3ZM14.892 13.9996L7.50032 14.0011C6.67189 14.0013 6.00018 13.3299 6 12.5015C5.99982 11.673 6.67125 11.0013 7.49968 11.0011L14.9398 10.9996L14.2089 9.76345C13.7872 9.05036 14.0235 8.13047 14.7366 7.70882C15.4497 7.28717 16.3696 7.52343 16.7912 8.23653L18.7912 11.6189C18.9405 11.8715 19.0099 12.1573 18.9989 12.4409C18.9996 12.4601 19 12.4793 19 12.4987C19.0001 12.9102 18.8345 13.283 18.5662 13.5541L16.8128 16.7257C16.4119 17.4508 15.4993 17.7136 14.7743 17.3127C14.0493 16.9119 13.7865 15.9992 14.1873 15.2742L14.892 13.9996Z'
            fill={props.fill || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
