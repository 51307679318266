import React from 'react';
import { Row } from 'components/simpleFlexbox';
import { createUseStyles, useTheme } from 'react-jss';
import LogoDataro from 'assets/Dataro_icon_black.png';

const useStyles = createUseStyles({
    image: {
        maxHeight: 64,
        maxWidth: 64
    }
});

function LogoComponent({ onClick }) {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row flexGrow={1} horizontal='center' vertical='center'>
            <img
                className={classes.image}
                onClick={onClick}
                style={{ cursor: 'pointer' }}
                src={LogoDataro}
                alt='logo'
            />
        </Row>
    );
}

export default LogoComponent;
