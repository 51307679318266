import React, { useEffect, useState, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'components/simpleFlexbox';
import Joyride, { STATUS } from 'react-joyride';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { IconCross } from 'assets/icons';
import SLUGS from 'resources/slugs';
import { convertSlugToUrl, timeout } from 'resources/utilities';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import useUserActivity from 'hooks/useUserActivity';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';
import {
    getWelcomeSteps,
    onWelcomeStepEffect,
    getCampaignSizeSteps,
    onCampaignSizeStepEffect,
    getCampaignResultsSteps,
    onCampaignResultsStepEffect,
    getModelPerformanceSteps,
    onModelPerformanceStepEffect,
    onSettingsStepEffect,
    getFundraisingIntelligenceSteps,
    onFundraisingIntelligenceStepEffect,
    getAIAssistSteps,
    onAIAssistStepEffect
} from './steps';

const useStyles = createUseStyles((theme) => ({
    smallTitle: { fontWeight: 700, fontSize: 20, marginBottom: 10 },
    title: {
        ...theme.typography.title,
        color: theme.color.charcoal,
        textAlign: 'center'
    }
}));

const TOUR_TYPES = {
    dataro_predict: 'dataro_predict',
    fundraising_intelligence: 'fundraising_intelligence',
    ai_assist: 'ai_assist'
};

function JoyrideComponent() {
    const theme = useTheme();
    const [stepIndex, setStepIndex] = useState(0);
    const [forceRender, setForceRender] = useState();
    const [selectedCRM, setSelectedCRM] = useState();
    const [tourType, setTourType] = useState();
    const classes = useStyles({ theme });
    const { replace } = useHistory();
    const { pathname, search } = useLocation();
    const queryParsed = queryString.parse(search);
    const settingsParams = [
        'onboarding_demo_completed',
        'first_name',
        'crm_type',
        'client_name',
        'upgrade_requested',
        'initial_product_preference'
    ];
    const {
        data: {
            first_name,
            onboarding_demo_completed,
            crm_type,
            client_name,
            upgrade_requested,
            initial_product_preference
        } = {},
        isLoading: isLoadingClientSettings,
        UpdateClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });
    const { isLoading: isLoadingCRMTypes, data: crm_types = [] } = useCRMTypes();
    const showSelectCRMList = !upgrade_requested && client_name === 'DEMO';

    const [run, setRun] = useState(onboarding_demo_completed === false);
    const [localState, setLocalState] = useState({});
    const [steps, setSteps] = useState([]);
    const refNextPressed = useRef(false);
    const refIsMounted = useRef(false);
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);

    const isLoading = isLoadingLocal || isLoadingClientSettings || isLoadingCRMTypes;

    const { addUserActivity } = useUserActivity();

    function loadDataroPredictSteps() {
        setSteps([
            ...getCampaignSizeSteps({
                classes,
                tourType,
                crm_type: selectedCRM || crm_type,
                crm_types
            }),
            ...getCampaignResultsSteps({ classes }),
            ...getModelPerformanceSteps({ classes, tourType }),
            ...getFundraisingIntelligenceSteps({ classes, tourType })
        ]);
    }

    function loadFundraisingIntelligenceSteps() {
        setSteps([
            ...getWelcomeSteps({
                classes,
                first_name,
                crm_type: selectedCRM || crm_type,
                crm_types,
                onCRMSubmit,
                onClickDataroPredict,
                onClickFI,
                onClickSkip,
                showSelectCRMList,

                tourType: TOUR_TYPES.fundraising_intelligence
            }),
            ...getFundraisingIntelligenceSteps({ classes, tourType }),
            ...getCampaignSizeSteps({
                classes,

                tourType,
                crm_type: selectedCRM || crm_type,
                crm_types
            }),
            ...getCampaignResultsSteps({ classes }),
            ...getModelPerformanceSteps({ classes, tourType })
        ]);
        setTimeout(() => {
            onClickFI();
        }, 1);
    }

    function loadAIAssistSteps() {
        setSteps([...getAIAssistSteps()]);
    }

    useEffect(() => {
        if (onboarding_demo_completed === false) {
            if (pathname !== '/' || pathname !== '/home') {
                const newUrl = `${SLUGS.home}/${search}`;
                replace(newUrl);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const productPreference =
            queryParsed.showOnboardingTour ||
            localStorage.getItem('dataro_initial_product_preference') ||
            initial_product_preference ||
            'dataro_predict';
        setSelectedCRM(crm_type);
        setTourType(productPreference);
        if (tourType === productPreference) {
            return;
        }
        switch (productPreference) {
            case TOUR_TYPES.dataro_predict:
                loadDataroPredictSteps();
                try {
                    addUserActivity({
                        event_name: 'onboarding_tour',
                        event_payload: {
                            modal_name: 'dataro_predict',
                            modal_component: 'JoyrideComponent'
                        }
                    });
                } catch (ex) {
                    console.log(ex);
                }
                break;
            case TOUR_TYPES.fundraising_intelligence:
                loadFundraisingIntelligenceSteps();
                try {
                    addUserActivity({
                        event_name: 'onboarding_tour',
                        event_payload: {
                            modal_name: 'fundraising_intelligence',
                            modal_component: 'JoyrideComponent'
                        }
                    });
                } catch (ex) {
                    console.log(ex);
                }
                break;
            case TOUR_TYPES.ai_assist:
                loadAIAssistSteps();
                try {
                    addUserActivity({
                        event_name: 'onboarding_tour',
                        event_payload: {
                            modal_name: 'ai_assist',
                            modal_component: 'JoyrideComponent'
                        }
                    });
                } catch (ex) {
                    console.log(ex);
                }
                break;
            default:
                break;
        }
        setRun(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initial_product_preference, queryParsed]);

    useEffect(() => {
        const [onWelcomeStart, onWelcomeEnd] = onWelcomeStepEffect({
            steps,
            stepIndex,
            localState,
            theme
        });
        const [onCampaignSizeStart, onCampaignSizeEnd] = onCampaignSizeStepEffect({
            steps,
            stepIndex,
            localState,
            theme
        });
        const [onCampaignResultsStart, onCampaignResultsEnd] = onCampaignResultsStepEffect({
            steps,
            stepIndex,
            localState,
            theme,
            setSteps
        });
        const [onModelPerformanceStart, onModelPerformanceEnd] = onModelPerformanceStepEffect({
            steps,
            stepIndex,
            localState,
            theme
        });
        const [onSettingsStart, onSettingsEnd] = onSettingsStepEffect({
            steps,
            stepIndex,
            localState,
            theme
        });
        const [onFundraisingIntelligenceStart, onFundraisingIntelligenceEnd] =
            onFundraisingIntelligenceStepEffect({
                steps,
                stepIndex,
                localState,
                theme,
                setSteps
            });
        const [onAIAssistStart, onAIAssistEnd] = onAIAssistStepEffect();

        onWelcomeStart(steps);
        onCampaignSizeStart(steps);
        onCampaignResultsStart(steps);
        onModelPerformanceStart(steps);
        onSettingsStart(steps);
        onFundraisingIntelligenceStart(steps);
        onAIAssistStart(steps);

        return () => {
            onWelcomeEnd();
            onCampaignSizeEnd();
            onCampaignResultsEnd();
            onModelPerformanceEnd();
            onSettingsEnd();
            onFundraisingIntelligenceEnd();
            onAIAssistEnd();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepIndex, forceRender, localState]);

    function onCRMSubmit(values) {
        // TODO: update upgrade_requested to true
        const btnSelector = '#btn_onboarding_next';
        const nextBtnElement = document.querySelector(btnSelector);
        nextBtnElement && nextBtnElement.click();
        setTimeout(() => setSelectedCRM(values.crm_type), 1);
    }

    function onClickDataroPredict() {
        setTourType(TOUR_TYPES.dataro_predict);
        const btnSelector = '#btn_onboarding_next';
        const nextBtnElement = document.querySelector(btnSelector);
        nextBtnElement && nextBtnElement.click();
    }

    function onClickFI() {
        setTourType(TOUR_TYPES.fundraising_intelligence);
        const btnSelector = '#btn_onboarding_next';
        const nextBtnElement = document.querySelector(btnSelector);
        nextBtnElement && nextBtnElement.click();
    }

    function onClickSkip() {
        const skipButtonElement = document.querySelector('#tour_skip_button');
        skipButtonElement && skipButtonElement.click();
    }

    function handleJoyrideCallback(data) {
        const { action, index, status, type } = data;
        if (!steps[index]) {
            return;
        }

        refIsMounted.current = true;

        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const stepId = steps[index].id;
        if (data.lifecycle === 'ready') {
            setLocalState(() => ({
                [stepId]: true
            }));
        }
        if (finishedStatuses.includes(status)) {
            UpdateClientSettings({
                onboarding_demo_completed: true
            });
            return setTimeout(() => {
                window.location = tourType === 'ai_assist' ? SLUGS.aiAssistSettings : SLUGS.home;
            }, 1500);
        }

        if (action === 'next' && type === 'step:after') {
            if (stepId === 'campaign_size_1') {
                replace(convertSlugToUrl(SLUGS.campaignSize));
            }

            if (stepId === 'campaign_results_1') {
                replace(convertSlugToUrl(SLUGS.campaignResults));
            }
            if (stepId === 'campaign_results_3') {
                replace(
                    convertSlugToUrl(SLUGS.campaignResultDetails, {
                        parentId: 342
                    })
                );
            }
            if (stepId === 'model_performance_1') {
                replace(convertSlugToUrl(SLUGS.performance));
            }
            if (stepId === 'settings_1') {
                replace(
                    convertSlugToUrl(
                        convertSlugToUrl(SLUGS.settings, {
                            section: 'subscription_status'
                        })
                    )
                );
            }
            if (stepId === 'settings_2') {
                replace(
                    convertSlugToUrl(
                        convertSlugToUrl(SLUGS.settings, {
                            section: 'manage_team'
                        })
                    )
                );
            }
            if (stepId === 'fundraising_intelligence_9') {
                replace(
                    convertSlugToUrl(
                        convertSlugToUrl(SLUGS.settings, {
                            section: 'manage_team'
                        })
                    )
                );
            }

            if (index === steps.findIndex((s) => s.id === 'fundraising_intelligence_1')) {
                replace(convertSlugToUrl(convertSlugToUrl(SLUGS.fundraisingIntelligence)));
            }
            if (index === steps.findIndex((s) => s.id === 'fundraising_intelligence_2')) {
                setTimeout(() => {
                    const newSearchParams = window.location.href.split('?')[1];
                    replace(
                        `${convertSlugToUrl(SLUGS.fundraisingIntelligenceSingleGiverReport, {
                            reportId: 'sg_acqusition_analysis'
                        })}?${newSearchParams}`
                    );
                }, 500);
            }
            return setStepIndex((prev) => prev + 1);
        } else if (action === 'next' && type === 'error:target_not_found') {
            refIsMounted.current = false;
            setIsLoadingLocal(true);
            setRun(false);
            forceMount();
        }
    }

    async function forceMount(retries = 20) {
        setRun(true);
        setIsLoadingLocal(false);
        for (let index = 0; index < retries; index++) {
            await timeout(500);
            if (refIsMounted.current) {
                break;
            }
            setForceRender(new Date().getTime());
        }
    }

    const currentStep = steps[stepIndex] || {};

    return (
        <>
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                styles={{
                    options: {
                        overlayColor: '#C4C4C499',
                        primaryColor: '#FFB282',
                        zIndex: 1000,
                        arrowColor:
                            steps[stepIndex] && steps[stepIndex].placement.indexOf('right') >= 0
                                ? '#FFEBE8'
                                : 'white'
                    }
                }}
                run={run}
                disableOverlayClose
                showProgress={true}
                showSkipButton={true}
                steps={steps}
                stepIndex={stepIndex}
                spotlightClicks={currentStep.hasSpotlightClicks}
                disableScrolling={currentStep.disableScrolling}
                disableOverlay={currentStep.disableOverlay}
                tooltipComponent={(tooltipProps) => (
                    <Tooltip
                        setStepIndex={setStepIndex}
                        refNextPressed={refNextPressed}
                        steps={steps}
                        tourType={tourType}
                        {...tooltipProps}
                    />
                )}
            />
            <LoadingComponent loading={isLoading} />
        </>
    );
}

const useStylesTooltip = createUseStyles((theme) => ({
    leftBar: {
        width: 100,
        background: '#FFEBE8',
        borderRadius: '10px 0px 0px 10px'
    },
    container: {
        padding: ({ styles }) => styles.containerPadding,
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: ({ styles }) => styles.containerWidth,
        maxWidth: ({ styles }) => styles.containerMaxWidth,
        fontSize: 18
    },
    buttonContainer: {
        position: 'absolute',
        top: ({ styles }) => styles.buttonContainerTop,
        width: '100%'
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center'
    },
    skipButton: {
        cursor: 'pointer',
        position: 'absolute',
        right: ({ styles }) => styles.skipButtonRight,
        height: 25,
        padding: 0,
        margin: '11px 19px'
    },
    stepCounter: {
        fontSize: 14
    }
}));

function Tooltip({
    continuous,
    index,
    isLastStep,
    steps,
    step,
    tourType,
    primaryProps,
    skipProps,
    tooltipProps,
    setStepIndex,
    refNextPressed
}) {
    const { nextText, skipText, hideSkip, stepStyles = {}, containerType } = step;

    const showSkipCross = !skipText && !hideSkip;
    const stepIndex = steps.findIndex((s) => s.id === step.id);
    const welcomeStepsCount = steps.filter((s) => s.id.indexOf('welcome_') >= 0).length;

    const predictStepsCount = steps.filter((s) => s.id.indexOf('predict_') >= 0).length;

    const fundrasingIntelligenceStepsCount = steps.filter(
        (s) => s.id.indexOf('fundraising_intelligence_') >= 0
    ).length;
    const dataroPredictStepsCount =
        steps.length - welcomeStepsCount - predictStepsCount - fundrasingIntelligenceStepsCount;
    let currentStepNumber;
    let totalStepsCount;

    if (tourType === TOUR_TYPES.dataro_predict) {
        currentStepNumber = stepIndex + 1 - welcomeStepsCount - predictStepsCount;
        totalStepsCount = dataroPredictStepsCount;
        if (currentStepNumber > totalStepsCount) {
            totalStepsCount = fundrasingIntelligenceStepsCount;
            currentStepNumber =
                stepIndex + 1 - welcomeStepsCount - predictStepsCount - dataroPredictStepsCount;
        }
    } else if (tourType === TOUR_TYPES.fundraising_intelligence) {
        currentStepNumber = stepIndex + 1 - welcomeStepsCount;
        totalStepsCount = fundrasingIntelligenceStepsCount;
        if (currentStepNumber > totalStepsCount) {
            totalStepsCount = dataroPredictStepsCount;
            currentStepNumber =
                stepIndex +
                1 -
                welcomeStepsCount -
                predictStepsCount -
                fundrasingIntelligenceStepsCount;
        }
    }

    const showStepCounter = 0 < currentStepNumber;

    const defaultStyles = {
        buttonContainerHorizontal: 'space-between',
        buttonWidth: 100,
        leftBarWidth: 15,
        containerPadding: '50px 30px 30px 30px',
        containerWidth: 400,
        buttonContainerTop: 'calc(100%)',
        skipButtonRight: 0,
        buttonNextStyles: {
            height: 32,
            padding: '3px 3px'
        },
        buttonBackStyles: {
            height: 32,
            padding: '3px 3px'
        }
    };

    const smallContainerStyles = {
        containerPadding: '35px 20px 50px 20px',
        containerWidth: null,
        containerMaxWidth: 400,
        buttonContainerTop: 'calc(100% + 8px)'
    };

    const styles = {
        ...defaultStyles,
        ...(containerType !== 'LARGE' ? smallContainerStyles : {}),
        ...stepStyles
    };

    const theme = useTheme();
    const classes = useStylesTooltip({ theme, styles });

    return (
        <div {...tooltipProps}>
            <Row>
                <div
                    id='tour_skip_button'
                    className={classes.skipButton}
                    style={{ display: showSkipCross ? '' : 'none' }}
                    {...skipProps}
                >
                    <IconCross width={32} height={32} color={theme.color.charcoal} />
                </div>
                <div className={classes.leftBar}></div>
                <Column className={classes.container}>
                    {step.content}
                    <div style={{ position: 'relative', width: '100%' }}>
                        <Row
                            className={classes.buttonContainer}
                            horizontal={styles.buttonContainerHorizontal}
                            style={{ flexDirection: step.buttonsReverse ? 'row-reverse' : 'row' }}
                        >
                            {skipText ? (
                                <ButtonComponent width={styles.buttonWidth} {...skipProps}>
                                    {skipText}
                                </ButtonComponent>
                            ) : (
                                showStepCounter && (
                                    <div className={classes.stepCounter}>
                                        <i>
                                            Step {currentStepNumber}/{totalStepsCount}
                                        </i>
                                    </div>
                                )
                            )}

                            <ButtonComponent
                                width={styles.buttonWidth}
                                {...primaryProps}
                                style={
                                    step.hideNext ? { display: 'none' } : styles.buttonNextStyles
                                }
                                id='btn_onboarding_next'
                                onClick={(...e) => {
                                    if (index === 0 && refNextPressed.current) {
                                        // necessary because returning to the first step does not allow to continue.
                                        setStepIndex(1);
                                    } else {
                                        primaryProps.onClick(...e);
                                    }
                                    refNextPressed.current = true;
                                }}
                            >
                                {nextText}
                            </ButtonComponent>
                        </Row>
                    </div>
                </Column>
            </Row>
        </div>
    );
}

export default JoyrideComponent;
