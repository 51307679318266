import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 63.5}
        height={props.height || 75.673}
        viewBox='0.5 0.242 63.5 75.673'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 21 19.83 C 25.344 19.83 29 16.191 29 11.536 C 29 10.424 28.791 9.369 28.414 8.409 C 27.738 6.687 26.522 5.266 24.997 4.353 C 23.814 3.645 22.445 3.242 21 3.242 C 16.656 3.242 13 6.88 13 11.536 C 13 16.191 16.656 19.83 21 19.83 Z M 21 22.83 C 27.075 22.83 32 17.773 32 11.536 C 32 5.299 27.075 0.242 21 0.242 C 14.925 0.242 10 5.299 10 11.536 C 10 17.773 14.925 22.83 21 22.83 Z'
            style={{ paintOrder: 'fill', fillRule: 'evenodd' }}
            fill='#908D9E'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.70502 28.8974C11.0095 24.3817 15.5881 20.3477 20.828 20.3477C25.1733 20.3477 29.1226 22.8766 32.2563 26.2344C35.413 29.6168 37.93 34.0311 39.4157 38.2642L36.585 39.2577C35.2195 35.367 32.9033 31.3246 30.0631 28.2813C27.1999 25.2134 23.9887 23.3477 20.828 23.3477C17.1097 23.3477 13.3134 26.3134 10.126 30.669C6.99257 34.9509 4.79142 40.1191 4.12576 43.6136C3.39723 47.4381 2.98177 51.031 4.75611 53.7219C6.47179 56.3237 10.6736 58.6481 20.828 58.6481C23.4903 58.6481 25.7648 58.5137 27.7045 58.2655L28.0853 61.2412C25.9923 61.509 23.5872 61.6481 20.828 61.6481C10.445 61.6481 4.84589 59.3077 2.25159 55.3733C-0.284043 51.528 0.481363 46.7133 1.17875 43.0522C1.93901 39.0611 4.34663 33.4867 7.70502 28.8974Z'
            fill='#908D9E'
        ></path>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.5 72.9148C53.5923 72.9148 61 65.4847 61 56.2736C61 47.0624 53.5923 39.6323 44.5 39.6323C35.4077 39.6323 28 47.0624 28 56.2736C28 65.4847 35.4077 72.9148 44.5 72.9148ZM44.5 75.9148C55.2696 75.9148 64 67.1211 64 56.2736C64 45.426 55.2696 36.6323 44.5 36.6323C33.7304 36.6323 25 45.426 25 56.2736C25 67.1211 33.7304 75.9148 44.5 75.9148Z'
            fill='#8564E7'
            style={{ fillRule: 'evenodd' }}
        ></path>
        <g
            id='icon'
            style={{
                stroke: 'none',
                strokeWidth: '1',
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: '10',
                fill: 'none',
                fillRule: 'nonzero',
                opacity: '1'
            }}
            transform='matrix(0.223293, 0, 0, 0.204168, 34.491863, 46.735577)'
        >
            <path
                d='M 80.89 78.772 H 9.11 c -5.023 0 -9.11 -4.087 -9.11 -9.11 V 20.338 c 0 -5.023 4.087 -9.11 9.11 -9.11 h 71.78 c 5.023 0 9.11 4.087 9.11 9.11 v 49.324 C 90 74.686 85.913 78.772 80.89 78.772 z M 9.11 17.228 c -1.715 0 -3.11 1.396 -3.11 3.11 v 49.324 c 0 1.715 1.395 3.11 3.11 3.11 h 71.78 c 1.715 0 3.11 -1.396 3.11 -3.11 V 20.338 c 0 -1.715 -1.396 -3.11 -3.11 -3.11 H 9.11 z'
                style={{
                    stroke: 'none',
                    strokeWidth: '1',
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    fillRule: 'nonzero',
                    opacity: '1',
                    fill: 'rgb(133, 100, 231)',
                    paintOrder: 'fill'
                }}
                transform=' matrix(1 0 0 1 0 0) '
                strokeLinecap='round'
            ></path>
            <path
                d='M 45 55.427 c -5.408 0 -10.599 -2.292 -14.242 -6.288 L 2.493 18.125 l 4.435 -4.042 l 28.265 31.013 c 2.545 2.792 6.028 4.331 9.807 4.331 c 3.779 0 7.262 -1.538 9.808 -4.331 l 28.266 -31.013 l 4.434 4.042 L 59.241 49.138 C 55.599 53.135 50.408 55.427 45 55.427 z'
                style={{
                    stroke: 'none',
                    strokeWidth: '1',
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    fillRule: 'nonzero',
                    opacity: '1',
                    fill: 'rgb(133, 100, 231)',
                    paintOrder: 'fill'
                }}
                transform=' matrix(1 0 0 1 0 0) '
                strokeLinecap='round'
            ></path>
            <rect
                x='-0.96'
                y='57.16'
                rx='0'
                ry='0'
                width='38.98'
                height='6'
                style={{
                    stroke: 'none',
                    strokeWidth: '1',
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    fillRule: 'nonzero',
                    opacity: '1',
                    fill: 'rgb(133, 100, 231)',
                    paintOrder: 'fill'
                }}
                transform=' matrix(0.7053 -0.7089 0.7089 0.7053 -37.1881 30.8639) '
            ></rect>
            <rect
                x='68.47'
                y='40.67'
                rx='0'
                ry='0'
                width='6'
                height='38.98'
                style={{
                    stroke: 'none',
                    strokeWidth: '1',
                    strokeDasharray: 'none',
                    strokeLinecap: 'butt',
                    strokeLinejoin: 'miter',
                    strokeMiterlimit: '10',
                    fillRule: 'nonzero',
                    opacity: '1',
                    fill: 'rgb(133, 100, 231)',
                    paintOrder: 'fill'
                }}
                transform=' matrix(0.709 -0.7053 0.7053 0.709 -21.628 67.9146) '
            ></rect>
        </g>
    </svg>
);

export default forwardRef(Icon);
