import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const LoginComponent = lazy(() => import('./auth/LoginComponent'));
const SignUpComponentV2 = lazy(() => import('./auth/signup/SignUpComponentV2'));
const SignUpComponent = lazy(() => import('./auth/signup/SignUpComponent'));
const ForgotPasswordComponent = lazy(() => import('./auth/forgot/ForgotPasswordComponent'));
const NewPasswordComponent = lazy(() => import('./auth/forgot/NewPasswordComponent'));
const SetPasswordComponent = lazy(() => import('./auth/forgot/SetPasswordComponent'));

function PublicRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route path={SLUGS.login} component={LoginComponent} />
                <Route path={SLUGS.signup} component={SignUpComponentV2} />
                <Route path={SLUGS.signupFree} component={SignUpComponent} />
                <Route path={SLUGS.forgotPassword} component={ForgotPasswordComponent} />
                <Route path={SLUGS.newPassword} component={NewPasswordComponent} />
                <Route path={SLUGS.setPassword} component={SetPasswordComponent} />
                <Redirect to={SLUGS.login} />
            </Switch>
        </Suspense>
    );
}

export default PublicRoutes;
