import React, { forwardRef, useRef, useEffect } from 'react';
import TooltipComponent from 'components/popover/TooltipComponent';
import './radio.css';

function RadioComponent(
    {
        className,
        currentValue,
        label,
        name,
        placeholder,
        errorMessage,
        showErrorMessageBelow,
        value,
        ...otherProps
    },
    ref
) {
    const inputRef = useRef();
    const containerClassName = [
        'input-radio-container',
        className,
        errorMessage ? 'input-text-container-error' : ''
    ].join(' ');

    useEffect(() => {
        if (currentValue === value) {
            inputRef.current.checked = true;
        }
    }, [currentValue, value]);

    return (
        <div className={containerClassName} onClick={() => (inputRef.current.checked = true)}>
            <input
                type='radio'
                placeholder={placeholder}
                autoComplete='off'
                name={name}
                value={value}
                {...otherProps}
                ref={(e) => {
                    if (ref) {
                        ref(e);
                    }
                    inputRef.current = e;
                }}
            />
            {label && <label htmlFor={name}>{label}</label>}
            {errorMessage && (
                <>
                    {!showErrorMessageBelow ? (
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 18
                                }}
                            >
                                <TooltipComponent type='error' message={errorMessage} />
                            </div>
                        </div>
                    ) : (
                        <span className='input-text-container-error-message'>{errorMessage}</span>
                    )}
                </>
            )}
        </div>
    );
}

export default forwardRef(RadioComponent);
