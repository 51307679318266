import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || props.size || '24'}
        height={props.height || props.size || '24'}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M6 18V14M6 14H8L13 17V7L8 10H5C3.89543 10 3 10.8954 3 12V12C3 13.1046 3.89543 14 5 14H6ZM17 7L19 5M17 17L19 19M19 12H21'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            stroke={props.color || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
