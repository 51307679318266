import React, { useState, createContext } from 'react';

export const GlobalContext = createContext();

const defaultValue = {
    reports: {
        search: '',
        sortBy: true, // true = asc, false: desc
        type: '',
        showValid: false
    }
};
export function GlobalContextProvider({ children }) {
    const [globalValue, setGlobalValue] = useState(defaultValue);

    return (
        <GlobalContext.Provider
            value={{
                globalValue,
                setGlobalValue
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
