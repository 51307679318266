import React, { forwardRef } from 'react';

const Icon = (props = {}, ref) => (
    <svg
        width={props.width || 22}
        height={props.height || 22}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(4,15.8) scale(0.048,-0.05)'
            fill='#000000'
            stroke='none'
        >
            <path d='M36 202 c-3 -6 -2 -13 4 -17 7 -4 3 -17 -10 -36 l-21 -29 20 -29 c26 -36 72 -55 112 -47 18 3 38 1 45 -4 7 -6 15 -7 18 -2 3 5 -32 46 -78 91 -47 46 -86 79 -90 73z m57 -83 c3 -11 15 -23 26 -26 26 -7 27 -23 2 -23 -25 0 -51 26 -51 51 0 25 16 24 23 -2z' />
            <path d='M100 189 c0 -5 13 -15 30 -20 16 -6 32 -20 36 -32 3 -12 13 -30 22 -40 14 -16 16 -16 29 3 12 17 11 23 -3 45 -27 42 -114 75 -114 44z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
