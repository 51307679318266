import { useMutation } from 'react-query';
import useFetchAuth from 'hooks/useFetchAuth';

function useUserActivityPost() {
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/user_activity', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post);
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

export default function useUserActivity() {
    const { data, error, isLoading, mutate: addUserActivity } = useUserActivityPost();

    return {
        isLoading,
        error,
        data,
        addUserActivity
    };
}
