import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        fill={props.fill || '#424147'}
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 30 30'
        id='icon'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <defs></defs>
        <title>report--alt</title>
        <rect x='10' y='18' width='8' height='2' />
        <rect x='10' y='13' width='12' height='2' />
        <rect x='10' y='23' width='5' height='2' />
        <path d='M25,5H22V4a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2V5H7A2,2,0,0,0,5,7V28a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V7A2,2,0,0,0,25,5ZM12,4h8V8H12ZM25,28H7V7h3v3H22V7h3Z' />
        <rect
            id='_Transparent_Rectangle_'
            data-name='&lt;Transparent Rectangle&gt;'
            width={props.width || 30}
            height={props.height || 30}
            fill='none'
        />
    </svg>
);

export default forwardRef(Icon);
