import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox='0 0 21.5 21.5'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.9771 0.223877C11.8055 0.223877 12.4771 0.89545 12.4771 1.72388V19.6702C12.4771 20.4986 11.8055 21.1702 10.9771 21.1702C10.1486 21.1702 9.47705 20.4986 9.47705 19.6702V1.72388C9.47705 0.89545 10.1486 0.223877 10.9771 0.223877Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.503906 10.7434C0.503906 9.91498 1.17548 9.24341 2.00391 9.24341H19.9502C20.7787 9.24341 21.4502 9.91498 21.4502 10.7434C21.4502 11.5718 20.7787 12.2434 19.9502 12.2434H2.00391C1.17548 12.2434 0.503906 11.5718 0.503906 10.7434Z'
            fill={props.fill || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
