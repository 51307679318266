import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 29}
        viewBox='0 0 101 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fill={props.fill || '#BFBFBF'}
            d='M85.94,67.9l0-.06a1.49,1.49,0,0,0,0-.33v-50A1.5,1.5,0,0,0,84.5,16h-10a1.49,1.49,0,0,0-.5.09,1.49,1.49,0,0,0-.5-.09H73V9.5a1.5,1.5,0,0,0-3,0V16H52V9.5a1.5,1.5,0,0,0-3,0V16H31V9.5a1.5,1.5,0,0,0-3,0V16H16.5A1.5,1.5,0,0,0,15,17.5v67A1.5,1.5,0,0,0,16.5,86h49a1.49,1.49,0,0,0,.5-.09l.05,0a1.49,1.49,0,0,0,.42-.25l0,0,19-17,0,0a1.5,1.5,0,0,0,.25-.3l0-.07A1.48,1.48,0,0,0,85.94,67.9ZM28,19v3.5a1.5,1.5,0,0,0,3,0V19H49v3.5a1.5,1.5,0,0,0,3,0V19H70v3.5a1.5,1.5,0,0,0,3,0V19h.5a1.49,1.49,0,0,0,.5-.09,1.49,1.49,0,0,0,.5.09H83V34H18V19ZM18,37H83V66H65.5A1.5,1.5,0,0,0,64,67.5V83H18ZM67,81.15V69H80.57Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M33.5,46h-10a1.5,1.5,0,0,0,0,3h10a1.5,1.5,0,0,0,0-3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M44.5,49h11a1.5,1.5,0,0,0,0-3h-11a1.5,1.5,0,0,0,0,3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M66.5,49h11a1.5,1.5,0,0,0,0-3h-11a1.5,1.5,0,0,0,0,3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M33.5,57h-10a1.5,1.5,0,0,0,0,3h10a1.5,1.5,0,0,0,0-3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M55.5,57h-11a1.5,1.5,0,0,0,0,3h11a1.5,1.5,0,0,0,0-3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M66.5,60h11a1.5,1.5,0,0,0,0-3h-11a1.5,1.5,0,0,0,0,3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M33.5,68h-10a1.5,1.5,0,0,0,0,3h10a1.5,1.5,0,0,0,0-3Z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='M54.5,68h-10a1.5,1.5,0,0,0,0,3h10a1.5,1.5,0,0,0,0-3Z'
        />
    </svg>
);

export default forwardRef(Icon);
