import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 21 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M20.341 3.09098L17.909 0.659016C17.4871 0.237058 16.9148 3.12036e-06 16.318 0L8.25 0C7.00734 0 6 1.00734 6 2.25V4.5H2.25C1.00734 4.5 0 5.50734 0 6.75V21.75C0 22.9927 1.00734 24 2.25 24H12.75C13.9927 24 15 22.9927 15 21.75V19.5H18.75C19.9927 19.5 21 18.4927 21 17.25V4.68197C21 4.08523 20.7629 3.51294 20.341 3.09098ZM12.4688 21.75H2.53125C2.45666 21.75 2.38512 21.7204 2.33238 21.6676C2.27963 21.6149 2.25 21.5433 2.25 21.4688V7.03125C2.25 6.95666 2.27963 6.88512 2.33238 6.83238C2.38512 6.77963 2.45666 6.75 2.53125 6.75H6V17.25C6 18.4927 7.00734 19.5 8.25 19.5H12.75V21.4688C12.75 21.5433 12.7204 21.6149 12.6676 21.6676C12.6149 21.7204 12.5433 21.75 12.4688 21.75ZM18.4688 17.25H8.53125C8.45666 17.25 8.38512 17.2204 8.33238 17.1676C8.27963 17.1149 8.25 17.0433 8.25 16.9688V2.53125C8.25 2.45666 8.27963 2.38512 8.33238 2.33238C8.38512 2.27963 8.45666 2.25 8.53125 2.25H13.5V6.375C13.5 6.99633 14.0037 7.5 14.625 7.5H18.75V16.9688C18.75 17.0433 18.7204 17.1149 18.6676 17.1676C18.6149 17.2204 18.5433 17.25 18.4688 17.25ZM18.75 5.25H15.75V2.25H16.2015C16.2761 2.25 16.3476 2.27962 16.4004 2.33236L18.6676 4.59961C18.6938 4.62573 18.7145 4.65674 18.7286 4.69086C18.7427 4.72499 18.75 4.76156 18.75 4.7985V5.25Z'
            fill='black'
        />
    </svg>
);

export default forwardRef(Icon);
