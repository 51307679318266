import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectComponent from './SelectComponent';

function FormSelectComponent({
    label,
    name,
    required,
    min,
    max,
    minLength,
    maxLength,
    pattern,
    validate,
    defaultValue,
    compareWithOther,
    showErrorMessageBelow,
    ...otherProps
}) {
    const { errors, formState, register, setValue, getValues } = useFormContext();
    const isTouched = formState.touched[name] || formState.isSubmitted;
    const fieldError = errors[name];
    const errorMessage = isTouched && fieldError && fieldError.message;
    const props = otherProps;
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    if (props.type === 'number') {
        props.step = 'any';
    }
    useEffect(() => {
        if (defaultValue && !isTouched) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, isTouched, name, setValue]);

    const validateProp = {
        ...validate
    };
    if (compareWithOther) {
        validateProp.compareWithOther = (value) => {
            const values = getValues();
            return compareWithOther(value, values);
        };
    }

    return (
        <SelectComponent
            type='text'
            onChange={(e) => setValue && setValue(name, e.target.value)}
            name={name}
            id={name}
            defaultValue={defaultValue}
            label={label}
            ref={register({
                required,
                min,
                max,
                minLength,
                maxLength,
                pattern,
                validate: validateProp
            })}
            errorMessage={errorMessage}
            showErrorMessageBelow={showErrorMessageBelow}
            {...props}
        />
    );
}

export default FormSelectComponent;
