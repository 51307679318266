import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox='0 0 24 24'
        fill={props.fill || '#424147'}
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path d='M5 12H20' stroke={props.fill || '#424147'} strokeWidth='2' strokeLinecap='round' />
        <path d='M5 17H20' stroke={props.fill || '#424147'} strokeWidth='2' strokeLinecap='round' />
        <path d='M5 7H20' stroke={props.fill || '#424147'} strokeWidth='2' strokeLinecap='round' />
    </svg>
);

export default forwardRef(Icon);
