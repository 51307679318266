import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g transform='translate(5, 5)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.5001 0C12.2681 0 12.8908 0.622652 12.8908 1.39073V2.45229C14.3639 2.7055 15.7182 3.30628 16.8662 4.16718L17.665 3.36836C18.2081 2.82524 19.0887 2.82524 19.6318 3.36836C20.1749 3.91147 20.1749 4.79203 19.6318 5.33515L18.8176 6.14932C19.6517 7.29858 20.2284 8.64681 20.4631 10.1093H21.6094C22.3774 10.1093 23.0001 10.732 23.0001 11.5001C23.0001 12.2681 22.3774 12.8908 21.6094 12.8908H20.49C20.2811 14.378 19.7194 15.7519 18.8922 16.9253L19.6318 17.6649C20.1749 18.208 20.1749 19.0886 19.6318 19.6317C19.0887 20.1748 18.2081 20.1748 17.665 19.6317L16.9678 18.9344C15.7984 19.8367 14.4076 20.4659 12.8908 20.7266V21.6092C12.8908 22.3773 12.2681 23 11.5001 23C10.732 23 10.1093 22.3773 10.1093 21.6092V20.7841C8.53866 20.5812 7.09122 19.9852 5.86884 19.098L5.33515 19.6317C4.79203 20.1748 3.91147 20.1748 3.36836 19.6317C2.82524 19.0886 2.82524 18.208 3.36836 17.6649L3.88673 17.1465C2.9715 15.9261 2.35027 14.4725 2.1281 12.8908H1.39073C0.622652 12.8908 0 12.2681 0 11.5001C0 10.732 0.622652 10.1093 1.39073 10.1093H2.155C2.40468 8.55306 3.04168 7.12622 3.964 5.93079L3.36836 5.33515C2.82524 4.79203 2.82524 3.91147 3.36836 3.36836C3.91147 2.82524 4.79203 2.82524 5.33515 3.36836L5.97311 4.00632C7.1732 3.16031 8.58309 2.59199 10.1093 2.3948V1.39073C10.1093 0.622652 10.732 0 11.5001 0ZM11.309 18.0795C14.8934 18.0795 17.7991 15.1738 17.7991 11.5894C17.7991 8.00507 14.8934 5.09936 11.309 5.09936C7.72467 5.09936 4.81896 8.00507 4.81896 11.5894C4.81896 15.1738 7.72467 18.0795 11.309 18.0795Z'
                fill='currentColor'
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
