import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import useUpgradeAccount from 'hooks/useUpgradeAccount';
import { IconError } from 'assets/icons';
import COUNTRIES from 'resources/countries';
import SLUGS from 'resources/slugs';
import FormComponent from 'components/form/FormComponent';
import FormInputComponent from 'components/form/input/FormInputComponent';
import FormSelectComponent from 'components/form/select/FormSelectComponent';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';

const useStyles = createUseStyles((theme) => ({
    leftBar: {
        width: 100,
        background: '#FFEBE8',
        borderRadius: '10px 0px 0px 10px'
    },
    container: {
        padding: '90px 70px 35px 70px',
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: null
    },
    formSection: {
        marginBottom: 16,
        width: '100%',
        maxWidth: 512,
        alignSelf: 'center',
        '& > div': {
            marginTop: 20
        }
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center'
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        container: {
            padding: '40px 30px 30px 40px'
        },
        formSection: {
            '&& input#client_name_external': {
                height: '40px'
            },
            '&& .select-container > select': {
                height: '40px'
            },
            '&& > .input-text-container > label': {
                marginBottom: 10
            },
            '&& > .select-container > label': {
                marginBottom: 10
            },
            '& > div': {
                marginTop: '10px !important'
            }
        },
        '#fi_free_modal_main_window': {
            width: '80%'
        },
        leftBar: {
            width: 75
        }
    }
}));

function WelcomeModal() {
    const { push } = useHistory();
    const { search } = useLocation();
    const queryParsed = queryString.parse(search);
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const { data: { client_name, first_name } = {}, isLoading: isLoadingClientSettings } =
        useClientSettings({
            params: 'client_name,first_name'
        });
    const { isLoading: isLoadingCRMTypes, data: crm_types_prop = [] } = useCRMTypes();
    const {
        isLoading: isLoadingUpgradeAccount,
        errorUpgradeAccount,
        upgradeAccount
    } = useUpgradeAccount();
    const [loadingLocal, setLoadingLocal] = useState(false);
    const [formPayload, setFormPayload] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const crm_types = crm_types_prop.filter((t) => !!t.onboarding_order);
    const isLoading =
        isLoadingClientSettings || isLoadingUpgradeAccount || isLoadingCRMTypes || loadingLocal;
    const showError = errorUpgradeAccount;
    const crms_with_fi_free = crm_types
        .filter((t) => t.self_onboarding_fi_free === true)
        .map((t) => t.crm_type);

    async function onSubmit({ client_name_external, crm_type, country: countryCode, details }) {
        const {
            name: country,
            default_currency,
            market,
            tz: timezone
        } = COUNTRIES.find((c) => c.code === countryCode);
        const payload = {
            crm_type,
            client_name_external,
            client_name: client_name_external
                .toLowerCase()
                .replace(/'/g, '')
                .replace(/[^A-Z0-9]+/gi, '_'),
            details: `fi_free ${details}`,
            email: false,
            phone: false,
            countryCode: country,
            country,
            timezone,
            default_currency,
            market,
            preventRedirectToSetup: true
        };
        setFormPayload(payload);

        const selectedCRMIsIncluded = crms_with_fi_free.includes(crm_type);
        if (selectedCRMIsIncluded) {
            if (client_name === 'DEMO') {
                setLoadingLocal(true);
                payload.preventRedirectToSetup = false;
                setFormPayload(payload);
                await upgradeAccount(payload);
            }
        } else {
            setFormSubmitted(true);
        }
    }

    async function onSubmitNotIncluded() {
        if (client_name === 'DEMO') {
            setLoadingLocal(true);
            await upgradeAccount(formPayload);
            setTimeout(() => setLoadingLocal(false), 2000);
        }
    }

    function onRequestClose() {
        delete queryParsed.showFiFreeWelcomeModal;
        const queryStringified = queryString.stringify(queryParsed);
        push(`${SLUGS.home}?${queryStringified}`);
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 968,
                    maxHeight: 880,
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            id='fi_free_modal_main_window'
            ariaHideApp={false}
        >
            <Row>
                <div className={classes.leftBar}></div>
                <Column className={classes.container}>
                    {!formSubmitted ? (
                        <FormSection
                            crm_types={crm_types}
                            errorUpgradeAccount={errorUpgradeAccount}
                            first_name={first_name}
                            onSubmit={onSubmit}
                            showError={showError}
                        />
                    ) : (
                        <CrmNotIncludedSection onSubmit={onSubmitNotIncluded} />
                    )}
                </Column>
            </Row>
            <LoadingComponent loading={isLoading} />
        </Modal>
    );
}

function CrmNotIncludedSection({ onSubmit }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });

    return (
        <Column
            id='you_are_too_quick_modal'
            horizontal='center'
            vertical='center'
            style={{ width: '100%' }}
        >
            <h2 className={classes.title}>Sorry! You’re too quick for us</h2>
            <p style={{ marginTop: 98, marginBottom: 8 }}>
                Currently the free tier of Fundraising Intelligence is not supported for your CRM.
                We plan to add new CRM’s soon.
            </p>
            <p style={{ marginBottom: 100 }}>
                Not to worry - you can still see all of Dataro’s features with our DEMO account.
                Click Next to start exploring.
            </p>
            <ButtonComponent id='you_are_too_quick_modal_next' width={260} onClick={onSubmit}>
                Next
            </ButtonComponent>
        </Column>
    );
}

function FormSection({ crm_types, errorUpgradeAccount, first_name, onSubmit, showError }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const [selectedCRM, setSelectedCRM] = useState('');
    const showAddDetailsSection = selectedCRM === 'other';

    function onChangeCRM(e) {
        setSelectedCRM(e.target.value);
    }

    return (
        <FormComponent onSubmit={onSubmit}>
            <Column vertical='center' style={{ width: '100%' }}>
                <h2 className={classes.title} id='fi_modal_tour'>
                    You’re almost there
                </h2>
                {!errorUpgradeAccount ? (
                    <>
                        <p className='fi_modal_p'>Hi {first_name},</p>
                        <p>
                            We can’t wait to show you Fundraising Intelligence. Before diving in,
                            can you tell us a few things:
                        </p>
                        <Column className={classes.formSection}>
                            <FormInputComponent
                                label='Organization name'
                                required='Please provide your organization name'
                                name='client_name_external'
                                type='text'
                            />
                            <FormSelectComponent
                                label='Select your CRM'
                                name='crm_type'
                                required='Please select a CRM'
                                autoComplete='off'
                                onChange={onChangeCRM}
                            >
                                {[
                                    { label: '', value: '' },
                                    ...crm_types.map(({ crm_type, crm_name }) => ({
                                        label: crm_name,
                                        value: crm_type
                                    })),
                                    { label: 'Other', value: 'other' }
                                ].map((p) => (
                                    <option key={`status-${p.value}`} value={p.value}>
                                        {p.label}
                                    </option>
                                ))}
                            </FormSelectComponent>
                            {showAddDetailsSection && (
                                <>
                                    <FormInputComponent
                                        required='Please provide details'
                                        name='details'
                                        type='text'
                                        label={'CRM Details'}
                                    />
                                </>
                            )}
                            <FormSelectComponent
                                label='Select your Country'
                                name='country'
                                required='Please select a Country'
                                autoComplete='off'
                            >
                                {[
                                    { label: '', value: '' },
                                    ...COUNTRIES.map(({ name: label, code: value }) => ({
                                        label,
                                        value
                                    }))
                                ].map((p) => (
                                    <option key={`country-${p.value}`} value={p.value}>
                                        {p.label}
                                    </option>
                                ))}
                            </FormSelectComponent>
                        </Column>
                        <Row
                            horizontal={showError ? 'space-between' : 'center'}
                            vertical='center'
                            style={{ marginTop: 12 }}
                            id='fi-modal-button-div'
                        >
                            {showError && (
                                <div className='upgrade-error-message'>
                                    <span>
                                        {showError} <IconError />
                                    </span>
                                </div>
                            )}
                            <ButtonComponent id='btn_next' width={260} type='submit'>
                                Next
                            </ButtonComponent>
                        </Row>
                    </>
                ) : (
                    <>
                        <div className='upgrade-error-message'>
                            We are not able to create an account for this organisation at this time.{' '}
                            <br /> Please contact integrations@dataro.io
                            <span style={{ marginLeft: 8 }}>
                                <IconError />
                            </span>
                        </div>
                    </>
                )}
            </Column>
        </FormComponent>
    );
}

export default WelcomeModal;
