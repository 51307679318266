import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 11}
        viewBox='0 0 25 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M1 1L12.5 10L24 1'
            stroke={props.fill || '#C0C0C0'}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default forwardRef(Icon);
