import { useState } from 'react';
import useUserPool from './useUserPool';

const useLogout = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { userPool } = useUserPool();

    function logout() {
        setLoading(true);
        setError('');
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser !== null) {
            cognitoUser.getSession(async (err) => {
                setLoading(false);
                if (err) {
                    return setError(err.message);
                }
                setError('');
                await cognitoUser.signOut();
            });
        } else {
            setLoading(false);
            setError('');
        }
    }

    return { error, loading, logout };
};

export default useLogout;
