import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width='27'
        height='25'
        viewBox='0 0 27 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M6.46391 17.1261L14.302 9.18128C14.302 9.18128 16.5756 7.36923 18.3794 9.18128C20.1831 10.9933 18.9479 12.7007 18.9479 12.7007L9.98517 21.7854C9.98517 21.7854 5.72206 26.048 2.16815 21.7854C-1.38575 17.5228 5.87249 11.8835 5.87249 11.8835L15.5542 2.80394C15.5542 2.80394 20.4442 -0.961505 24.1481 3.13284C27.8519 7.22718 24.1481 11.8835 24.1481 11.8835L16.4371 19.9572'
            stroke='#FFB282'
            strokeWidth='2'
        />
    </svg>
);

export default forwardRef(Icon);
