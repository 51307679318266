import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 64}
        height={props.height || 76}
        viewBox='0 0 64 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M21 19.8296C25.3442 19.8296 29 16.1914 29 11.5359C29 6.8804 25.3442 3.24219 21 3.24219C16.6558 3.24219 13 6.8804 13 11.5359C13 16.1914 16.6558 19.8296 21 19.8296ZM21 22.8296C27.0751 22.8296 32 17.7733 32 11.5359C32 5.29856 27.0751 0.242188 21 0.242188C14.9249 0.242188 10 5.29856 10 11.5359C10 17.7733 14.9249 22.8296 21 22.8296Z'
            fill='#908D9E'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.70502 28.8974C11.0095 24.3817 15.5881 20.3477 20.828 20.3477C25.1733 20.3477 29.1226 22.8766 32.2563 26.2344C35.413 29.6168 37.93 34.0311 39.4157 38.2642L36.585 39.2577C35.2195 35.367 32.9033 31.3246 30.0631 28.2813C27.1999 25.2134 23.9887 23.3477 20.828 23.3477C17.1097 23.3477 13.3134 26.3134 10.126 30.669C6.99257 34.9509 4.79142 40.1191 4.12576 43.6136C3.39723 47.4381 2.98177 51.031 4.75611 53.7219C6.47179 56.3237 10.6736 58.6481 20.828 58.6481C23.4903 58.6481 25.7648 58.5137 27.7045 58.2655L28.0853 61.2412C25.9923 61.509 23.5872 61.6481 20.828 61.6481C10.445 61.6481 4.84589 59.3077 2.25159 55.3733C-0.284043 51.528 0.481363 46.7133 1.17875 43.0522C1.93901 39.0611 4.34663 33.4867 7.70502 28.8974Z'
            fill='#908D9E'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44.5 72.9148C53.5923 72.9148 61 65.4847 61 56.2736C61 47.0624 53.5923 39.6323 44.5 39.6323C35.4077 39.6323 28 47.0624 28 56.2736C28 65.4847 35.4077 72.9148 44.5 72.9148ZM44.5 75.9148C55.2696 75.9148 64 67.1211 64 56.2736C64 45.426 55.2696 36.6323 44.5 36.6323C33.7304 36.6323 25 45.426 25 56.2736C25 67.1211 33.7304 75.9148 44.5 75.9148Z'
            fill='#8564E7'
        />
        <path
            d='M45.1982 48.7585C46.9682 49.0585 47.8982 49.9885 48.2882 50.4085C48.7682 50.5285 49.4882 50.2585 49.8782 49.8685C50.3882 49.3285 50.4782 48.5785 50.3282 48.1885C49.9082 47.7685 48.5882 46.2685 45.1982 46.0585V43.2385C44.9882 42.9685 44.6282 42.7285 44.1182 42.7285C43.4882 42.7285 43.1882 42.9385 42.9782 43.2385V46.1185C39.9182 46.5085 37.2182 48.1885 37.2182 51.7585C37.2182 55.2685 40.0082 56.3785 42.9782 57.2185V63.8485C40.6982 63.5485 39.8282 62.1385 39.4682 61.5685C39.0782 61.4185 38.3882 61.4785 37.7882 61.8385C37.2182 62.2585 36.9482 62.7985 37.0082 63.3085C37.3682 63.9385 38.7782 66.3085 42.9782 66.5485V69.6985C43.1282 69.9685 43.5182 70.2085 44.0582 70.2085C44.7182 70.2085 44.9882 69.9685 45.1982 69.7285V66.4885C48.6782 66.0085 51.1982 63.8785 51.1982 60.4885C51.1982 56.7085 48.1982 55.7185 45.1982 54.8785V48.7585ZM45.1982 63.8185V57.8785C47.0582 58.4785 48.2582 59.1685 48.2582 60.7885C48.2582 62.4685 47.0582 63.5185 45.1982 63.8185ZM42.9782 48.7585V54.2185C41.1482 53.6185 40.1582 52.9585 40.1582 51.3985C40.1582 49.9285 41.2682 49.0585 42.9782 48.7585Z'
            fill='#8564E7'
        />
    </svg>
);

export default forwardRef(Icon);
