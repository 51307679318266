import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import RadioComponent from './RadioComponent';

function FormRadioComponent({
    label,
    name,
    required,
    defaultChecked,
    showErrorMessageBelow,
    ...otherProps
}) {
    const { errors, formState, register, setValue, getValues } = useFormContext();
    const isTouched = formState.touched[name] || formState.isSubmitted;
    const fieldError = errors[name];
    const errorMessage = isTouched && fieldError && fieldError.message;
    const currentValue = (getValues() || {})[name];
    const props = otherProps;
    // TODO: check if default values is fixed in next patch https://github.com/react-hook-form/react-hook-form/issues/2308

    useEffect(() => {
        if (defaultChecked && !isTouched) {
            setValue(name, otherProps.value);
        }
    }, [defaultChecked, isTouched, name, setValue, otherProps.value]);

    return (
        <RadioComponent
            name={name}
            id={name}
            currentValue={currentValue}
            label={label}
            ref={register({
                required
            })}
            errorMessage={errorMessage}
            showErrorMessageBelow={showErrorMessageBelow}
            {...props}
        />
    );
}

export default FormRadioComponent;
