import React from 'react';
import { Column } from 'components/simpleFlexbox';
import LoadingComponent from 'components/loading';

export function getWelcomeSteps({
    classes,
    first_name,
    onClickDataroPredict = () => {},
    onClickFI = () => {},
    onClickSkip = () => {},
    showSelectCRMList = false,
    tourType
}) {
    const steps = [
        {
            content: (
                <Column>
                    <LoadingComponent loading={true} fullScreen={false} backgroundColor={'white'} />
                </Column>
            ),
            placement: 'center',
            target: 'body',
            hideSkip: true,
            nextText: 'Start guided tour',
            buttonsReverse: true,
            containerType: 'LARGE',
            hideNext: true,
            id: 'welcome_0',
            stepStyles: {
                buttonWidth: 245,
                containerPadding: '60px 90px 60px 90px',
                containerWidth: null,
                buttonContainerTop: 0,
                skipButtonRight: 0,
                buttonContainerHorizontal: 'space-between',
                leftBarWidth: 100,
                buttonNextStyles: {
                    height: 50
                }
            }
        }
    ];

    if (!showSelectCRMList) {
        return steps.filter((step) => step.id !== 'welcome_1');
    }
    return steps;
}

export function onWelcomeStepEffect({ steps = [], stepIndex, localState }) {
    const currentStep = steps[stepIndex] || {};
    return [
        () => {
            if (currentStep.id === 'welcome_0') {
                document.querySelector('#main_container').style.marginTop = '60px';
            }
        },
        () => {}
    ];
}
