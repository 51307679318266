import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={props.width || 24}
        height={props.height || 24}
        fill='none'
        viewBox='0 0 24 24'
        id='google-ads'
        ref={ref}
    >
        <path
            stroke={props.fill || '#000'}
            d='M9.37997 5.53758C9.13795 5.97024 9 6.46901 9 7C9 7.50649 9.12552 7.98367 9.34714 8.40212L12 13.266M9.37997 5.53758C9.89312 4.6202 10.8741 4 12 4C13.1554 4 14.1582 4.65313 14.6594 5.61032L20.5987 16.5C20.8539 16.9413 21 17.4536 21 18C21 19.6569 19.6569 21 18 21C16.8598 21 15.8682 20.3639 15.3606 19.4272L12 13.266M9.37997 5.53758L3.35145 16.5898M3.35145 16.5898C3.27389 16.7351 3.20795 16.8876 3.15486 17.046C3.05442 17.3457 3 17.6665 3 18C3 19.6569 4.34315 21 6 21C6.94717 21 7.79182 20.5611 8.34162 19.8755C8.45367 19.7358 8.55348 19.5858 8.6394 19.4272M3.35145 16.5898C3.85618 15.6438 4.85291 15 6 15C7.65685 15 9 16.3431 9 18C9 18.5167 8.8694 19.0028 8.6394 19.4272M8.6394 19.4272L12 13.266'
        ></path>
    </svg>
);

export default forwardRef(Icon);
