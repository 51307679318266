import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 66}
        height={props.height || 76}
        viewBox='0 0 66 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 46.442 45.853 C 47.27 45.853 47.942 46.61 47.942 47.544 L 47.942 66.709 C 47.942 67.643 47.27 68.4 46.442 68.4 C 45.614 68.4 44.942 67.643 44.942 66.709 L 44.942 47.544 C 44.942 46.61 45.614 45.853 46.442 45.853 Z'
            fill='#8564E7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 39.786 51.4 C 39.233 50.784 39.284 49.835 39.901 49.282 L 45.471 44.282 C 46.047 43.766 46.921 43.771 47.489 44.295 L 52.919 49.295 C 53.528 49.856 53.567 50.805 53.006 51.414 C 52.445 52.024 51.496 52.063 50.887 51.502 L 46.46 47.426 L 41.905 51.515 C 41.288 52.068 40.34 52.017 39.786 51.4 Z'
            fill='#8564E7'
        />
        <path
            d='M31.5 12.1458C31.5 17.5922 27.2097 21.9395 22 21.9395C16.7903 21.9395 12.5 17.5922 12.5 12.1458C12.5 6.69934 16.7903 2.35205 22 2.35205C27.2097 2.35205 31.5 6.69934 31.5 12.1458Z'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            d='M39 39.3708C36.0696 31.247 29.0652 22.4575 21.3507 22.4575C12.1436 22.4575 4.13558 36.4572 2.67006 43.9428C1.20453 51.4284 0.242708 60.758 21.3507 60.758C24.1367 60.758 26.5415 60.6212 28.6139 60.3632'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.5 72.4888C55.5923 72.4888 63 65.0587 63 55.8476C63 46.6364 55.5923 39.2063 46.5 39.2063C37.4077 39.2063 30 46.6364 30 55.8476C30 65.0587 37.4077 72.4888 46.5 72.4888ZM46.5 75.4888C57.2696 75.4888 66 66.6951 66 55.8476C66 45 57.2696 36.2063 46.5 36.2063C35.7304 36.2063 27 45 27 55.8476C27 66.6951 35.7304 75.4888 46.5 75.4888Z'
            fill='#8564E7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M 39.891 58.299 C 39.338 57.682 39.389 56.734 40.005 56.181 L 45.576 51.181 C 46.151 50.664 47.025 50.67 47.594 51.193 L 53.023 56.193 C 53.633 56.755 53.672 57.704 53.111 58.313 C 52.55 58.922 51.601 58.961 50.991 58.4 L 46.565 54.324 L 42.009 58.413 C 41.393 58.966 40.444 58.915 39.891 58.299 Z'
            fill='#8564E7'
        />
    </svg>
);

export default forwardRef(Icon);
