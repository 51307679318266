import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 29}
        viewBox='0 0 128 128'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fill={props.fill || '#BFBFBF'}
            d='m113.206 106.265h-13.623v-86.28a1.749 1.749 0 0 0 -1.554-1.738l-46-5.192a1.75 1.75 0 0 0 -1.946 1.739v3.441h-19.916a1.75 1.75 0 0 0 -1.75 1.75v86.28h-13.623a1.75 1.75 0 1 0 0 3.5h35.289v3.441a1.75 1.75 0 0 0 1.75 1.75 1.61 1.61 0 0 0 .2-.011l45.9-5.18h15.276a1.75 1.75 0 0 0 0-3.5zm-81.289-84.53h18.166v4.5h-11.917a1.75 1.75 0 0 0 -1.75 1.75v78.28h-4.5zm8 84.53v-76.53h10.166v76.53zm13.666-89.513 42.5 4.8v84.9l-42.5 4.8z'
        />
        <path
            fill={props.fill || '#BFBFBF'}
            d='m61.125 58.792a5.208 5.208 0 1 0 5.208 5.208 5.214 5.214 0 0 0 -5.208-5.208zm0 6.916a1.708 1.708 0 1 1 1.708-1.708 1.71 1.71 0 0 1 -1.708 1.708z'
        />
    </svg>
);

export default forwardRef(Icon);
