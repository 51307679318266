import React from 'react';
import AIAssistWelcomeComponent from 'routes/onboarding/welcome/AIAssistWelcomeComponent';

export function getAIAssistSteps() {
    const steps = [
        {
            content: (
                <>
                    <AIAssistWelcomeComponent />
                </>
            ),
            nextText: 'Finish tour',
            hideSkip: true,
            placement: 'center',
            target: 'body',
            containerType: 'LARGE',
            id: 'aiassist_0',
            stepStyles: {
                buttonContainerHorizontal: 'center',
                buttonWidth: 245,
                leftBarWidth: 100,
                containerPadding: '60px 90px 120px 90px',
                containerWidth: null,
                buttonContainerTop: 'calc(100% + 40px)',
                skipButtonRight: 0,
                buttonNextStyles: {
                    height: 50
                }
            }
        }
    ];
    return steps;
}

export function onAIAssistStepEffect() {
    return [() => {}, () => {}];
}
