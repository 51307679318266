import React, { forwardRef } from 'react';
import IconTick from './icon-tick';

function Icon({ className, height = 32, width = 32 }, ref) {
    const tickHeight = height * 0.8;
    const tickWidth = width * 0.8;
    return (
        <div className={['top-3 right-3 bg-green-800 rounded-full', className].join(' ')} ref={ref}>
            <div className='flex justify-center items-center' style={{ width, height }}>
                <IconTick color='white' height={tickHeight} width={tickWidth} />
            </div>
        </div>
    );
}

export default forwardRef(Icon);
