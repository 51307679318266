import React, { forwardRef } from 'react';

const Icon = (props = {}, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 300 300'
        fill={props.fill || '#424147'}
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            transform='matrix(0.10000000149011612, 0, 0, -0.10000000149011612, 0, 300)'
            fill={props.fill || '#424147'}
            stroke='none'
        >
            <path d='M1363 2969 c-318 -28 -630 -169 -866 -389 -481 -451 -609 -1153 -316 -1741 135 -270 388 -523 658 -658 429 -214 914 -210 1341 10 528 272 841 842 790 1436 -30 352 -175 659 -430 913 -253 254 -562 400 -910 430 -127 11 -135 11 -267 -1z m414 -58 c509 -104 928 -475 1086 -960 47 -145 66 -251 70 -391 8 -270 -38 -482 -154 -714 -72 -141 -139 -235 -249 -347 -172 -174 -335 -277 -567 -359 -157 -56 -289 -75 -493 -74 -228 2 -433 54 -637 162 -386 206 -653 562 -744 995 -31 146 -32 379 -1 533 60 304 194 559 402 764 244 241 514 366 895 414 75 9 297 -4 392 -23z' />
            <path d='M 1349.196 2941.023 C 1168.923 2918.622 958.615 2861.073 769.124 2749.992 C 603.332 2652.804 453.476 2509.136 353.614 2385.008 C 150.614 2131.008 39.714 1849.281 47.714 1519.281 C 53.714 1305.281 117.931 1041.099 203.931 858.099 C 340.358 568.115 618.305 308.716 932.792 170.02 C 1132.356 82.007 1348.002 55.985 1547.158 58.703 C 2060.158 65.703 2570.01 393.925 2801.01 854.925 C 2913.01 1078.925 2978.601 1283.811 2944.601 1540.811 C 2930.601 1654.811 2929.745 1772.184 2894.745 1882.184 C 2850.745 2023.184 2785.857 2198.757 2692.857 2316.757 C 2625.857 2402.757 2528.683 2543.028 2435.683 2605.028 C 2282.683 2708.028 2281.473 2765.895 2095.473 2814.895 C 1995.473 2841.895 1898.168 2930.522 1758.168 2933.522 C 1670.168 2935.522 1368.196 2943.023 1349.196 2941.023 Z M 1795 2756 C 2012 2700 2193 2605 2348 2466 C 2623 2220 2769 1914 2787 1541 C 2797 1342 2747 1104 2660 929 C 2474 558 2113 291 1705 224 C 1584 205 1354 209 1242 234 C 783 334 414 667 271 1109 C 225 1249 215 1321 215 1500 C 215 1677 229 1767 276 1909 C 405 2294 707 2596 1091 2724 C 1250 2778 1338 2790 1530 2786 C 1667 2783 1712 2777 1795 2756 Z' />
            <path d='M 1841 2324 C 1709 2289 1602 2199 1543 2074 C 1510 2005 1505 1984 1502 1904 C 1494 1727 1567 1582 1711 1489 C 1851 1400 2065 1398 2192 1485 C 2212 1499 2230 1510 2232 1510 C 2234 1510 2234 1381 2232 1223 C 2228 904 2233 870 2280 845 C 2296 837 2316 830 2325 830 C 2354 830 2396 858 2408 885 C 2423 919 2425 1881 2410 1961 C 2361 2221 2092 2392 1841 2324 Z M 2086.842 2155.232 C 2230.842 2118.232 2238.625 1878.547 2273.625 1728.547 C 2285.625 1674.547 2383 940 2371 907 C 2359 875 2417.882 866.597 2388.882 889.597 C 2366.882 907.597 2370.447 1040.607 2367.447 1331.607 L 2308.658 1588.653 L 2233.672 1524.998 L 2219 1540 L 2392.692 1797.68 C 2186.692 1693.68 2017.793 1513.026 1771.743 1637.628 C 1688.852 1679.605 1623.691 1819.685 1660.549 1914.87 C 1712.104 2048.01 1676.359 2042.019 1821.866 2152.682 C 1863.901 2184.651 2015.842 2174.232 2086.842 2155.232 Z' />
            <path d='M 1875 2181 C 1803 2158 1768 2137 1726 2090 C 1678 2037 1650 1970 1643 1895 C 1630 1739 1766 1580 1925 1567 C 2073 1554 2307.408 1457.293 2354.408 1597.293 C 2398.408 1726.293 2241 2070 2125 2142 C 2045 2192 1953 2206 1875 2181 Z M 2070 2128 C 2170 2085 2230 1992 2230 1880 C 2230 1692 2056 1562 1875 1614 C 1798 1637 1721 1712 1695 1789 C 1655 1911 1706 2052 1814 2115 C 1895 2162 1981 2167 2070 2128 Z' />
            <path d='M 864 2236 C 757 2209 661 2128 612 2022 C 590 1974 585 1951 585 1880 C 585 1809 590 1786 612 1738 C 647 1662 684 1617 745 1575 C 869 1491 1023 1489 1149 1570 C 1391 1727 1366 2087 1105 2211 C 1032 2245 938 2255 864 2236 Z M 922.866 2094.11 C 988.866 2065.11 1035.426 2109.077 1073.426 2051.077 C 1180.426 1885.077 1188.355 1917.462 1128.275 1767.965 C 1108.263 1718.17 1036.125 1665.483 963.125 1665.483 C 891.125 1666.483 823.811 1664.156 775.811 1687.156 C 551.811 1796.156 554.683 1836.864 760.683 1978.864 C 848.683 2038.864 824.866 2137.11 922.866 2094.11 Z' />
            <path d='M 834.047 2094.36 C 765.047 2052.36 600.699 1922.366 600.699 1842.366 C 600.699 1672.366 927 1599 1069 1693 C 1254 1815 1169 2100 949 2100 C 905 2100 877.047 2120.36 834.047 2094.36 Z M 1044 2036 C 1200 1945 1136 1695 957 1696 C 847 1696 770 1772 770 1877 C 770 1938 785 1973 827 2011 C 870 2051 890 2059 949 2060 C 989 2060 1014 2054 1044 2036 Z' />
            <path d='M 908 900 C 882 873 878 864 883 833 C 887 813 902 784 917 767 C 994 686 1171 589 1305 554 C 1401 529 1599 529 1695 554 C 1831 589 2006 686 2085 769 C 2146 834 2110 930 2025 930 C 1998 930 1978 918 1928 875 C 1809 771 1663 717 1500 717 C 1337 717 1191 771 1072 875 C 995 942 957 948 908 900 Z M 919.973 875.07 C 878.871 882.092 937.194 800.305 944.194 793.305 C 968.194 770.305 1071.276 668.684 1125.823 652.853 C 1308.241 599.909 1511.555 469.26 1716.555 590.26 C 1748.555 608.26 1866.952 635.029 1876.952 646.029 C 1909.952 682.029 1996.912 744.071 2018.912 737.071 C 2046.912 728.071 2076 836 2056 805 C 2037 776 1955 710 1894 674 C 1836 640 1707 595 1628 581 C 1405 542 1147 615 985 762 C 926 816 916 839 937 869 C 954 894 886.973 898.07 919.973 875.07 Z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
