import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 15}
        height={props.height || 16}
        viewBox='0 0 15 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <circle cx='7.5' cy='8.5' r='7.5' fill={props.fill || '#E20000'} />
        <path
            d='M8.31725 3.878V7.882C8.31725 8.30667 8.29625 8.72433 8.25425 9.135C8.21225 9.541 8.15858 9.97267 8.09325 10.43H6.91725C6.85192 9.97267 6.79825 9.541 6.75625 9.135C6.71425 8.72433 6.69325 8.30667 6.69325 7.882V3.878H8.31725ZM6.42025 13.048C6.42025 12.9033 6.44592 12.7657 6.49725 12.635C6.55325 12.5043 6.62792 12.3923 6.72125 12.299C6.81925 12.2057 6.93358 12.131 7.06425 12.075C7.19492 12.019 7.33492 11.991 7.48425 11.991C7.62892 11.991 7.76658 12.019 7.89725 12.075C8.02792 12.131 8.13992 12.2057 8.23325 12.299C8.33125 12.3923 8.40825 12.5043 8.46425 12.635C8.52025 12.7657 8.54825 12.9033 8.54825 13.048C8.54825 13.1973 8.52025 13.3373 8.46425 13.468C8.40825 13.594 8.33125 13.7037 8.23325 13.797C8.13992 13.8903 8.02792 13.9627 7.89725 14.014C7.76658 14.07 7.62892 14.098 7.48425 14.098C7.33492 14.098 7.19492 14.07 7.06425 14.014C6.93358 13.9627 6.81925 13.8903 6.72125 13.797C6.62792 13.7037 6.55325 13.594 6.49725 13.468C6.44592 13.3373 6.42025 13.1973 6.42025 13.048Z'
            fill='white'
        />
    </svg>
);

export default forwardRef(Icon);
