import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 66}
        height={props.height || 76}
        viewBox='0 0 66 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M31.5 12.1458C31.5 17.5922 27.2097 21.9395 22 21.9395C16.7903 21.9395 12.5 17.5922 12.5 12.1458C12.5 6.69934 16.7903 2.35205 22 2.35205C27.2097 2.35205 31.5 6.69934 31.5 12.1458Z'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            d='M39 39.3708C36.0696 31.247 29.0652 22.4575 21.3507 22.4575C12.1436 22.4575 4.13558 36.4572 2.67006 43.9428C1.20453 51.4284 0.242708 60.758 21.3507 60.758C24.1367 60.758 26.5415 60.6212 28.6139 60.3632'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.5 72.4888C55.5923 72.4888 63 65.0587 63 55.8476C63 46.6364 55.5923 39.2063 46.5 39.2063C37.4077 39.2063 30 46.6364 30 55.8476C30 65.0587 37.4077 72.4888 46.5 72.4888ZM46.5 75.4888C57.2696 75.4888 66 66.6951 66 55.8476C66 45 57.2696 36.2063 46.5 36.2063C35.7304 36.2063 27 45 27 55.8476C27 66.6951 35.7304 75.4888 46.5 75.4888Z'
            fill='#8564E7'
        />
        <g
            transform='matrix(0.000003, 0.011857, -0.012988, 0.000004, 61.204651, 43.037498)'
            fill='#000000'
            stroke='none'
        >
            <path
                d='M1465 2051 c-16 -10 -52 -42 -79 -70 l-50 -50 -47 44 c-42 39 -54 45 -91 45 l-43 0 -552 -553 -553 -552 0 -45 c0 -43 3 -47 109 -153 l110 -110 38 8 c61 12 84 8 109 -17 26 -25 30 -51 18 -111 -7 -38 -7 -38 103 -148 106 -106 110 -109 153 -109 41 0 48 4 111 68 l67 68 45 -42 c51 -49 85 -61 125 -45 43 18 1109 1090 1118 1124 11 45 -12 80 -122 187 -87 84 -109 101 -127 96 -71 -21 -85 -19 -118 13 -32 33 -34 47 -13 118 5 18 -11 40 -92 124 -130 133 -159 148 -219 110z m217 -286 c-6 -60 13 -110 56 -143 30 -23 46 -27 99 -28 l65 -1 84 -84 84 -84 -532 -532 -533 -533 -35 35 -35 35 377 377 c298 298 380 375 390 365 21 -21 57 -14 70 13 9 21 9 29 -5 42 -15 15 -13 20 28 64 39 41 45 54 45 91 0 40 -5 47 -109 151 l-110 110 -38 -8 c-62 -12 -84 -8 -110 18 -26 26 -30 49 -18 108 6 32 3 41 -23 70 l-31 34 57 58 56 57 87 -86 c86 -85 86 -86 81 -129z m-397 84 l80 -81 1 -57 c1 -107 61 -167 165 -164 l56 1 84 -84 84 -84 -533 -532 -532 -533 -84 84 -84 84 1 56 c3 105 -59 167 -164 164 l-56 -1 -84 84 -84 84 420 420 c332 333 423 418 440 415 29 -7 45 7 47 41 1 22 20 49 77 107 42 42 78 77 81 77 3 0 41 -36 85 -81z'
                fill='#8564E7'
            />
            <path
                d='M1077 1675 c-26 -19 -20 -50 14 -79 34 -29 49 -32 67 -14 19 19 14 53 -10 75 -32 28 -51 33 -71 18z'
                fill='#8564E7'
            />
            <path
                d='M1202 1548 c-19 -19 -14 -53 11 -75 34 -31 45 -35 67 -23 30 16 25 54 -11 84 -34 29 -49 32 -67 14z'
                fill='#8564E7'
            />
            <path
                d='M1333 1423 c-38 -15 7 -103 53 -103 50 0 57 47 13 84 -30 26 -42 29 -66 19z'
                fill='#8564E7'
            />
            <path
                d='M1452 1288 c-19 -19 -14 -42 17 -72 39 -37 81 -31 81 12 0 51 -66 92 -98 60z'
                fill='#8564E7'
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
