import { useEffect, useState } from 'react';
import * as AWS from 'aws-sdk/global';
import { useHistory } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Hub } from 'aws-amplify';
import SLUGS from 'resources/slugs';
import useUserPool from './useUserPool';
import useUserActivity from '../useUserActivity';

const UserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const region = process.env.REACT_APP_COGNITO_REGION;
const IdentityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;

const useLogin = () => {
    const { push } = useHistory();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { userPool } = useUserPool();
    const { addUserActivity } = useUserActivity();

    function login(data) {
        return new Promise((resolve, reject) => {
            setLoading(true);
            setError('');
            try {
                let authenticationDetails = new AuthenticationDetails({
                    Username: data.email,
                    Password: data.password
                });
                let userData = {
                    Username: data.email,
                    Pool: userPool
                };
                let cognitoUser = new CognitoUser(userData);
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: async (result) => {
                        // const accessToken = result.getAccessToken().getJwtToken();
                        AWS.config.region = region;

                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                            IdentityPoolId,
                            Logins: {
                                [`cognito-idp.${region}.amazonaws.com/${UserPoolId}`]: result
                                    .getIdToken()
                                    .getJwtToken()
                            }
                        });

                        AWS.config.credentials.refresh((error) => {
                            setLoading(false);
                            if (error) {
                                setError(error.message);
                                reject(error);
                            } else {
                                setError('');
                                resolve('');
                                try {
                                    addUserActivity({
                                        event_name: 'login',
                                        event_payload: {}
                                    });
                                } catch (ex) {}
                            }
                        });
                    },
                    onFailure: (err) => {
                        setLoading(false);
                        let errorMessage = 'Wrong Email or Password';
                        if (err.code === 'PasswordResetRequiredException') {
                            errorMessage = 'Password reset required';
                        }
                        setError(errorMessage);
                        reject(new Error(errorMessage));
                    }
                });
            } catch (e) {
                setLoading(false);
                setError(e.message);
                reject(e);
            }
        });
    }

    useEffect(() => {
        Hub.listen('auth', (response) => {
            const {
                payload: { event, data }
            } = response;
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    push(SLUGS.home);
                    break;
                case 'signOut':
                    console.log('signOut');
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
                    console.log('Default', event, data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { error, loading, login };
};

export default useLogin;
