import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 44}
        height={props.height || 31}
        viewBox='0 0 44 31'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M18.3101 3.35903C19.7292 1.88046 21.7048 0.963476 23.8897 0.963476C26.7942 0.963476 29.3283 2.58308 30.6778 4.98741C31.8505 4.46342 33.1485 4.17197 34.5143 4.17197C39.7529 4.17197 44 8.45601 44 13.7404C44 19.0254 39.7529 23.3095 34.5143 23.3095C33.875 23.3095 33.2501 23.2455 32.6459 23.1233C31.4575 25.2431 29.1929 26.6753 26.5937 26.6753C25.5056 26.6753 24.4764 26.4239 23.5601 25.977C22.3554 28.8107 19.5487 30.7976 16.2775 30.7976C12.8709 30.7976 9.96769 28.6421 8.85328 25.6191C8.36627 25.7226 7.86171 25.7765 7.34399 25.7765C3.28809 25.7765 0 22.4545 0 18.356C0 15.6095 1.47732 13.2114 3.67231 11.9284C3.2204 10.8885 2.96906 9.74091 2.96906 8.53436C2.96906 3.82097 6.79556 0.000114441 11.5152 0.000114441C14.2862 0.000114441 16.7488 1.31761 18.3101 3.35903Z'
            fill='#505359'
        />
        <path
            d='M6.37127 15.9714C6.34369 16.0435 6.3813 16.0586 6.39008 16.0711C6.47281 16.1313 6.5568 16.1745 6.64141 16.2228C7.09019 16.4609 7.51389 16.5305 7.95703 16.5305C8.85959 16.5305 9.41993 16.0504 9.41993 15.2776V15.2625C9.41993 14.548 8.78751 14.2885 8.19395 14.1011L8.11686 14.076C7.66933 13.9306 7.28324 13.8053 7.28324 13.5107V13.495C7.28324 13.2431 7.50888 13.0575 7.85862 13.0575C8.24723 13.0575 8.70854 13.1866 9.00563 13.3509C9.00563 13.3509 9.09275 13.4073 9.12472 13.3227C9.14227 13.2775 9.2927 12.8726 9.30837 12.8288C9.32529 12.7811 9.2952 12.746 9.26449 12.7272C8.9254 12.521 8.45657 12.38 7.97144 12.38L7.88119 12.3806C7.05509 12.3806 6.47845 12.8795 6.47845 13.5947V13.6097C6.47845 14.3637 7.11463 14.6082 7.7107 14.7787L7.8066 14.8081C8.24096 14.9416 8.61515 15.0563 8.61515 15.3622V15.3772C8.61515 15.6568 8.37196 15.8649 7.97959 15.8649C7.82728 15.8649 7.34153 15.8617 6.81691 15.5302C6.75361 15.4932 6.71663 15.4662 6.66774 15.4368C6.64204 15.4205 6.57748 15.3923 6.54928 15.4775L6.37127 15.9714Z'
            fill='white'
        />
        <path
            d='M19.5861 15.9714C19.5585 16.0435 19.5961 16.0586 19.6049 16.0711C19.6877 16.1313 19.7716 16.1745 19.8563 16.2228C20.305 16.4609 20.7287 16.5305 21.1719 16.5305C22.0744 16.5305 22.6348 16.0504 22.6348 15.2776V15.2625C22.6348 14.548 22.0024 14.2885 21.4088 14.1011L21.3317 14.076C20.8842 13.9306 20.4981 13.8053 20.4981 13.5107V13.495C20.4981 13.2431 20.7237 13.0575 21.0735 13.0575C21.4621 13.0575 21.9234 13.1866 22.2205 13.3509C22.2205 13.3509 22.3076 13.4073 22.3396 13.3227C22.3571 13.2775 22.5075 12.8726 22.5232 12.8288C22.5401 12.7811 22.51 12.746 22.4793 12.7272C22.1402 12.521 21.6714 12.38 21.1863 12.38L21.096 12.3806C20.2699 12.3806 19.6933 12.8795 19.6933 13.5947V13.6097C19.6933 14.3637 20.3295 14.6082 20.9255 14.7787L21.0214 14.8081C21.4558 14.9416 21.8306 15.0563 21.8306 15.3622V15.3772C21.8306 15.6568 21.5868 15.8649 21.1944 15.8649C21.0421 15.8649 20.5564 15.8617 20.0318 15.5302C19.9685 15.4932 19.9308 15.4675 19.8832 15.4368C19.8669 15.4261 19.7904 15.3967 19.7641 15.4775L19.5861 15.9714Z'
            fill='white'
        />
        <path
            d='M28.6085 14.457C28.6085 14.8939 28.527 15.238 28.3665 15.4812C28.208 15.7219 27.9679 15.8391 27.6332 15.8391C27.2979 15.8391 27.0591 15.7225 26.903 15.4812C26.745 15.2386 26.6648 14.8939 26.6648 14.457C26.6648 14.0208 26.745 13.6773 26.903 13.4366C27.0591 13.1985 27.2979 13.0825 27.6332 13.0825C27.9679 13.0825 28.208 13.1985 28.3672 13.4366C28.527 13.6773 28.6085 14.0208 28.6085 14.457ZM29.3619 13.6472C29.2879 13.3971 29.1726 13.1765 29.019 12.9929C28.8654 12.8086 28.6711 12.6607 28.4405 12.5529C28.2105 12.4457 27.9384 12.3912 27.6332 12.3912C27.3273 12.3912 27.0553 12.4457 26.8253 12.5529C26.5946 12.6607 26.4003 12.8086 26.2461 12.9929C26.0932 13.1771 25.9779 13.3978 25.9033 13.6472C25.8299 13.8961 25.793 14.1681 25.793 14.457C25.793 14.746 25.8299 15.0186 25.9033 15.2668C25.9779 15.5163 26.0926 15.7369 26.2468 15.9212C26.4003 16.1055 26.5952 16.2528 26.8253 16.3574C27.0559 16.4621 27.3273 16.5154 27.6332 16.5154C27.9384 16.5154 28.2098 16.4621 28.4405 16.3574C28.6705 16.2528 28.8654 16.1055 29.019 15.9212C29.1726 15.7375 29.2879 15.5169 29.3619 15.2668C29.4358 15.018 29.4728 14.7454 29.4728 14.457C29.4728 14.1687 29.4358 13.8961 29.3619 13.6472Z'
            fill='white'
        />
        <path
            d='M35.5459 15.7224C35.5208 15.6491 35.45 15.6767 35.45 15.6767C35.3403 15.7187 35.2237 15.7575 35.0996 15.777C34.9736 15.7964 34.8351 15.8064 34.6866 15.8064C34.3218 15.8064 34.0322 15.698 33.8248 15.4836C33.6167 15.2693 33.5001 14.9227 33.5013 14.4538C33.5026 14.027 33.6054 13.7061 33.7903 13.4616C33.9739 13.2184 34.2535 13.0937 34.6264 13.0937C34.9373 13.0937 35.1742 13.1294 35.4224 13.2078C35.4224 13.2078 35.482 13.2335 35.5102 13.1558C35.576 12.9727 35.6249 12.8417 35.6951 12.6405C35.7151 12.5835 35.6662 12.5591 35.6487 12.5522C35.5509 12.5139 35.3203 12.4519 35.146 12.4256C34.983 12.4005 34.7925 12.3873 34.5807 12.3873C34.2641 12.3873 33.9821 12.4412 33.7408 12.549C33.5001 12.6562 33.2958 12.8041 33.134 12.9884C32.9723 13.1727 32.8495 13.3933 32.7674 13.6428C32.6859 13.8916 32.6445 14.1649 32.6445 14.4538C32.6445 15.0787 32.8131 15.5839 33.146 15.9537C33.4794 16.3248 33.9802 16.5134 34.6333 16.5134C35.0194 16.5134 35.4155 16.4351 35.7001 16.3229C35.7001 16.3229 35.7546 16.2966 35.7308 16.2333L35.5459 15.7224Z'
            fill='white'
        />
        <path
            d='M36.8639 14.0389C36.8997 13.7964 36.9667 13.5945 37.0701 13.4372C37.2262 13.1984 37.4644 13.0674 37.7991 13.0674C38.1338 13.0674 38.355 13.199 38.5136 13.4372C38.6189 13.5945 38.6647 13.8051 38.6829 14.0389H36.8639ZM39.4005 13.5055C39.3366 13.2642 39.178 13.0204 39.074 12.9088C38.9097 12.7321 38.7493 12.6086 38.5901 12.5397C38.382 12.4507 38.1325 12.3918 37.8593 12.3918C37.5409 12.3918 37.2519 12.445 37.0175 12.5553C36.7825 12.6657 36.585 12.8161 36.4302 13.0035C36.2754 13.1903 36.1588 13.4128 36.0849 13.6654C36.0103 13.9167 35.9727 14.1906 35.9727 14.4796C35.9727 14.7735 36.0115 15.0474 36.0886 15.2937C36.1663 15.542 36.2904 15.7607 36.4584 15.9418C36.6258 16.1242 36.8414 16.2671 37.0996 16.3668C37.356 16.4658 37.6675 16.5172 38.0254 16.5166C38.7618 16.5141 39.1498 16.3499 39.3096 16.2615C39.3378 16.2458 39.3648 16.2182 39.3309 16.1393L39.1642 15.6723C39.1392 15.6028 39.0683 15.6284 39.0683 15.6284C38.8859 15.6961 38.6264 15.8177 38.0216 15.8165C37.6261 15.8159 37.3328 15.6993 37.1491 15.5169C36.9605 15.3301 36.8683 15.0556 36.852 14.6682L39.4024 14.6707C39.4024 14.6707 39.4695 14.6695 39.4764 14.6043C39.4789 14.5767 39.5641 14.0803 39.4005 13.5055Z'
            fill='white'
        />
        <path
            d='M16.4421 14.0389C16.4784 13.7964 16.5449 13.5945 16.6483 13.4372C16.8043 13.1984 17.0425 13.0674 17.3772 13.0674C17.7119 13.0674 17.9332 13.199 18.0924 13.4372C18.197 13.5945 18.2428 13.8051 18.261 14.0389H16.4421ZM18.978 13.5055C18.9141 13.2642 18.7561 13.0204 18.6521 12.9088C18.4879 12.7321 18.3274 12.6086 18.1682 12.5397C17.9601 12.4507 17.7107 12.3918 17.4374 12.3918C17.1196 12.3918 16.83 12.445 16.5956 12.5553C16.3606 12.6657 16.1631 12.8161 16.0083 13.0035C15.8535 13.1903 15.7369 13.4128 15.663 13.6654C15.589 13.9167 15.5508 14.1906 15.5508 14.4796C15.5508 14.7735 15.5896 15.0474 15.6667 15.2937C15.7445 15.542 15.8686 15.7607 16.0365 15.9418C16.2039 16.1242 16.4195 16.2671 16.6777 16.3668C16.9341 16.4658 17.2456 16.5172 17.6035 16.5166C18.34 16.5141 18.7279 16.3499 18.8878 16.2615C18.916 16.2458 18.9429 16.2182 18.9091 16.1393L18.743 15.6723C18.7173 15.6028 18.6465 15.6284 18.6465 15.6284C18.4641 15.6961 18.2052 15.8177 17.5991 15.8165C17.2042 15.8159 16.9109 15.6993 16.7272 15.5169C16.5386 15.3301 16.4465 15.0556 16.4302 14.6682L18.9805 14.6707C18.9805 14.6707 19.0476 14.6695 19.0545 14.6043C19.057 14.5767 19.1422 14.0803 18.978 13.5055Z'
            fill='white'
        />
        <path
            d='M10.9288 15.7086C10.8291 15.629 10.8153 15.6089 10.7815 15.5575C10.7313 15.4792 10.7056 15.3676 10.7056 15.226C10.7056 15.0016 10.7796 14.8405 10.9332 14.7321C10.9313 14.7327 11.1525 14.5409 11.6728 14.5478C12.0382 14.5528 12.3647 14.6067 12.3647 14.6067V15.7662H12.3653C12.3653 15.7662 12.0413 15.8358 11.6765 15.8577C11.1575 15.8891 10.9269 15.7079 10.9288 15.7086ZM11.9435 13.9166C11.8401 13.9091 11.706 13.9047 11.5455 13.9047C11.3268 13.9047 11.1155 13.9323 10.9175 13.9856C10.7182 14.0388 10.5389 14.1222 10.3847 14.2325C10.2299 14.3434 10.1052 14.4851 10.0149 14.6531C9.92466 14.8211 9.87891 15.0191 9.87891 15.241C9.87891 15.4666 9.91777 15.6628 9.99549 15.8233C10.0732 15.9844 10.1854 16.1185 10.3283 16.2219C10.47 16.3253 10.6448 16.4012 10.8479 16.4469C11.0479 16.4927 11.2747 16.5159 11.523 16.5159C11.7843 16.5159 12.0451 16.4946 12.2977 16.4513C12.5477 16.4087 12.8549 16.3466 12.9401 16.3272C13.0247 16.3072 13.1187 16.2815 13.1187 16.2815C13.182 16.2658 13.177 16.1981 13.177 16.1981L13.1758 13.8658C13.1758 13.3544 13.0391 12.9752 12.7702 12.7401C12.5026 12.5057 12.1084 12.3873 11.5988 12.3873C11.4076 12.3873 11.0999 12.4136 10.9156 12.4506C10.9156 12.4506 10.3584 12.5584 10.129 12.7376C10.129 12.7376 10.0788 12.769 10.1064 12.8392L10.2869 13.3243C10.3095 13.387 10.3703 13.3657 10.3703 13.3657C10.3703 13.3657 10.3897 13.3581 10.4123 13.345C10.9031 13.078 11.5236 13.0861 11.5236 13.0861C11.7994 13.0861 12.0112 13.1413 12.1541 13.251C12.2933 13.3575 12.3641 13.5186 12.3641 13.8583V13.9661C12.1447 13.9348 11.9435 13.9166 11.9435 13.9166Z'
            fill='white'
        />
        <path
            d='M32.5111 12.6026C32.5305 12.5449 32.4898 12.5174 32.4728 12.5111C32.4296 12.4942 32.2127 12.4484 32.0454 12.4378C31.7251 12.4183 31.5471 12.4722 31.3879 12.5437C31.2299 12.6151 31.0544 12.7305 30.9566 12.8615V12.5512C30.9566 12.508 30.9259 12.4735 30.8833 12.4735H30.2296C30.187 12.4735 30.1562 12.508 30.1562 12.5512V16.3551C30.1562 16.3978 30.1913 16.4329 30.234 16.4329H30.904C30.9466 16.4329 30.9811 16.3978 30.9811 16.3551V14.4547C30.9811 14.1996 31.0093 13.9452 31.0657 13.7853C31.1209 13.6274 31.1961 13.5008 31.2888 13.4099C31.3822 13.3196 31.4882 13.2563 31.6041 13.2206C31.7226 13.1843 31.8536 13.1723 31.9463 13.1723C32.0798 13.1723 32.2265 13.2068 32.2265 13.2068C32.2754 13.2125 32.303 13.1824 32.3193 13.1379C32.3631 13.0213 32.4872 12.6722 32.5111 12.6026Z'
            fill='white'
        />
        <path
            d='M26.225 10.8389C26.1435 10.8139 26.0695 10.7969 25.973 10.7788C25.8752 10.7612 25.7587 10.7524 25.6264 10.7524C25.1651 10.7524 24.8016 10.8828 24.5465 11.1398C24.2926 11.3955 24.1203 11.7847 24.0338 12.2968L24.0024 12.4692H23.4233C23.4233 12.4692 23.3531 12.4667 23.338 12.5431L23.2434 13.074C23.2365 13.1242 23.2584 13.1561 23.3261 13.1561H23.8896L23.318 16.3477C23.2735 16.6047 23.2221 16.8159 23.165 16.9764C23.1093 17.1343 23.0547 17.2528 22.987 17.3393C22.9219 17.422 22.8604 17.4834 22.7539 17.5192C22.6661 17.5486 22.5646 17.5624 22.4536 17.5624C22.3922 17.5624 22.3101 17.5524 22.2493 17.5398C22.1891 17.5279 22.1572 17.5148 22.1114 17.4953C22.1114 17.4953 22.0456 17.4703 22.0193 17.5361C21.9986 17.5906 21.8482 18.0037 21.83 18.0544C21.8124 18.1052 21.8375 18.1447 21.8695 18.1566C21.9447 18.1829 22.0005 18.2005 22.1026 18.2249C22.2443 18.2581 22.364 18.26 22.4762 18.26C22.7106 18.26 22.925 18.2268 23.1024 18.1629C23.2804 18.0983 23.4358 17.9861 23.5737 17.8344C23.7222 17.6702 23.8156 17.4985 23.9046 17.2634C23.993 17.0315 24.0689 16.7432 24.129 16.4072L24.7038 13.1561H25.5437C25.5437 13.1561 25.6145 13.1586 25.6289 13.0815L25.7242 12.5513C25.7305 12.5005 25.7091 12.4692 25.6408 12.4692H24.8254C24.8298 12.451 24.8668 12.1639 24.9601 11.8938C25.0003 11.7791 25.0755 11.6857 25.1388 11.6218C25.2015 11.5591 25.2735 11.5146 25.3525 11.4889C25.4334 11.4626 25.5255 11.45 25.6264 11.45C25.7029 11.45 25.7787 11.4588 25.8358 11.4707C25.9147 11.4876 25.9454 11.4964 25.9661 11.5027C26.0495 11.5278 26.0608 11.5033 26.0771 11.4632L26.272 10.9279C26.2921 10.8703 26.2425 10.8458 26.225 10.8389Z'
            fill='white'
        />
        <path
            d='M14.8282 16.3558C14.8282 16.3984 14.7975 16.4329 14.7549 16.4329H14.0786C14.0359 16.4329 14.0059 16.3984 14.0059 16.3558V10.9128C14.0059 10.8702 14.0359 10.8357 14.0786 10.8357H14.7549C14.7975 10.8357 14.8282 10.8702 14.8282 10.9128V16.3558Z'
            fill='white'
        />
    </svg>
);

export default forwardRef(Icon);
