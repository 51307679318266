import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';

const useUserPool = () => {
    const UserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
    const ClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const userPool = new CognitoUserPool({
        UserPoolId,
        ClientId
    });

    function getIdToken() {
        return new Promise((resolve, reject) => {
            try {
                const cognitoUser = userPool.getCurrentUser();
                if (!cognitoUser) {
                    return resolve(null);
                }
                cognitoUser.getSession((err, result) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(result.idToken.jwtToken);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    function isLoggedIn() {
        const cognitoUser = userPool.getCurrentUser();
        return !!cognitoUser;
    }

    async function changePassword(email, oldPassword, newPassword) {
        let authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: oldPassword.trim()
        });
        let userData = {
            Username: email,
            Pool: userPool
        };
        let cognitoUser = new CognitoUser(userData);
        await new Promise((resolve, reject) => {
            try {
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: async () => {
                        cognitoUser.changePassword(oldPassword.trim(), newPassword, function (err) {
                            if (err) {
                                reject(err);
                            }
                            resolve();
                        });
                    },
                    onFailure: (err) => {
                        let message = err.message.trim();
                        if (message === 'Incorrect username or password.') {
                            message = 'Incorrect password';
                        }
                        reject(new Error(message));
                    }
                });
            } catch (e) {
                reject(e);
            }
        });

        await new Promise((resolve, reject) => {
            cognitoUser.globalSignOut({
                onSuccess: () => {
                    resolve();
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
        window.location.reload();
    }

    function setPassword(email, oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            try {
                let authenticationDetails = new AuthenticationDetails({
                    Username: email,
                    Password: oldPassword.trim()
                });
                let userData = {
                    Username: email,
                    Pool: userPool
                };
                let cognitoUser = new CognitoUser(userData);
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: async () => {},
                    onFailure: (err) => {
                        let message = err.message.trim();
                        if (message === 'Incorrect username or password.') {
                            message = 'Incorrect password';
                        }
                        reject(new Error(message));
                    },
                    newPasswordRequired: function (userAttributes) {
                        delete userAttributes.email_verified;
                        const sessionUserAttributes = userAttributes;

                        cognitoUser.completeNewPasswordChallenge(
                            newPassword,
                            sessionUserAttributes,
                            {
                                onSuccess: async () => {
                                    cognitoUser.signOut();
                                    resolve();
                                },
                                onFailure: (err) => {
                                    let message = err.message.trim();
                                    if (message === 'Incorrect username or password.') {
                                        message = 'Incorrect password';
                                    }
                                    reject(new Error(message));
                                }
                            }
                        );
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    function initAmplify() {
        const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
        const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
        const region = process.env.REACT_APP_COGNITO_REGION;
        const identityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
        const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
        const frontendURL = process.env.REACT_APP_FRONTEND_URL;

        Amplify.configure({
            Auth: {
                identityPoolId,
                region,
                userPoolId,
                userPoolWebClientId: clientId,
                oauth: {
                    domain: cognitoDomain,
                    scope: ['openid'],
                    redirectSignIn: `${frontendURL}/login/`,
                    redirectSignOut: `${frontendURL}/logout`,
                    responseType: 'code'
                }
            }
        });
    }

    return { changePassword, setPassword, getIdToken, isLoggedIn, userPool, initAmplify };
};

export default useUserPool;
