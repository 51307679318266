import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 38}
        height={props.height || 38}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.0003 20.5C11.8467 20.5009 11.6945 20.4715 11.5524 20.4134C11.4102 20.3554 11.2809 20.2698 11.1719 20.1617L2.10692 11.085C0.969846 9.93606 0.332031 8.38485 0.332031 6.76835C0.332031 5.15186 0.969846 3.60065 2.10692 2.45169C3.2529 1.30895 4.80523 0.667236 6.42359 0.667236C8.04195 0.667236 9.59429 1.30895 10.7403 2.45169L12.0003 3.71169L13.2603 2.45169C14.4062 1.30895 15.9586 0.667236 17.5769 0.667236C19.1953 0.667236 20.7476 1.30895 21.8936 2.45169C23.0307 3.60065 23.6685 5.15186 23.6685 6.76835C23.6685 8.38485 23.0307 9.93606 21.8936 11.085L12.8286 20.1617C12.7196 20.2698 12.5903 20.3554 12.4481 20.4134C12.306 20.4715 12.1538 20.5009 12.0003 20.5Z'
            fill='#5D5D5D'
        />
    </svg>
);

export default forwardRef(Icon);
