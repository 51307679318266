import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 11}
        height={props.height || 21}
        viewBox='0 0 11 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M2 2L9 10.204L2 18.2001'
            stroke={props.fill || '#FFB282'}
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default forwardRef(Icon);
