import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import COUNTRIES from 'resources/countries';
import SLUGS from 'resources/slugs';
import useClientSettings from './useClientSettings';
import useCRMTypes from './useCrmTypes';

function useUpgradeAccountFetch() {
    const { push } = useHistory();
    const queryCache = useQueryClient();
    const fetchAuth = useFetchAuth();

    function post(payload) {
        return fetchAuth('/upgrade_request', {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post, {
        onSuccess: (data, variables) => {
            queryCache.invalidateQueries({ queryKey: 'clientSettings' });
            if (data.success) {
                if (variables.preventRedirectToSetup) {
                    push({
                        pathname: SLUGS.home
                    });
                } else {
                    let search = '?noredirect=true';
                    if (variables.fromCreateClient) {
                        search += '&fromCreateClient=true';
                    }
                    push({
                        pathname: SLUGS.accountSetup,
                        search
                    });
                }
            } else {
                push({
                    pathname: SLUGS.home,
                    search: '?show_upgrade=true&upgrade_error=true'
                });
            }
        },
        onError: (err, variables) => {
            Sentry.captureException(err, {
                tags: ['upgrade_account'],
                extra: variables
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
    return {
        data,
        error,
        isLoading,
        mutate
    };
}

function useCreateClient() {
    const { isLoading: isLoadingCRMTypes, data: crm_types_prop = [] } = useCRMTypes();
    const { isLoading: isLoadingUpgradeAccount, mutate: upgradeAccount } = useUpgradeAccountFetch();

    const settingsParams = [
        'declared_country',
        'declared_organisation_name',
        'declared_crm_type',
        'estimated_donors_count'
    ];
    const {
        data: {
            declared_country,
            declared_organisation_name,
            declared_crm_type,
            estimated_donors_count
        },
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });
    const isLoading = isLoadingCRMTypes || isLoadingUpgradeAccount || isLoadingClientSettings;

    const crm_types = crm_types_prop.filter((t) => !!t.onboarding_order);

    function createClient() {
        const {
            default_currency,
            market,
            tz: timezone
        } = COUNTRIES.find((c) => c.name === declared_country);
        const payload = {
            crm_type: declared_crm_type,
            client_name_external: declared_organisation_name,
            client_name: declared_organisation_name
                .toLowerCase()
                .replace(/'/g, '')
                .replace(/[^A-Z0-9]+/gi, '_'),
            details: `estimated_donors_count = ${estimated_donors_count} - `,
            email: false,
            phone: false,
            countryCode: declared_country,
            country: declared_country,
            timezone,
            default_currency,
            market,
            fromCreateClient: true
        };
        // assume that the CRM is included in the FI Free
        const crms_with_fi_free = crm_types
            .filter((t) => t.self_onboarding_fi_free === true)
            .map((t) => t.crm_type);
        const selectedCRMIsIncluded = crms_with_fi_free.includes(declared_crm_type);
        if (selectedCRMIsIncluded) {
            upgradeAccount(payload);
        }
    }
    return {
        isLoading,
        createClient
    };
}

export default function useUpgradeAccount() {
    const {
        data: resultUpgradeAccount,
        error: errorUpgradeAccount,
        isLoading: isLoadingUpgradeAccount,
        mutate: upgradeAccount
    } = useUpgradeAccountFetch();

    const { isLoading: isLoadingCreateClient, createClient } = useCreateClient();
    const isLoading = isLoadingUpgradeAccount || isLoadingCreateClient;

    return {
        isLoading,
        resultUpgradeAccount,
        errorUpgradeAccount,
        upgradeAccount,
        createClient
    };
}
