import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 24}
        height={props.height || 26}
        viewBox='0 0 21 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.5 9.22388V24.2239V9.22388Z'
            fill={props.fill || '#D8D8D8'}
        />
        <path d='M19.5 9.22388V24.2239' stroke='#424147' strokeWidth='3' strokeLinecap='round' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.55882 15.5745V24.0451V15.5745Z'
            fill={props.fill || '#D8D8D8'}
        />
        <path d='M1.55882 15.5745V24.0451' stroke='#424147' strokeWidth='3' strokeLinecap='round' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.4997 1.80981V24.0451V1.80981Z'
            fill={props.fill || '#D8D8D8'}
        />
        <path d='M10.4997 1.80981V24.0451' stroke='#424147' strokeWidth='3' strokeLinecap='round' />
    </svg>
);

export default forwardRef(Icon);
