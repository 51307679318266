import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 66}
        height={props.height || 76}
        viewBox='0 0 66 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.5 72.4888C55.5923 72.4888 63 65.0587 63 55.8476C63 46.6364 55.5923 39.2063 46.5 39.2063C37.4077 39.2063 30 46.6364 30 55.8476C30 65.0587 37.4077 72.4888 46.5 72.4888ZM46.5 75.4888C57.2696 75.4888 66 66.6951 66 55.8476C66 45 57.2696 36.2063 46.5 36.2063C35.7304 36.2063 27 45 27 55.8476C27 66.6951 35.7304 75.4888 46.5 75.4888Z'
            fill='#8564E7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46 46.7422C46.8284 46.7422 47.5 47.4138 47.5 48.2422V65.2422C47.5 66.0706 46.8284 66.7422 46 66.7422C45.1716 66.7422 44.5 66.0706 44.5 65.2422V48.2422C44.5 47.4138 45.1716 46.7422 46 46.7422Z'
            fill='#8564E7'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M39.3837 53.2441C38.8304 52.6276 38.8816 51.6792 39.4981 51.1259L45.0687 46.1259C45.6441 45.6094 46.5179 45.615 47.0867 46.1388L52.5161 51.1388C53.1255 51.7 53.1646 52.6489 52.6034 53.2583C52.0422 53.8677 51.0933 53.9068 50.4839 53.3456L46.0577 49.2694L41.502 53.3585C40.8855 53.9118 39.9371 53.8606 39.3837 53.2441Z'
            fill='#8564E7'
        />
        <path
            d='M31.5 12.1458C31.5 17.5922 27.2097 21.9395 22 21.9395C16.7903 21.9395 12.5 17.5922 12.5 12.1458C12.5 6.69934 16.7903 2.35205 22 2.35205C27.2097 2.35205 31.5 6.69934 31.5 12.1458Z'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            d='M39 39.3708C36.0696 31.247 29.0652 22.4575 21.3507 22.4575C12.1436 22.4575 4.13558 36.4572 2.67006 43.9428C1.20453 51.4284 0.242708 60.758 21.3507 60.758C24.1367 60.758 26.5415 60.6212 28.6139 60.3632'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
    </svg>
);

export default forwardRef(Icon);
