import React, { useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import SLUGS from 'resources/slugs';
import useClientSettings from 'hooks/useClientSettings';
import useUserDeclaredDetails from 'hooks/useUserOrganisationDetails';
import useUserActivity from 'hooks/useUserActivity';
import LoadingComponent from 'components/loading';

const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '90px 70px',
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: '100%',
        maxWidth: 1072
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center'
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        container: {
            padding: '70px 30px'
        },
        '#initial_product_modal_main_window': {
            width: '80%'
        }
    },
    productsRow: {
        columnGap: 33,
        marginTop: 22
    },
    productContainer: {
        cursor: 'pointer',
        padding: '0px 19px 16px 19px',
        boxSizing: 'border-box',
        width: 312,
        height: 315,
        border: `2px solid ${theme.color.darkOrange}`,
        borderRadius: 20,
        '&:hover': {
            border: `2px solid ${theme.color.purpleHeaders}`,
            background: '#F9F9F9'
        }
    },
    productImageContainer: {
        width: 163,
        height: 163,
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    productTitle: {
        fontWeight: 500,
        fontSize: 28,
        color: theme.color.dataroBlack,
        marginTop: 12
    },
    productDescription: {
        fontSize: 18,
        lineHeight: '20px',
        color: theme.color.dataroBlack,
        marginTop: 12
    }
}));

function InitialProductModal() {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const settingsParams = [
        'declared_country',
        'declared_organisation_name',
        'declared_crm_type',
        'about_us_source',
        'job_title',
        'estimated_donors_count',
        'initial_product_preference'
    ];
    const {
        data: {
            declared_country,
            declared_organisation_name,
            declared_crm_type,
            about_us_source,
            job_title,
            estimated_donors_count,
            initial_product_preference
        } = {},
        isLoading: isLoadingClientSettings
    } = useClientSettings({
        params: settingsParams.join(',')
    });
    const clientSettings = {
        declared_country: declared_country,
        declared_organisation_name: declared_organisation_name,
        declared_crm_type: declared_crm_type,
        about_us_source: about_us_source,
        job_title: job_title,
        estimated_donors_count: estimated_donors_count,
        initial_product_preference: initial_product_preference
    };
    const { isLoadingUpdatingDetails, addToUserOrgDetails } = useUserDeclaredDetails();
    const [isOpen, setIsOpen] = useState(true);
    const { push } = useHistory();
    const { search } = useLocation();
    const queryParsed = queryString.parse(search);
    const isLoading = isLoadingClientSettings || isLoadingUpdatingDetails;

    const { addUserActivity } = useUserActivity();

    useEffect(() => {
        try {
            addUserActivity({
                event_name: 'onboarding_tour',
                event_payload: {
                    modal_name: 'select_product',
                    modal_component: 'InitialProductModal'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onProductClick(product) {
        const payload = {
            declared_country: clientSettings.declared_country,
            declared_organisation_name: clientSettings.declared_organisation_name,
            declared_crm_type: clientSettings.declared_crm_type,
            about_us_source: clientSettings.about_us_source,
            job_title: clientSettings.job_title,
            estimated_donors_count: clientSettings.estimated_donors_count,
            initial_product_preference: product
        };
        queryParsed.showOnboardingTour = product;
        delete queryParsed.showSelectProduct;
        const queryStringified = queryString.stringify(queryParsed);
        if (!clientSettings.initial_product_preference) {
            addToUserOrgDetails(payload);
        }
        localStorage.setItem('dataro_initial_product_preference', product);
        push(`${SLUGS.home}?${queryStringified}`);
    }

    function onClickClose() {
        if (!clientSettings.initial_product_preference) {
            return;
        }
        if (queryParsed.showSelectProduct) {
            delete queryParsed.showSelectProduct;
            push({
                search: queryString.stringify(queryParsed)
            });
        }
        setIsOpen(false);
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClickClose}
                style={{
                    overlay: {
                        background: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        padding: 0,
                        // maxWidth: 968,
                        maxHeight: 880,
                        borderRadius: 10,
                        position: 'relative',
                        top: '',
                        bottom: '',
                        left: '',
                        right: '',
                        // width: '100%',
                        marginTop: 30,
                        marginBottom: 30
                    }
                }}
                id='initial_product_modal_main_window'
                ariaHideApp={false}
            >
                <Row style={{ height: '100%' }}>
                    <Column className={classes.container}>
                        <FormSection onProductClick={onProductClick} />
                    </Column>
                </Row>
            </Modal>
            <LoadingComponent loading={isLoading} />
        </>
    );
}

function FormSection({ onProductClick }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });

    return (
        <Column vertical='center' horizontal='center' style={{ width: '100%' }}>
            <h2 className={classes.title} id='fi_modal_tour'>
                Which Dataro product are you most interested in?
            </h2>
            <p>We will use this information to tailor your experience</p>
            <Row className={classes.productsRow}>
                <ProductCard
                    title='Dataro Predict'
                    type='dataro_predict'
                    description='Target the right donors at the right time with propensity scores for each of your fundraising programs'
                    onClick={() => onProductClick('dataro_predict')}
                />
                <ProductCard
                    title='Fundraising Intelligence'
                    type='fundraising_intelligence'
                    description='Live dashboards and benchmarks to view and compare your latest fundraising results'
                    onClick={() => onProductClick('fundraising_intelligence')}
                />
                <ProductCard
                    title='AI Assist'
                    type='ai_assist'
                    description='Create first drafts and tailor existing content based on the latest GPT technology'
                    onClick={() => onProductClick('ai_assist')}
                />
            </Row>
            <p
                style={{
                    marginTop: 40,
                    marginBottom: -40,
                    color: theme.color.purpleHeaders,
                    fontWeight: 500
                }}
            >
                Once you have finished the tour, you can restart it by clicking on the "Start tour
                now" button on the Help and Support page.
            </p>
        </Column>
    );
}

function ProductCard({ title, description, onClick, type }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const imagesURL = {
        dataro_predict: `${process.env.PUBLIC_URL}/images/product_dataro_predict.png`,
        fundraising_intelligence: `${process.env.PUBLIC_URL}/images/product_fundraising_intelligence.png`,
        ai_assist: `${process.env.PUBLIC_URL}/images/product_ai_assist.png`
    };
    return (
        <Column
            horizontal='center'
            id={`product_${type}`}
            className={classes.productContainer}
            onClick={onClick}
        >
            <Row className={classes.productImageContainer}>
                <img alt={`product ${type}`} src={imagesURL[type]} />
            </Row>
            <h3 className={classes.productTitle}>{title}</h3>
            <p className={classes.productDescription}>{description}</p>
        </Column>
    );
}

export default InitialProductModal;
