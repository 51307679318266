import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='10 10 35 35'
        fill={props.color || '#424147'}
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g
            transform='translate(13, 45) scale(0.065,-0.065)'
            fill={props.color || '#424147'}
            stroke='none'
        >
            <path
                d='M52 457 c-44 -46 -16 -107 47 -107 31 0 42 -5 56 -26 14 -21 15 -31
5 -54 -6 -16 -10 -37 -8 -47 5 -25 -58 -72 -98 -73 -51 0 -72 -41 -38 -74 26
-27 54 -20 69 17 21 51 79 85 135 80 38 -4 49 -10 68 -38 15 -24 20 -43 16
-64 -7 -33 23 -71 56 -71 26 0 60 34 60 60 0 27 -35 60 -64 60 -16 0 -31 12
-49 40 -16 22 -23 42 -17 45 6 3 10 15 10 26 0 21 29 26 47 8 15 -15 54 -7 67
13 24 39 -37 76 -72 45 -28 -23 -52 -28 -59 -12 -8 21 44 93 70 97 13 2 25 14
31 31 8 23 6 31 -12 49 -18 18 -26 20 -49 12 -25 -8 -28 -15 -28 -55 -1 -56
-35 -109 -70 -109 -33 0 -65 48 -65 98 0 67 -64 96 -108 49z m73 -42 c0 -27
-3 -30 -30 -30 -21 0 -31 5 -33 18 -5 25 17 49 42 45 16 -2 21 -10 21 -33z
m233 12 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m-94
-169 c14 -23 -3 -52 -33 -56 -42 -6 -67 38 -39 66 17 17 58 11 72 -10z m126
12 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m-330 -171 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m325 -39
c0 -20 -5 -25 -25 -25 -28 0 -41 32 -18 46 23 15 43 5 43 -21z'
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
