import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory, matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { Column, Row } from 'components/simpleFlexbox';
import useClientSettings from 'hooks/useClientSettings';
import useUserActivity from 'hooks/useUserActivity';
import useExternalScripts from 'hooks/useExternalScripts';
import useDelighted from 'hooks/useDelighted';
import { GlobalContextProvider } from 'hooks/useGlobalContext';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import SidebarContext from 'components/sidebar';
import OnboardingBanner from 'components/onboarding/OnboardingBanner';
import UpgradeAccountPopup from './onboarding/upgradeAccount/UpgradeAccountPopup';
import SelectOrganization from './settings/SelectOrganization';
import PrivateRoutes from './PrivateRoutes';
import MainModal from './MainModal';
import Frill from 'components/frill/index';
import useProducts from 'hooks/useProducts';

const SentryRoute = Sentry.withSentryRouting(Route);

const AccountSetup = lazy(() => import('./onboarding/accountSetup/AccountSetup'));
const AccountSetupSales = lazy(() => import('./onboarding/accountSetup/sales/AccountSetup'));
const AccountSetupDemo = lazy(() => import('./onboarding/accountSetup/demo/AccountSetup'));
const Page404Component = lazy(() => import('./errors/Page404Component'));

function PrivateSection() {
    const [currentPath, setCurrentPath] = useState();
    const { pathname } = useLocation();
    const { addUserActivity } = useUserActivity();
    const {
        data: {
            client_name,
            features,
            onboarding_demo_completed,
            first_name,
            last_name,
            email,
            added_ts,
            last_NPS
        } = {},
        isLoading: isLoadingClient
    } = useClientSettings({
        params: 'client_name,features,onboarding_demo_completed,first_name,last_name,email,added_ts,last_NPS'
    });
    const { isSalesClient, isLoadingProducts, showOnboardingBanner } = useProducts();
    const { sendSurvey } = useDelighted();

    const isLoading = isLoadingClient || isLoadingProducts;

    useExternalScripts({
        id: 'play-vidyard-video',
        url: '//play.vidyard.com/embed/v4.js',
        type: 'text/javascript',
        include: !onboarding_demo_completed
    });
    useExternalScripts({
        id: 'fill-widget',
        url: 'https://widget.frill.co/v2/widget.js',
        type: 'text/javascript',
        include: process.env.REACT_APP_FRILL_ID && process.env.REACT_APP_FRILL_ID.length > 0
    });

    useEffect(() => {
        if (pathname !== currentPath) {
            setCurrentPath(pathname);
            try {
                addUserActivity({
                    event_name: 'page_visited',
                    event_payload: {
                        from: currentPath,
                        to: pathname
                    }
                });
            } catch (ex) {}
        }
    }, [pathname, currentPath, addUserActivity]);

    if (isLoading && !client_name) {
        return <LoadingComponent loading={isLoading} />;
    }

    if (!isLoading) {
        sendSurvey(client_name, email, `${first_name} ${last_name}`, added_ts, last_NPS);
    }

    if (pathname.indexOf('/account_setup/demo') === 0) {
        return (
            <Suspense fallback={<LoadingComponent loading />}>
                <Switch>
                    <SentryRoute path={SLUGS.accountSetupDemo} component={AccountSetupDemo} />
                </Switch>
            </Suspense>
        );
    }

    if (pathname.indexOf('/account_setup/sales') === 0 && isSalesClient) {
        return (
            <Suspense fallback={<LoadingComponent loading />}>
                <Switch>
                    <SentryRoute path={SLUGS.accountSetupSales} component={AccountSetupSales} />
                </Switch>
            </Suspense>
        );
    }

    if (pathname.indexOf('/account_setup') === 0) {
        return (
            <Suspense fallback={<LoadingComponent loading />}>
                <Switch>
                    <SentryRoute path={SLUGS.accountSetup} component={AccountSetup} />
                </Switch>
            </Suspense>
        );
    }

    return (
        <GlobalContextProvider>
            <Frill />
            <MainModal />
            <SelectOrganization />
            <Column id='main_container'>
                <LoadingComponent loading={isLoading} />
                <Row>
                    <SidebarContext />
                    <Column flexGrow={1}>
                        {showOnboardingBanner && <OnboardingBanner />}

                        <PrivateRoutes
                            features={features}
                            onboarding_demo_completed={onboarding_demo_completed}
                        />
                    </Column>
                </Row>
                <UpgradeAccountPopup />
            </Column>
        </GlobalContextProvider>
    );
}

function SentrySection(props) {
    const history = useHistory();
    const { data: { client_name, email } = {} } = useClientSettings({
        params: 'client_name,email'
    });

    useEffect(() => {
        if (
            process.env.REACT_APP_SENTRY_DSN &&
            process.env.REACT_APP_SENTRY_DSN.trim().length > 2
        ) {
            let tracesSampleRate = 0;
            try {
                tracesSampleRate = +(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 0);
                if (Number.isNaN(tracesSampleRate)) {
                    tracesSampleRate = 0;
                }
            } catch (ex) {
                tracesSampleRate = 0;
            }
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                beforeSend: (event, hint) => {
                    if (hint && hint.originalException) {
                        const exception = hint.originalException;
                        if (exception.message.includes('Refresh Token has expired')) {
                            return null;
                        }
                    }
                    if (
                        event.user &&
                        ((event.user.email && event.user.email.indexOf('@dataro') > 0) ||
                            (event.user.id && event.user.id.indexOf('@dataro') > 0))
                    ) {
                        return null;
                    }
                    return event;
                },
                denyUrls: [/.*googlesyndication\.com.*/],
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
                            history,
                            Object.keys(SLUGS).map((key) => SLUGS[key]),
                            matchPath
                        )
                    }),
                    new Sentry.Integrations.Breadcrumbs({
                        dom: true,
                        fetch: true,
                        history: true,
                        xhr: true
                    }),
                    new Sentry.Integrations.GlobalHandlers({
                        onerror: true,
                        onunhandledrejection: true
                    })
                    // new Sentry.Replay()
                ],

                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate
                // replaysSessionSampleRate: 0.1,
                // replaysOnErrorSampleRate: 1
            });

            Sentry.setUser({ email, id: email, username: client_name });
        }
        if (process.env.REACT_APP_ADD_HUBSPOT === 'true') {
            try {
                const _hsq = (window._hsq = window._hsq || []);
                _hsq.push([
                    'identify',
                    {
                        email,
                        client_name
                    }
                ]);
            } catch (ex) {}
        }
    }, [email, client_name, history]);

    return (
        <Sentry.ErrorBoundary fallback={Page404Component}>
            <PrivateSection {...props} />
        </Sentry.ErrorBoundary>
    );
}

export default SentrySection;
