import React, { forwardRef } from 'react';
import { useTheme } from 'react-jss';
import { IconError, IconNotification, IconLock, IconTickRounded } from 'assets/icons';
import './tooltip.css';

function TooltipComponent(
    {
        className: classNameProp,
        content,
        message,
        onClick,
        type,
        size,
        tooltipClassName,
        classNameForDisabled,
        iconStyleName,
        isContentWithIcon = false
    },
    ref
) {
    const theme = useTheme();
    if (!message) {
        return null;
    }
    const className = ['bapple-tooltip-container', classNameProp ? classNameProp : ''].join(' ');
    let Icon;
    let color;
    switch (type) {
        case 'error':
            Icon = IconError;
            color = theme.color.error;
            break;
        case 'info':
            Icon = IconNotification;
            color = theme.color.sidebarIconColor;
            break;
        case 'lock':
            Icon = IconLock;
            color = theme.color.sidebarIconColor;
            break;
        case 'success':
            Icon = IconTickRounded;
            color = theme.color.statsGreen;
            break;
        default:
            Icon = IconNotification;
            color = theme.color.sidebarIconColor;
    }

    if (content && isContentWithIcon) {
        return (
            <div className={className} onClick={onClick} ref={ref}>
                {content}
                <span className={iconStyleName}>
                    <Icon width={size} height={size} />
                </span>
                <TooltipMessage
                    color={color}
                    message={message}
                    className={tooltipClassName}
                    classNameForDisabled={classNameForDisabled}
                />
            </div>
        );
    }

    if (content) {
        return (
            <div className={className} onClick={onClick} ref={ref}>
                {content}
                <TooltipMessage
                    color={color}
                    message={message}
                    className={tooltipClassName}
                    classNameForDisabled={classNameForDisabled}
                />
            </div>
        );
    }

    return (
        <div className={className} onClick={onClick} ref={ref}>
            <Icon width={size} height={size} />
            <TooltipMessage
                color={color}
                message={message}
                className={tooltipClassName}
                classNameForDisabled={classNameForDisabled}
            />
        </div>
    );
}

function TooltipMessage({ color, message, className: classNameProp, classNameForDisabled }) {
    const className = [
        'bapple-tooltip',
        classNameProp ? classNameProp : '',
        classNameForDisabled
    ].join(' ');
    return (
        <div className={className}>
            <div className={'bapple-tooltip-content p-[7px]'} style={{ backgroundColor: color }}>
                <span style={{ color: 'white' }}>{message}</span>
            </div>
            <div className='bapple-tooltip-arrow' style={{ borderTopColor: color }}></div>
        </div>
    );
}

export default forwardRef(TooltipComponent);
