import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import useClientSettings from 'hooks/useClientSettings';
import SLUGS from 'resources/slugs';
import AIAssistWelcomeComponent from './AIAssistWelcomeComponent';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';

const useStyles = createUseStyles((theme) => ({
    leftBar: {
        width: 100,
        background: '#FFEBE8',
        borderRadius: '10px 0px 0px 10px'
    },
    container: {
        padding: '120px 90px 60px 90px',
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: null
    },
    button: {
        marginTop: 40
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        container: {
            padding: '70px 80px'
        },
        leftBar: {
            width: 75
        }
    }
}));

function WelcomeModal() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { UpdateClientSettings, isLoading } = useClientSettings();

    async function onLetsBeginClick() {
        UpdateClientSettings({
            onboarding_demo_completed: true
        });
        return setTimeout(() => {
            window.location = SLUGS.aiAssistSettings;
        }, 1500);
    }

    return (
        <Modal
            isOpen
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 968,
                    maxHeight: 880,
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            id='fi_free_modal_main_window'
            ariaHideApp={false}
        >
            <Row>
                <div className={classes.leftBar}></div>
                <Column horizontal='center' className={classes.container}>
                    <AIAssistWelcomeComponent />
                    <ButtonComponent
                        id='you_are_too_quick_modal_next'
                        width={260}
                        className={classes.button}
                        onClick={onLetsBeginClick}
                    >
                        Let's begin!
                    </ButtonComponent>
                </Column>
            </Row>
            <LoadingComponent loading={isLoading} />
        </Modal>
    );
}

export default WelcomeModal;
