import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.7665 0.57477C18.7665 0.257333 19.0299 0 19.3548 0H29.0322C29.357 0 29.6204 0.257333 29.6204 0.57477V6.06275C29.6204 6.38018 29.357 6.63752 29.0322 6.63752H19.9672C19.9591 6.63752 19.951 6.63736 19.943 6.63704V9.08504H24.7059C27.6297 9.08504 30 11.401 30 14.258V23.0649C30 23.6998 29.4733 24.2145 28.8235 24.2145H17.04V28.8504H18.3872C18.7121 28.8504 18.9755 29.1077 18.9755 29.4252C18.9755 29.7425 18.7122 29.9998 18.3874 29.9999V30H11.6132V29.9999C11.2883 29.9999 11.025 29.7426 11.025 29.4252C11.025 29.1077 11.2883 28.8504 11.6132 28.8504H12.9604V24.2145H12.3719H1.17647C1.09525 24.2145 1.01596 24.2064 0.939371 24.1911C0.403273 24.0839 0 23.6204 0 23.0649V14.258C0 11.401 2.37026 9.08504 5.29412 9.08504H8.25428H18.7665V0.57477ZM14.1369 28.8505H15.8635V24.2145H14.1369V28.8505ZM18.7665 15.7042V10.2346H11.5821C12.7815 11.1829 13.5484 12.6329 13.5484 14.258V23.0649H28.8235V14.258C28.8235 12.0359 26.98 10.2346 24.7059 10.2346H19.943V15.7042C19.943 16.0216 19.6796 16.279 19.3548 16.279C19.0299 16.279 18.7665 16.0216 18.7665 15.7042ZM19.943 5.48846C19.951 5.48814 19.9591 5.48798 19.9672 5.48798H28.4439V1.14954H19.943V5.48846ZM8.25428 10.2346H5.29412C3.02 10.2346 1.17647 12.0359 1.17647 14.258L1.17647 23.0649L12.3719 23.0649V14.258C12.3719 12.0359 10.5284 10.2346 8.25428 10.2346Z'
            fill={props.fill || '#BFBFBF'}
        />
    </svg>
);

export default forwardRef(Icon);
