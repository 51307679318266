import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width='20'
        height='23'
        viewBox='0 0 20 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M2 22C2 22 3.03125 14 10 14C16.9688 14 18 22 18 22'
            stroke='#979797'
            strokeWidth='2.5'
        />
        <circle cx='10.1109' cy='6.4' r='4.4' stroke='#979797' strokeWidth='2.5' />
    </svg>
);

export default forwardRef(Icon);
