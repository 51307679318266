const SLUGS = {
    // private
    home: '/home',
    campaignResults: '/campaigns',
    campaignInsights: '/campaign_insights',
    campaignResultDetails: '/campaigns/:parentId',
    campaignSize: '/campaign_size',
    campaignSizeRecommendations: '/campaign_size_recommendations',
    createCampaignBase: '/create_campaign/1',
    createCampaign: '/create_campaign/:step?',
    predict: '/predict',
    predictions: '/predictions',
    performance: '/performance',
    settingsBase: '/settings',
    settings: '/settings/:section?',
    support: '/support',
    accountSetup: '/account_setup/:step?',
    campaignTagging: '/data_hub/campaign_tagging',
    campaignBulkTagging: '/data_hub/campaign_tagging/bulk',
    accountSetupDemo: '/account_setup/demo',
    fundraisingIntelligence: '/fundraising_intelligence',
    baseFundraisingIntelligenceSettings: '/fundraising_intelligence/settings',
    fundraisingIntelligenceSettings: '/fundraising_intelligence/settings/:section?',
    aiAnalyst: '/ai_analyst',
    fundraisingIntelligenceDashboard: '/fundraising_intelligence/dashboard',
    fundraisingIntelligenceDashboardElement: '/fundraising_intelligence/dashboard/:dashboardId',
    fundraisingIntelligenceReport: '/fundraising_intelligence/:reportId',
    fundraisingIntelligenceRegularGiverReport: '/fundraising_intelligence/regular_giver/:reportId',
    fundraisingIntelligenceAppealGiverReport: '/fundraising_intelligence/appeal/:reportId',
    fundraisingIntelligenceSingleGiverReport: '/fundraising_intelligence/single_giver/:reportId',
    fundraisingIntelligenceManagementReport: '/fundraising_intelligence/management/:reportId',
    fundraisingIntelligenceMidLevelGiverReport: '/fundraising_intelligence/mid_giving/:reportId',
    fundraisingIntelligenceMajorGiverReport: '/fundraising_intelligence/major_giving/:reportId',
    fundraisingIntelligencePeerToPeerReport: '/fundraising_intelligence/peer-to-peer/:reportId',
    fundraisingIntelligenceGlobalReport: '/fundraising_intelligence/global/:reportId',
    fundraisingInsights: '/fundraising_insights',
    accountSetupSales: '/account_setup/sales',
    donorList: '/donor_list',
    connections: '/connections',
    dataHub: '/data_hub',
    dataInspector: '/data_hub/data_inspector',
    dataRecordMapping: '/data_hub/data_mapping',
    baseDonorView: '/donors/',
    donorView: '/donors/:donorId?/:filters?',
    aiAssist: '/ai_assist/:generator_type?',
    aiAssistSettings: '/ai_assist/settings',
    facebookTerms: '/facebook/terms',
    googleAdsTerms: '/google_ads/terms',
    mailchimpTerms: '/mailchimp/terms',
    virtuousTerms: '/virtuous/terms',
    civiCRMTerms: '/civicrm/terms',
    savedDonorLists: '/saved_donor_lists',
    donorsAndSegments: '/donors_and_segments',
    donorsAndSegmentsMidValue: '/donors_and_segments/mid_value',
    donorsAndSegmentsMajorDonor: '/donors_and_segments/major_donor',
    donorsAndSegmentsSmartAudience: '/donors_and_segments/smart_audiences',

    // dragAndDrop
    dragAndDrop: '/dragndrop',
    dragAndDropDataset: '/dragndrop/:datasetId',
    dragAndDropMatchFields: '/dragndrop/:datasetId/field_mapping/:table_name',
    dragAndDropFileList: '/dragndrop/:datasetId/file_list/:table_name',
    dragAndDropMapData: '/dragndrop/:datasetId/file_data_mapping/:table_name',

    // public
    login: '/login/:message?',
    signup: '/signup/:type?/:source?',
    signupFree: '/signup-free/:source?',
    forgotPassword: '/forgot_password',
    newPassword: '/new_password',
    setPassword: '/set_password',

    //error
    error404: '/error-404'
};

export default SLUGS;
