import {
    convertToRgDisplayDM,
    convertToRgDisplayTM,
    defaultFormDisplay,
    dmAppealOverFiveHundredDisplay,
    dmAppealPastFormDisplay,
    dmAppealROIFormDisplay,
    giwConfirmerDisplay,
    majorGiverDisplay,
    midValueGiverDisplay,
    rgChurnDisplay,
    rgReactivationDisplay,
    rgUpgradeDisplayDM,
    rgUpgradeDisplayTM,
    twentyFourMLapsedDisplay
} from '../constants/campaignSizeRecConstants';

// TODO is there a global campaign propensity?
const getFormDisplay = (campaignType, variant, appealType = '') => {
    switch (campaignType) {
        case '24M Lapsed':
            return twentyFourMLapsedDisplay;
        case 'Convert to RG':
            if (variant === 'tmAppeal') return convertToRgDisplayTM;
            return convertToRgDisplayDM;
        case 'DM Appeal >$500':
            return dmAppealOverFiveHundredDisplay;
        case 'DM Appeal':
            if (variant === 'pastCampaignData') return dmAppealPastFormDisplay;
            return dmAppealROIFormDisplay;
        case 'GiW Confirmer':
            return giwConfirmerDisplay;
        case 'Major Giver':
            return majorGiverDisplay;
        case 'Mid Value Giver':
            return midValueGiverDisplay;
        case 'RG Churn':
            return rgChurnDisplay;
        case 'RG Reactivation':
            return rgReactivationDisplay;
        case 'RG Upgrade':
            if (appealType === 'tmCampaign') return rgUpgradeDisplayTM;
            if (appealType === 'dmCampaign') return rgUpgradeDisplayDM;
            return defaultFormDisplay;
        default:
            break;
    }
    return defaultFormDisplay;
};

const titleToYVAR = {
    'DM Appeal': 'y_meta_dm_appeal',
    'RG Upgrade': 'y_meta_upgrade_rg'
};

const convertDisplayToYVar = (display) => {
    if (Object.keys(titleToYVAR).includes(display)) {
        return titleToYVAR[display];
    }
    return null;
};

export { getFormDisplay, convertDisplayToYVar };
