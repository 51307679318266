import React, { forwardRef } from 'react';
const Icon = (props, ref) => (
    <svg
        id={props.id}
        width={props.width || '18'}
        height={props.height || '18'}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g>
            <rect y='12.8843' width='11.3685' height='7.20005' fill={props.fill || '#11151D'} />
            <rect y='12.8843' width='11.3685' height='7.20005' fill={props.fill || '#11151D'} />
            <rect y='12.8843' width='11.3685' height='7.20005' fill={props.fill || '#11151D'} />
            <rect y='12.8843' width='11.3685' height='7.20005' fill={props.fill || '#11151D'} />
            <rect y='5.68433' width='11.3685' height='6.44215' fill={props.fill || '#11151D'} />
            <rect y='5.68433' width='11.3685' height='6.44215' fill={props.fill || '#11151D'} />
            <rect y='5.68433' width='11.3685' height='6.44215' fill={props.fill || '#11151D'} />
            <rect y='5.68433' width='11.3685' height='6.44215' fill={props.fill || '#11151D'} />
            <rect width='11.3685' height='4.92635' fill={props.fill || '#11151D'} />
            <rect width='11.3685' height='4.92635' fill={props.fill || '#11151D'} />
            <rect width='11.3685' height='4.92635' fill={props.fill || '#11151D'} />
            <rect width='11.3685' height='4.92635' fill={props.fill || '#11151D'} />
        </g>
    </svg>
);
export default forwardRef(Icon);
