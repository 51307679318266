import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 22 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M20.9457 10.1213C21.1557 9.104 20.9845 8.12062 20.5419 7.29172C20.6748 6.21952 20.3612 5.11009 19.7629 4.2824C19.7201 1.77096 18.1537 0 14.6895 0C14.375 0 14.0156 0.000449219 13.6913 0.000449219C9.03806 0.000449219 7.59166 1.79688 5.75 1.79688H5.26282C5.00946 1.57339 4.67695 1.4375 4.3125 1.4375H1.4375C0.643596 1.4375 0 2.0811 0 2.875V13.6562C0 14.4502 0.643596 15.0938 1.4375 15.0938H4.3125C4.84446 15.0938 5.30864 14.8045 5.5572 14.375H5.87398C6.73406 15.1366 7.94097 17.0996 8.96281 18.1215C9.57676 18.7354 9.4189 23 12.1864 23C14.773 23 16.4661 21.5654 16.4661 18.2953C16.4661 17.4683 16.2896 16.7801 16.0686 16.2047H17.7073C19.8906 16.2047 21.5625 14.3375 21.5625 12.3603C21.5625 11.5 21.3397 10.7884 20.9457 10.1213ZM2.875 13.2969C2.27956 13.2969 1.79688 12.8142 1.79688 12.2188C1.79688 11.6233 2.27956 11.1406 2.875 11.1406C3.47044 11.1406 3.95312 11.6233 3.95312 12.2188C3.95312 12.8142 3.47044 13.2969 2.875 13.2969ZM17.7073 14.0484H13.0359C13.0359 15.7474 14.3099 16.5357 14.3099 18.2953C14.3099 19.3622 14.3099 20.8438 12.1864 20.8438C11.3369 19.9943 11.7614 17.8708 10.4875 16.5968C9.29434 15.4033 7.51453 12.2188 6.24055 12.2188H5.75V3.85564C8.15831 3.85564 10.2422 2.1567 13.4604 2.1567H15.1593C16.7546 2.1567 17.8915 2.92576 17.5456 5.11705C18.2284 5.48361 18.736 6.754 18.1718 7.7032C19.1413 8.61889 19.0118 9.99714 18.4058 10.651C18.8304 10.651 19.4103 11.5004 19.4062 12.3495C19.4022 13.1989 18.6556 14.0484 17.7073 14.0484Z'
            fill='#E1535C'
        />
    </svg>
);

export default forwardRef(Icon);
