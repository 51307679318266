import React, { useState, useEffect } from 'react';
import { any, func, object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { useSidebar } from 'hooks/useSidebar';
import { IconAlert } from 'assets/icons';
import MenuSubItemComponent from './MenuSubItemComponent';

function MenuItemComponent({
    icon: Icon,
    id,
    onClick,
    parentPath = '',
    fullPath,
    items,
    title,
    MenuHeader = undefined,
    onlyHeader = false
}) {
    const theme = useTheme();
    const hasItems = items && items.length > 0;
    const hasHeader = MenuHeader !== undefined;
    const showAlertIcon = !!(items || []).find((item) => item.isAlertIcon);
    const [showMenuItems, setShowMenuItems] = useState(false);

    const path = !parentPath ? `/${id}` : `/${parentPath}/${id}`;
    const { isActive, onItemClick, setCurrentPath } = useSidebar({
        path
    });

    const [highlightOnHover, setHighlightOnHover] = useState(false);

    const classNameIconInnerContainer =
        isActive || highlightOnHover
            ? 'flex flex-row flex-grow justify-start bg-white pt-[12px] pb-[12px] pl-[20px] rounded-tl-[10px] rounded-bl-[10px] '
            : 'flex flex-row  flex-grow justify-center pt-[12px] pb-[12px]';
    const iconColor = theme.color.sidebarIconColor;
    const leftBar =
        isActive || highlightOnHover ? 'w-[15px] bg-keyline-grey' : 'w-[0px] bg-keyline-grey';

    const subItemContainerStyles = `bg-white w-[220px] left-[65px] 
                ${
                    hasHeader
                        ? 'top-[-77px]'
                        : onlyHeader
                        ? 'top-[calc(0%-15px)]'
                        : 'top-[calc(0%-50px)]'
                } 
                ${hasHeader ? '' : hasItems ? 'p-[14px_18px_14px_14px]' : 'p-[14px]'} 
                absolute z-[11]`;

    function onTitleClicked(e) {
        const element = document.querySelector(`#menu_${id}`);
        if (element && element.getAttribute('class').includes('menu-disabled')) {
            return;
        }
        onClick && onClick(e);
    }

    function onItemClicked(e) {
        const element = document.querySelector(`#menu_${id}`);
        if (element && element.getAttribute('class').includes('menu-disabled')) {
            return;
        }
        if (onClick) {
            onClick(e);
            onItemClick();
        }
    }

    function onSubItemClicked(item) {
        const newPath = !item.parentPath ? `/${item.id}` : `/${item.parentPath}/${item.id}`;
        const element = document.querySelector(`#submenu_${item.id}`);
        if (element && element.getAttribute('class').includes('submenu-disabled')) {
            return;
        }
        setCurrentPath(newPath);
        item.onClick && item.onClick();
    }

    function onMouseEnterLocalEvent(currentHoverTitle) {
        if (currentHoverTitle !== '') {
            setShowMenuItems(true);
            setHighlightOnHover(true);
        }
    }

    function onMouseLeaveLocalEvent(currentHoverTitle) {
        if (currentHoverTitle !== '') {
            setShowMenuItems(false);
            setHighlightOnHover(false);
        }
    }

    useEffect(() => {
        // the following functions are used by e2e tests
        function closeMenu() {
            console.log('invoking open menu', title);
            onMouseLeaveLocalEvent(title);
        }
        function openMenu() {
            console.log('invoking open menu', id, title);
            onMouseEnterLocalEvent(title);
        }

        // attach the function to the document
        window[`closeMenu_${id}`] = closeMenu;
        window[`openMenu_${id}`] = openMenu;

        return () => {
            delete window.closeMenu;
            delete window.openMenu;
        };
    }, [id, title]);

    return (
        <>
            {showMenuItems && (
                <div
                    id={`sidebar_popup_container_${id}`}
                    className={
                        'transition-all duration-200 ease-in-out bg-white min-h-screen absolute z-[10] left-[120px] border-r-solid border-r-[1px] border-r-keyline-grey w-[245px]'
                    }
                ></div>
            )}
            <div
                onMouseEnter={() => onMouseEnterLocalEvent(title)}
                onMouseLeave={() => onMouseLeaveLocalEvent(title)}
            >
                <div
                    key={id}
                    id={`menu_${id}`}
                    className='flex flex-col w-[100px] mt-[10px] mb-[10px]'
                >
                    <div />
                    <div className='flex flex-row transition-all duration-200 ease-in-out'>
                        <div className={leftBar}></div>
                        <div
                            className='flex flex-row flex-grow bg-keyline-grey justify-center'
                            id={`menu_${id}_icon_container`}
                        >
                            <div
                                className={[classNameIconInnerContainer, 'menuitem-container'].join(
                                    ' '
                                )}
                            >
                                <div className='flex flex-row items-center pointer h-[25px] relative'>
                                    <div onClick={onItemClicked} id={`menu_${id}_icon`}>
                                        {fullPath ? (
                                            <Link to={fullPath}>
                                                <>
                                                    <Icon fill={iconColor} />
                                                    <AlertIcon showAlertIcon={showAlertIcon} />
                                                </>
                                            </Link>
                                        ) : (
                                            <>
                                                <Icon fill={iconColor} />
                                                <AlertIcon showAlertIcon={showAlertIcon} />
                                            </>
                                        )}
                                    </div>
                                    {showMenuItems && title && (
                                        <div className='h-screen bg-transparent'>
                                            <div
                                                className={subItemContainerStyles}
                                                id={`menu_${id}_subitems`}
                                            >
                                                {hasHeader && <MenuHeader />}
                                                <div
                                                    className={`flex flex-col p-[${
                                                        hasHeader ? '14px' : '0px'
                                                    }] min-w-[${hasItems ? '120px' : 'none'}]`}
                                                >
                                                    {fullPath ? (
                                                        <Link to={fullPath}>
                                                            <span
                                                                onClick={onTitleClicked}
                                                                className='text-soft-black w-full text-[22px] text-left font-[420] leading-[31px] whitespace-nowrap tracking-[-0.35px]'
                                                            >
                                                                {title}
                                                            </span>
                                                        </Link>
                                                    ) : (
                                                        <span
                                                            onClick={onTitleClicked}
                                                            className='text-soft-black w-full text-[22px] text-left font-[420] leading-[31px] whitespace-nowrap tracking-[-0.35px]'
                                                        >
                                                            {title}
                                                        </span>
                                                    )}

                                                    {(items || []).map((item, index) => {
                                                        return (
                                                            <MenuSubItemComponent
                                                                key={`subitem-${index}`}
                                                                item={item}
                                                                onSubItemClicked={onSubItemClicked}
                                                                sectionTitle={title}
                                                                hasHeader={hasHeader}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div />
                </div>
            </div>
        </>
    );
}

function AlertIcon({ showAlertIcon }) {
    if (!showAlertIcon) {
        return null;
    }

    return (
        <div className='absolute z-12 -top-2.5 left-8 w-3.5 h-3.5 bg-white rounded-full'>
            <IconAlert height={24} width={24} />
        </div>
    );
}

MenuItemComponent.propTypes = {
    children: any,
    icon: object,
    id: string,
    onClick: func,
    parentPath: string,
    title: any
};

export default MenuItemComponent;
