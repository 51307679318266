import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';
import useFetchAuth from 'hooks/useFetchAuth';

const localCache = {};

export function useListView(payload, isUsingDemoData, enabled) {
    const fetchAuth = useFetchAuth();
    const urlParameters = queryString.stringify(payload);
    const queryKey = ['donor_list', urlParameters];
    const queryKeyStringify = JSON.stringify(queryKey);
    let isCached = false;
    if (!!localCache[queryKeyStringify]) {
        isCached = !!localCache[queryKeyStringify].data;
    } else {
        localCache[queryKeyStringify] = {};
    }

    const {
        data = {},
        error,
        isLoading
    } = useQuery(
        queryKey,
        () =>
            fetchAuth(
                `/client_donors/list_builder/filter_donors?${urlParameters}&is_demo_data=${isUsingDemoData}`
            ),
        {
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: !!enabled && !!urlParameters && !isCached,
            onSuccess: (data) => {
                localCache[queryKeyStringify].data = data;
            },
            onError: (err) => {
                console.log(err);
            }
        }
    );
    return {
        isLoading,
        error,
        data
    };
}

export function useListViewForExport(isUsingDemoData) {
    const fetchAuth = useFetchAuth();

    function post(payload) {
        payload['is_export'] = true;
        payload['is_demo_data'] = isUsingDemoData;
        return fetchAuth(`/client_donors/list_builder/export_donors`, {
            method: 'POST',
            body: JSON.stringify(payload)
        });
    }

    const { mutate, isLoading, error, data } = useMutation(post);
    return {
        data,
        error,
        isLoading,
        mutate
    };
}
