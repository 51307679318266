import { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import useUserPool from './useUserPool';
// import useLogin from './useLogin';

const useForgotPassword = () => {
    // const { login } = useLogin();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { userPool } = useUserPool();

    async function resendInvitation(email) {
        const baseUrl = process.env.REACT_APP_BASEURL;
        const response = await fetch(
            `${baseUrl}/invite_user_resend?email=${encodeURIComponent(email)}`,
            {
                method: 'GET'
            }
        );
        await response.json();
    }

    function forgotPassword(email) {
        return new Promise((resolve, reject) => {
            setLoading(true);
            setError('');
            try {
                const userData = {
                    Username: email,
                    Pool: userPool
                };
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.forgotPassword({
                    onSuccess: async function () {
                        await new Promise((resolve) => {
                            cognitoUser.globalSignOut({
                                onSuccess: () => {
                                    resolve();
                                },
                                onFailure: () => {
                                    resolve();
                                }
                            });
                        });

                        return resendInvitation(email)
                            .then(() => {
                                setLoading(false);
                                setError('');
                                return resolve('');
                            })
                            .catch((e) => {
                                setLoading(false);
                                setError('');
                                return resolve('');
                            });
                    },
                    onFailure: function (err) {
                        if (
                            [
                                'User password cannot be reset in the current state.',
                                'Cannot reset password for the user as there is no registered/verified email or phone_number'
                            ].includes(err.message)
                        ) {
                            resendInvitation(email)
                                .then(() => {
                                    if (
                                        err.message ===
                                        'Cannot reset password for the user as there is no registered/verified email or phone_number'
                                    ) {
                                        setLoading(false);
                                        setError('');
                                        return resolve('');
                                    }
                                    setLoading(false);
                                    setError('');
                                    return resolve('SET');
                                })
                                .catch(() => {
                                    setLoading(false);
                                    const errorMessage =
                                        'Error during resend invitation, contact support.';
                                    setError(errorMessage);
                                    return reject(new Error(errorMessage));
                                });
                        } else {
                            setLoading(false);
                            const errorMessage = err.message || JSON.stringify(err);
                            setError(errorMessage);
                            return reject(new Error(errorMessage));
                        }
                    }
                });
            } catch (e) {
                setLoading(false);
                setError(e.message);
                reject(e);
            }
        });
    }

    function confirmPassword(email, verificationCode, password) {
        return new Promise((resolve, reject) => {
            setLoading(true);
            setError('');
            try {
                const userData = {
                    Username: email.trim(),
                    Pool: userPool
                };
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.confirmPassword(verificationCode.trim(), password.trim(), {
                    async onSuccess() {
                        // await login({ email, password });
                        setLoading(false);
                        setError('');
                        return resolve('');
                    },
                    onFailure(err) {
                        setLoading(false);
                        const errorMessage = err.message || JSON.stringify(err);
                        setError(errorMessage);
                        return reject(new Error(errorMessage));
                    }
                });
            } catch (e) {
                setLoading(false);
                setError(e.message);
                reject(e);
            }
        });
    }

    return { error, loading, forgotPassword, confirmPassword };
};

export default useForgotPassword;
