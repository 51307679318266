import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 25}
        height={props.height || 25}
        viewBox='20 10 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M33.9079 12.2761C34.359 11.9348 34.9691 11.9101 35.4431 12.201L35.5586 12.2803L47.4621 21.3815C48.0603 21.8388 48.1744 22.6945 47.7171 23.2927C47.2866 23.8557 46.5033 23.9899 45.914 23.6224L45.8059 23.5477L34.725 15.0757L23.5219 23.5519C22.9568 23.9794 22.1661 23.8996 21.6963 23.388L21.6121 23.2871C21.1845 22.7219 21.2644 21.9312 21.7759 21.4614L21.8769 21.3772L33.9079 12.2761Z'
            fill={props.fill || '#424147'}
        />
        <path
            d='M42.5901 25.405C43.2988 25.405 43.8812 25.9457 43.9472 26.6371L43.9535 26.7684V36.4465C43.9535 37.1551 43.4128 37.7375 42.7214 37.8036L42.5901 37.8098H26.8336C26.1249 37.8098 25.5425 37.2691 25.4765 36.5778L25.4702 36.4465V26.7684C25.4702 26.0154 26.0806 25.405 26.8336 25.405C27.5423 25.405 28.1247 25.9457 28.1908 26.6371L28.197 26.7684L28.1967 35.0822H41.2263L41.2267 26.7684C41.2267 26.0597 41.7674 25.4773 42.4588 25.4113L42.5901 25.405Z'
            fill={props.fill || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
