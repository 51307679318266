import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <mask id='path-1-inside-1' fill='white'>
            <path d='M11 32.1702L17 31.6702V32.1702C17 33.2748 16.1046 34.1702 15 34.1702H13C11.8954 34.1702 11 33.2748 11 32.1702Z' />
        </mask>
        <path
            d='M17 31.6702H20V28.4098L16.7509 28.6805L17 31.6702ZM11.2491 35.1598L17.2491 34.6598L16.7509 28.6805L10.7509 29.1806L11.2491 35.1598ZM14 31.6702V32.1702H20V31.6702H14ZM15 31.1702H13V37.1702H15V31.1702ZM13 31.1702C13.5523 31.1702 14 31.6179 14 32.1702H8C8 34.9316 10.2386 37.1702 13 37.1702V31.1702ZM14 32.1702C14 31.6179 14.4477 31.1702 15 31.1702V37.1702C17.7614 37.1702 20 34.9316 20 32.1702H14Z'
            fill={props.fill || '#424147'}
            mask='url(#path-1-inside-1)'
        />
        <path
            d='M22.2197 17.4302C22.2197 20.5749 20.7106 23.2958 18.4953 24.8342C17.2525 25.6973 15.7872 26.1902 14.2197 26.1902C9.90806 26.1902 6.21973 22.379 6.21973 17.4302C6.21973 12.4814 9.90806 8.67017 14.2197 8.67017C18.5314 8.67017 22.2197 12.4814 22.2197 17.4302Z'
            stroke='#424147'
            strokeWidth='3'
        />
        <path
            d='M11.7425 26.4009L11.8935 26.4323H12.0477H13.9146L11.5704 26.6197L11.249 26.2983L11.7425 26.4009Z'
            stroke='#424147'
            strokeWidth='3'
        />
        <path
            d='M15.2754 13.6501C15.2754 13.6501 17.3865 14.3788 17.3865 16.8901'
            stroke='#424147'
            strokeWidth='2'
            strokeLinecap='round'
        />
        <path
            d='M11 29.1702L17 28.6702V30.6702L11 31.1702V29.1702Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14 0.170166C14.5523 0.170166 15 0.617881 15 1.17017V4.17017C15 4.72245 14.5523 5.17017 14 5.17017C13.4477 5.17017 13 4.72245 13 4.17017V1.17017C13 0.617881 13.4477 0.170166 14 0.170166Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M28.1474 7.85372C28.5505 8.23124 28.5712 8.86407 28.1937 9.26718L26.143 11.4569C25.7655 11.86 25.1327 11.8807 24.7296 11.5032C24.3265 11.1257 24.3057 10.4929 24.6833 10.0898L26.7339 7.90006C27.1114 7.49695 27.7443 7.4762 28.1474 7.85372Z'
            fill={props.fill || '#424147'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.730063 7.85372C0.326952 8.23124 0.306204 8.86407 0.683722 9.26718L2.73439 11.4569C3.11191 11.86 3.74473 11.8807 4.14785 11.5032C4.55096 11.1257 4.5717 10.4929 4.19419 10.0898L2.14352 7.90006C1.766 7.49695 1.13317 7.4762 0.730063 7.85372Z'
            fill={props.fill || '#424147'}
        />
    </svg>
);

export default forwardRef(Icon);
