import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 24}
        height={props.height || 15}
        viewBox='0 0 24 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.2971 1.41185C11.2568 0.518966 12.7432 0.518966 13.7029 1.41185L23.2029 10.2504C24.2138 11.1909 24.2708 12.7728 23.3303 13.7837C22.3899 14.7945 20.808 14.8516 19.7971 13.9111L12 6.65686L4.20291 13.9111C3.19204 14.8516 1.61016 14.7945 0.669674 13.7837C-0.270812 12.7728 -0.213757 11.1909 0.79711 10.2504L10.2971 1.41185Z'
            fill='#FFB282'
        />
    </svg>
);

export default forwardRef(Icon);
