import React, { forwardRef } from 'react';
const Icon = (props, ref) => (
    <svg
        id={props.id}
        width={props.width || '18'}
        height={props.height || '18'}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g>
            <path
                d='M10.5 0V17.8571H17.5V0H10.5ZM0 7.14286V17.8571H7V7.14286H0Z'
                fill={props.fill || 'black'}
            />
        </g>
    </svg>
);
export default forwardRef(Icon);
