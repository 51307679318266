import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import useFetchAuth from 'hooks/useFetchAuth';
import useClientSettings from 'hooks/useClientSettings';
import SLUGS from 'resources/slugs';

const AI_ASSIST_LINKS = {
    links: [
        {
            text: 'Create email subject lines',
            link: '/ai_assist/d8b61d97-6658-474c-8c56-e9eae3a054d6',
            internal: true
        },
        {
            text: 'Create Appeal email (Emergency)',
            link: '/ai_assist/917666a0-3993-4f4c-88bf-9b832044cce6',
            internal: true
        },
        {
            text: 'Create Appeal email (Story-driven)',
            link: '/ai_assist/917666a0-3993-4f4c-88bf-9b832044cce5',
            internal: true
        },
        {
            text: 'Create Thank you email',
            link: '/ai_assist/917666a0-3993-4f4c-88bf-9b832044cce7',
            internal: true
        },
        {
            text: 'Create Welcome journey',
            link: '/ai_assist/7af2363c-a6c4-43bb-81af-dfc16aba0f9c',
            internal: true
        },
        {
            text: 'Convert to multi-channel',
            link: '/ai_assist/7af2363c-a6c4-43bb-81af-dfc16aba0f9a',
            internal: true
        },
        {
            text: 'Tailor to segments',
            link: '/ai_assist/350f5ca2-471e-47a7-a27c-33e439dfe92d',
            internal: true
        },
        {
            text: 'Enhance content',
            link: '/ai_assist/05e4983e-958b-4d23-9f06-2a256b46d7cb',
            internal: true
        }
    ]
};

function useFetchUsefulLinks(page) {
    const { push } = useHistory();
    const fetchAuth = useFetchAuth();
    return useQuery([`links_${page}`], () => fetchAuth(`/useful_links?page=${page}`), {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (!data) {
                Sentry.captureException(new Error('empty result'), {
                    tags: ['useful_links'],
                    extra: {
                        page
                    }
                });
                push(SLUGS.error404);
            }
        },
        onError: (err) => {
            Sentry.captureException(err, {
                tags: ['useful_links'],
                extra: {
                    page
                }
            });
            console.log(err);
            push(SLUGS.error404);
        }
    });
}

export default function useUsefulLinks(page) {
    const {
        data: dataUsefulLinks,
        error,
        isLoading: isLoadingUsefulLinks
    } = useFetchUsefulLinks(page);
    const { data: { initial_product_preference } = {}, isLoading: isLoadingClientSettings } =
        useClientSettings({
            params: 'initial_product_preference'
        });
    const isLoading = isLoadingUsefulLinks || isLoadingClientSettings;
    let data = dataUsefulLinks;
    if (page === 'home' && initial_product_preference === 'ai_assist') {
        data = AI_ASSIST_LINKS;
    }

    return { data, error, isLoading };
}
