import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 24}
        height={props.height || 24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            id='arrow-up'
            d='M1224,360a12,12,0,1,1,12-12A12,12,0,0,1,1224,360Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,1224,338Zm3.58,8.851-2.58-1.743V353a1,1,0,0,1-2,0v-7.892l-2.58,1.743a0.879,0.879,0,0,1-1.3-.406,1.216,1.216,0,0,1,.35-1.518l3.76-2.539a0.193,0.193,0,0,1,.05-0.039,0.893,0.893,0,0,1,.25-0.216v0a0.847,0.847,0,0,1,.37-0.11,0.052,0.052,0,0,0,.02,0l0.03-.006c0.02,0,.03-0.01.05-0.01a0.222,0.222,0,0,1,.05.01l0.03,0.006a0.159,0.159,0,0,0,.03.006,0.858,0.858,0,0,1,.26.052c0.03,0.015.06,0.038,0.1,0.057a1.182,1.182,0,0,1,.19.129c0.03,0.03.05,0.073,0.08,0.108l3.79,2.559a1.216,1.216,0,0,1,.35,1.518A0.879,0.879,0,0,1,1227.58,346.851Z'
            transform='translate(-1212 -336)'
            fill='white'
        />
    </svg>
);

export default forwardRef(Icon);
