import React from 'react';

export function getSettingsSteps() {
    const steps = [
        {
            content: <div>Go to Settings</div>,
            placement: 'top',
            target: '#menu_settings',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            hasSpotlightClicks: true,
            stepStyles: {
                containerWidth: 260
            },
            id: 'settings_1'
        },
        {
            content: (
                <div>Manage your subscription here if you would like to add new propensities</div>
            ),
            placement: 'right',
            target: '#btn_upgrade_subscription',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            id: 'settings_2'
        },
        {
            content: <div>Open up Dataro to your whole team. Why not some collagues now?</div>,
            placement: 'bottom',
            target: '#invite_team_member_bottom_div',
            nextText: 'Skip',
            backText: 'Back',
            disableScrolling: false,
            hasSpotlightClicks: true,
            disableOverlay: true,
            id: 'settings_3'
        },
        {
            content: (
                <div>
                    You’re all set. Select from the menu on the left to start your journey! Or head
                    to our Support section to learn more about Dataro. Happy fundraising!
                </div>
            ),
            placement: 'right',
            target: '#sidebar',
            nextText: 'Finish Tour',
            backText: 'Back',
            hideSkip: true,
            id: 'settings_4'
        }
    ];
    return steps;
}

export function onSettingsStepEffect({ steps = [], stepIndex, localState, theme }) {
    return [() => {}, () => {}];
}
