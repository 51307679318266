import React from 'react';

function MenuComponent({ children }) {
    return (
        <>
            <div
                id='sidebar'
                className='flex flex-col bg-grey-nav-bar w-[100px] mr-[50px] min-h-screen'
            >
                {children}
            </div>
        </>
    );
}

export default MenuComponent;
