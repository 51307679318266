import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || '23'}
        height={props.height || '16'}
        viewBox='0 0 23 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M2 6.38462L9.30769 13.6923L21 2'
            stroke={props.color || '#FFB282'}
            strokeWidth='3'
        />
    </svg>
);

export default forwardRef(Icon);
