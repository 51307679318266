import React, { forwardRef } from 'react';
const Icon = (props, ref) => (
    <svg
        width={props.width || 15}
        height={props.height || 15}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(-1.8,16.5) scale(0.075,-0.075)'
            fill='#ff3668'
            stroke='white'
        >
            <path d='M71 206 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124 -149 87z m59 -66 c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z m0 -60 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z' />
        </g>
    </svg>
);
export default forwardRef(Icon);
