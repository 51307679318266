import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 66}
        height={props.height || 76}
        viewBox='0 0 66 76'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M31.5 12.1458C31.5 17.5922 27.2097 21.9395 22 21.9395C16.7903 21.9395 12.5 17.5922 12.5 12.1458C12.5 6.69934 16.7903 2.35205 22 2.35205C27.2097 2.35205 31.5 6.69934 31.5 12.1458Z'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            d='M39 39.3708C36.0696 31.247 29.0652 22.4575 21.3507 22.4575C12.1436 22.4575 4.13558 36.4572 2.67006 43.9428C1.20453 51.4284 0.242708 60.758 21.3507 60.758C24.1367 60.758 26.5415 60.6212 28.6139 60.3632'
            stroke='#8F8C9D'
            strokeWidth='3'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M46.5 72.4888C55.5923 72.4888 63 65.0587 63 55.8476C63 46.6364 55.5923 39.2063 46.5 39.2063C37.4077 39.2063 30 46.6364 30 55.8476C30 65.0587 37.4077 72.4888 46.5 72.4888ZM46.5 75.4888C57.2696 75.4888 66 66.6951 66 55.8476C66 45 57.2696 36.2063 46.5 36.2063C35.7304 36.2063 27 45 27 55.8476C27 66.6951 35.7304 75.4888 46.5 75.4888Z'
            fill='#8564E7'
        />
        <g
            transform='matrix(0.010444, -0.004219, 0.004256, 0.010533, 30.205166, 48.424023)'
            fill='#000000'
            stroke='none'
        >
            <path
                d='M 1082.618 2056.615 C 1058.618 2032.615 1054 1972 1073 1945 C 1084 1931 1111 1924 1192 1914 C 1402 1889 1540 1822 1686 1676 C 1762 1599 1785 1569 1824 1490 C 1886 1363 1923.05 1256.215 1923.05 1125.215 C 1923.05 993.215 1890 910 1834 791 L 1790 697 L 1724 760 C 1687 796 1655 819 1650 814 C 1641 805 1595.766 345.913 1595.766 322.913 C 1595.766 316.913 1586.384 324.55 1596.384 324.55 C 1628.384 325.55 2038.26 446.563 2049.26 457.563 C 2058.26 466.563 1969 512 1932 552 C 1885 601 1848.047 544.486 1871.648 544.405 C 1935.176 544.186 2028.384 732.495 2049.384 791.495 C 2168.384 1114.495 2094.062 1512.668 1861.062 1762.668 C 1697.062 1939.668 1440.498 2082.384 1203.498 2099.384 C 1124.498 2104.384 1102.618 2076.615 1082.618 2056.615 Z'
                fill='#8564E7'
            />
            <path
                d='M 474.637 1850.613 C 368.637 1821.613 241.542 1798.741 235.542 1792.741 C 227.542 1784.741 303.756 1733.156 340.756 1696.156 L 328.59 1673.283 L 256.521 1585.258 C 50.521 1223.258 133.269 712.552 436.269 421.552 C 596.269 266.552 854.16 159.04 1068.16 145.04 C 1152.16 140.04 1175.717 181.217 1195.717 201.217 C 1221.717 227.217 1196 294 1172 318 C 1158 332 1131 338 1072 343 C 784 365 516.789 526.904 393.789 786.904 C 339.789 901.904 340 1002 340 1133 C 340 1259 363 1360 421 1479 L 461 1564 L 525 1500 C 566 1459 593 1439 599 1445 C 613 1460 722 1861 716 1877 C 708 1896 689.637 1909.613 474.637 1850.613 Z'
                fill='#8564E7'
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
