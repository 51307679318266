import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 47}
        height={props.height || 47}
        viewBox='0 0 47 47'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M23.28 46.5581C10.4446 46.5581 0 36.1154 0 23.2781C0.00195008 10.4427 10.4446 0 23.28 0C36.1154 0 46.56 10.4427 46.56 23.28C46.56 36.1154 36.1154 46.5581 23.28 46.5581ZM23.28 3.58034C12.4181 3.58034 3.58229 12.4161 3.58229 23.2781C3.58229 34.138 12.4181 42.9758 23.28 42.9758C34.14 42.9758 42.9777 34.14 42.9777 23.2781C42.9777 12.4181 34.14 3.58034 23.28 3.58034Z'
            fill={props.fill || '#4BB543'}
        />
        <path
            d='M20.2079 30.8893C19.7496 30.8893 19.2913 30.7138 18.9423 30.3647L13.4587 24.8791C12.7586 24.181 12.7586 23.048 13.4587 22.3479C14.1568 21.6479 15.2898 21.6479 15.9898 22.3479L20.2079 26.566L30.5803 16.1935C31.2784 15.4954 32.4114 15.4954 33.1135 16.1935C33.8116 16.8936 33.8116 18.0266 33.1135 18.7267L21.4735 30.3667C21.1244 30.7138 20.6661 30.8893 20.2079 30.8893Z'
            fill={props.fill || '#4BB543'}
        />
    </svg>
);

export default forwardRef(Icon);
