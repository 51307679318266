import React, { useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Modal from 'react-modal';
import { Column, Row } from 'components/simpleFlexbox';
import useClientSettings from 'hooks/useClientSettings';
import useCRMTypes from 'hooks/useCrmTypes';
import useUserDeclaredDetails from 'hooks/useUserOrganisationDetails';
import useUserActivity from 'hooks/useUserActivity';
import { IconError } from 'assets/icons';
import COUNTRIES from 'resources/countries';
import FormComponent from 'components/form/FormComponent';
import FormInputComponent from 'components/form/input/FormInputComponent';
import FormSelectComponent from 'components/form/select/FormSelectComponent';
import LoadingComponent from 'components/loading';
import ButtonComponent from 'components/form/ButtonComponent';

const useStyles = createUseStyles((theme) => ({
    leftBar: {
        width: 100,
        background: '#FFEBE8',
        borderRadius: '10px 0px 0px 10px'
    },
    container: {
        padding: '90px 70px 35px 70px',
        background: '#FFFFFF',
        borderRadius: '0px 10px 10px 0px',
        width: '100%'
    },
    formSection: {
        marginBottom: 16,
        width: '100%',
        maxWidth: 512,
        alignSelf: 'flex-start',
        '& > div': {
            marginTop: 20
        }
    },
    title: {
        ...theme.typography.title,
        textAlign: 'center',
        marginBottom: 20
    },
    modalText: {
        textAlign: 'center'
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        container: {
            padding: '40px 30px 30px 40px'
        },
        formSection: {
            '&& input#client_name_external': {
                height: '40px'
            },
            '&& .select-container > select': {
                height: '40px'
            },
            '&& > .input-text-container > label': {
                marginBottom: 15
            },
            '&& > .select-container > label': {
                marginBottom: 15
            },
            '& > div': {
                marginTop: '10px !important'
            }
        },
        '#non_fi_free_modal_main_window': {
            width: '80%'
        },
        leftBar: {
            width: 75
        }
    }
}));

function NonFiFreeWelcomeModal({ callback }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const { data: { first_name } = {}, isLoading: isLoadingClientSettings } = useClientSettings({
        params: 'first_name'
    });
    const { isLoading: isLoadingCRMTypes, data: crm_types_prop = [] } = useCRMTypes();
    const {
        isLoadingUpdatingDetails: isLoadingUpdateUserDetails,
        errorUpdatingDetails: errorUpdateUser,
        addToUserOrgDetails
    } = useUserDeclaredDetails();
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const crm_types = crm_types_prop.filter((t) => !!t.onboarding_order);
    const isLoading =
        isLoadingClientSettings || isLoadingUpdateUserDetails || isLoadingCRMTypes || isLoadingForm;
    const showError = errorUpdateUser;

    const { addUserActivity } = useUserActivity();

    async function onSubmit({
        declared_country,
        declared_organisation_name,
        declared_crm_type,
        about_us_source,
        job_title,
        estimated_donors_count
    }) {
        const { name: country } = COUNTRIES.find((c) => c.code === declared_country);
        const payload = {
            declared_country: country,
            declared_organisation_name,
            declared_crm_type,
            about_us_source,
            job_title,
            estimated_donors_count
        };
        setIsLoadingForm(true);
        await addToUserOrgDetails(payload);
    }

    useEffect(() => {
        try {
            addUserActivity({
                event_name: 'onboarding_tour',
                event_payload: {
                    modal_name: 'organisation_details',
                    modal_component: 'NonFiFreeWelcomeModal'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            isOpen
            onRequestClose={() => {}}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 968,
                    maxHeight: 880,
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            id='non_fi_free_modal_main_window'
            ariaHideApp={false}
        >
            <Row>
                <div className={classes.leftBar}></div>
                <Column className={classes.container}>
                    <FormSection
                        crm_types={crm_types}
                        errorUpgradeAccount={errorUpdateUser}
                        first_name={first_name}
                        onSubmit={onSubmit}
                        showError={showError}
                    />
                </Column>
            </Row>
            <LoadingComponent loading={isLoading} />
        </Modal>
    );
}

function FormSection({ crm_types, errorUpgradeAccount, first_name, onSubmit, showError }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const [selectedCRM, setSelectedCRM] = useState('');
    const showCRMTypeInputText = selectedCRM === 'other';

    function onLocalSubmit(values) {
        const { declared_crm_type, declared_crm_type_extra, ...otherValues } = values;
        const payload = {
            ...otherValues,
            declared_crm_type: showCRMTypeInputText
                ? `other: ${declared_crm_type_extra}`
                : declared_crm_type
        };
        onSubmit(payload);
    }

    return (
        <FormComponent onSubmit={onLocalSubmit}>
            <Column vertical='center' style={{ width: '100%' }}>
                <h2 className={classes.title} id='fi_modal_tour'>
                    Tell us about your organization
                </h2>
                {!errorUpgradeAccount ? (
                    <>
                        <div className={classes.modalText}>
                            We can’t wait to show you Dataro. Before diving in, help us learn more
                            about you
                        </div>
                        <Row flexGrow={1} style={{ columnGap: 30 }}>
                            <Column
                                className={classes.formSection}
                                flexGrow={1}
                                style={{ rowGap: 10 }}
                            >
                                <FormInputComponent
                                    label='Organization name'
                                    required='Please provide your organization name'
                                    name='declared_organisation_name'
                                    type='text'
                                    showErrorMessageBelow
                                />
                                <FormSelectComponent
                                    label='Select your CRM'
                                    name='declared_crm_type'
                                    required='Please select a CRM'
                                    autoComplete='off'
                                    style={{ height: 50 }}
                                    showErrorMessageBelow
                                    onChange={(e) => setSelectedCRM(e.target.value)}
                                >
                                    {[
                                        { label: '', value: '' },
                                        ...crm_types.map(({ crm_type, crm_name }) => ({
                                            label: crm_name,
                                            value: crm_type
                                        })),
                                        { label: 'Other', value: 'other' }
                                    ].map((p) => (
                                        <option key={`status-${p.value}`} value={p.value}>
                                            {p.label}
                                        </option>
                                    ))}
                                </FormSelectComponent>
                                {showCRMTypeInputText && (
                                    <FormInputComponent
                                        label='CRM Type'
                                        required='Please provide your CRM Type'
                                        placeholder='Please provide the name of your CRM'
                                        name='declared_crm_type_extra'
                                        type='text'
                                        showErrorMessageBelow
                                    />
                                )}
                                <FormSelectComponent
                                    label='Select your Country'
                                    name='declared_country'
                                    required='Please select a Country'
                                    autoComplete='off'
                                    style={{ height: 50 }}
                                    showErrorMessageBelow
                                >
                                    {[
                                        { label: '', value: '' },
                                        ...COUNTRIES.map(({ name: label, code: value }) => ({
                                            label,
                                            value
                                        }))
                                    ].map((p) => (
                                        <option key={`country-${p.value}`} value={p.value}>
                                            {p.label}
                                        </option>
                                    ))}
                                </FormSelectComponent>
                            </Column>
                            <Column
                                className={classes.formSection}
                                flexGrow={1}
                                style={{ rowGap: 10 }}
                            >
                                <FormInputComponent
                                    label='How did you hear about us?'
                                    required='Please let us know how you heard about us'
                                    name='about_us_source'
                                    type='text'
                                    showErrorMessageBelow
                                />
                                <FormInputComponent
                                    label='Job title'
                                    required='Please let us know your job title'
                                    name='job_title'
                                    type='text'
                                    showErrorMessageBelow
                                />
                                <FormInputComponent
                                    label='Estimated number of donors'
                                    required='Please let us know the number of donors'
                                    name='estimated_donors_count'
                                    type='number'
                                    tooltipInfo='Estimate of total number of donors (active and inactive) in your CRM'
                                    showErrorMessageBelow
                                />
                            </Column>
                        </Row>
                        <Row
                            horizontal={showError ? 'space-between' : 'center'}
                            vertical='center'
                            style={{ marginTop: 12 }}
                            id='fi-modal-button-div'
                        >
                            {showError && (
                                <div className='upgrade-error-message'>
                                    <span>
                                        {showError} <IconError />
                                    </span>
                                </div>
                            )}
                            <ButtonComponent width={260} type='submit'>
                                Next
                            </ButtonComponent>
                        </Row>
                    </>
                ) : (
                    <>
                        <div className='upgrade-error-message'>
                            We are not able to update your organisation details at this time. <br />{' '}
                            Please contact integrations@dataro.io
                            <span style={{ marginLeft: 8 }}>
                                <IconError />
                            </span>
                        </div>
                    </>
                )}
            </Column>
        </FormComponent>
    );
}

export default NonFiFreeWelcomeModal;
