import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            className='st0'
            d='M27,30H5c-1.1,0-2-0.9-2-2V14c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2v14C29,29.1,28.1,30,27,30z'
            stroke={props.fill || '#BFBFBF'}
        />
        <polyline className='st0' points='7,4 13,12 21,2 ' stroke={props.fill || '#BFBFBF'} />
        <line className='st0' x1='21' y1='12' x2='21' y2='30' stroke={props.fill || '#BFBFBF'} />
        <circle className='st0' cx='25' cy='17' r='1' stroke={props.fill || '#BFBFBF'} />
        <circle cx='25' cy='24' r='1' stroke={props.fill || '#BFBFBF'} />
        <circle cx='25' cy='21' r='1' stroke={props.fill || '#BFBFBF'} />
    </svg>
);

export default forwardRef(Icon);
