import React from 'react';
import { enableJustOneMenuItem, enableAllMenuItems } from '../utilities';

const functionsAttached = {};

export function getCampaignResultsSteps({ classes }) {
    const steps = [
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Now it’s time to run your campaign</div>
                    <div>
                        Once your campaign has launched go to the Reporting tab to get live results.
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#menu_predict',
            nextText: 'Next',
            backText: 'Back',
            id: 'campaign_results_1',
            disableScrolling: true,
            disableOverlay: true,
            hasSpotlightClicks: true
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Easily find your results</div>
                    <div>
                        Here’s where you can find results on all your prior campaigns - you can sort
                        by campaign type, date and name
                        <br />
                        For this demo, we will review a DM Mail Appeal campaign.
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#filter_campaign_container',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            disableOverlay: true,
            hasSpotlightClicks: true,
            id: 'campaign_results_3',
            stepStyles: {
                skipButtonRight: 10,
                containerWidth: 600,
                containerMaxWidth: 600
            }
        },
        {
            content: (
                <div>
                    <div className={classes.smallTitle}>Live fundraising results</div>
                    <div>
                        See how your past campaigns performed and how many donors were targeted.
                        <br />
                        Quickly see the total raised ($), # of donors, ROI, average gift ($),
                        response rates (%) and more. Scroll down to see results from A/B
                        experiments, accuracy of the AI predictions, adjust campaign costs and more
                        <br />
                        Your current campaign data is updated daily so you get LIVE results!
                        <br />
                        <br />
                    </div>
                </div>
            ),
            placement: 'top',
            target: '#report_overview_container',
            nextText: 'Next',
            backText: 'Back',
            disableScrolling: true,
            stepStyles: {
                containerWidth: 600,
                containerMaxWidth: 600
            },
            id: 'campaign_results_6'
        }
    ];
    return steps;
}

export function onCampaignResultsStepEffect({
    steps = [],
    stepIndex,
    theme,
    localState,
    setSteps
}) {
    const currentStep = steps[stepIndex] || {};
    const btnNextSelector = '#btn_onboarding_next';
    // const menuSelector = '#menu_predict';
    const subMenuCampaignResultsSelector = '#submenu_campaigns';
    const campaignLinkSelector = '.campaigns-results-top-title-DM_Appeal-experiment';
    const filterSelector = '#filter_campaign_type_dm_appeal';

    function onNextClick() {
        const nextBtnElement = document.querySelector(btnNextSelector);
        nextBtnElement && nextBtnElement.click();
    }

    function enableNextButton() {
        setSteps((prev) => {
            const newSteps = [...prev];
            newSteps[stepIndex].disableNext = false;
            return newSteps;
        });
    }

    return [
        () => {
            if (currentStep.id === 'campaign_results_1' && localState['campaign_results_1']) {
                enableJustOneMenuItem({
                    mainSelector: '#menu_predict',
                    selector: subMenuCampaignResultsSelector,
                    onNextClick,
                    functionsAttached
                });
            }

            if (currentStep.id === 'campaign_results_3' && localState['campaign_results_3']) {
                document.querySelector('#main_container').style.marginTop = '120px';
                let campaignsFilterElement = document.querySelector(filterSelector);
                if (campaignsFilterElement) {
                    campaignsFilterElement.addEventListener('click', enableNextButton);
                    functionsAttached[filterSelector] = enableNextButton;
                } else {
                    setTimeout(() => {
                        campaignsFilterElement = document.querySelector(filterSelector);
                        campaignsFilterElement &&
                            campaignsFilterElement.addEventListener('click', enableNextButton);
                        functionsAttached[filterSelector] = enableNextButton;
                    }, 2000);
                }
            }
        },
        () => {
            if (currentStep.id === 'campaign_results_3') {
                enableAllMenuItems({
                    mainSelector: '#menu_predict',
                    selector: subMenuCampaignResultsSelector,
                    functionsAttached
                });
            }

            if (currentStep.id === 'campaign_results_6') {
                document.querySelector('#main_container').style.marginTop = '60px';
                const campaignsFilterElement = document.querySelector(filterSelector);
                if (campaignsFilterElement) {
                    campaignsFilterElement.removeEventListener(
                        'click',
                        functionsAttached[filterSelector]
                    );
                }
                const campaignLinkElement = document.querySelector(campaignLinkSelector);
                if (campaignLinkElement) {
                    campaignLinkElement.removeEventListener(
                        'click',
                        functionsAttached[campaignLinkSelector]
                    );
                }
            }
        }
    ];
}
