import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        id={props.id}
        width={props.width || '24'}
        height={props.height || '24'}
        viewBox='0 0 24 24'
        strokeWidth={1}
        fill='#424147'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(0.000000,25.000000) scale(0.09,-0.09)'
            fill='#424147'
            stroke='none'
        >
            <path d='M127 252 c-21 -23 -22 -36 -2 -28 11 4 15 -1 15 -19 0 -14 5 -25 10 -25 6 0 10 11 10 25 0 18 4 23 15 19 21 -8 19 9 -3 29 -23 21 -25 21 -45 -1z' />
            <path d='M45 170 c-17 -19 -17 -21 0 -40 21 -24 40 -26 31 -5 -4 11 1 15 19 15 14 0 25 5 25 10 0 6 -11 10 -25 10 -18 0 -23 4 -19 15 9 21 -10 19 -31 -5z' />
            <path d='M224 175 c4 -11 -1 -15 -19 -15 -14 0 -25 -4 -25 -10 0 -5 11 -10 25 -10 18 0 23 -4 19 -15 -9 -21 10 -19 31 5 17 19 17 21 0 40 -21 24 -40 26 -31 5z' />
            <path d='M140 95 c0 -18 -4 -23 -15 -19 -21 9 -19 -10 5 -31 19 -17 21 -17 40 0 24 21 26 40 5 31 -11 -4 -15 1 -15 19 0 14 -4 25 -10 25 -5 0 -10 -11 -10 -25z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
