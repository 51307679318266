import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 15}
        height={props.height || 9}
        viewBox={props.viewBox || '0 0 15 9'}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M2 2L7.71429 7L13.4286 2'
            stroke={props.fill || '#424147'}
            strokeWidth={props.strokeWidth || '3'}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default forwardRef(Icon);
