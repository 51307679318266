import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 29}
        viewBox='0 0 24 24'
        fill='none'
        stroke={props.fill || '#BFBFBF'}
        strokeWidth='1'
        strokeLinecap='round'
        strokeLinejoin='round'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <circle cx='12' cy='12' r='10'></circle>
        <line x1='2' y1='12' x2='22' y2='12'></line>
        <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z'></path>
    </svg>
);

export default forwardRef(Icon);
