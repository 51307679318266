export function enableJustOneMenuItem({ selector, mainSelector, onNextClick, functionsAttached }) {
    const subMenuItems = Array.from(document.querySelectorAll(`[id^="submenu_"]`));
    for (let index = 0; index < subMenuItems.length; index++) {
        const menuItem = subMenuItems[index];
        const itemId = menuItem.getAttribute('id');
        if (`#${itemId}` === selector) {
            menuItem.dispatchEvent(new Event('mouseover'));
            menuItem.addEventListener('click', onNextClick);
            functionsAttached[selector] = onNextClick;
            continue;
        }
        menuItem && (() => (menuItem.className += ' submenu-disabled'))();
    }

    const menuItems = Array.from(document.querySelectorAll(`[id^="menu_"]`));
    for (let index = 0; index < menuItems.length; index++) {
        const menuItem = menuItems[index];
        const itemId = menuItem.getAttribute('id');
        if (`#${itemId}` === mainSelector) {
            continue;
        }
        menuItem && (() => (menuItem.className += ' menu-disabled'))();
    }
}

export function enableAllMenuItems({ selector, mainSelector, functionsAttached }) {
    const subMenuItems = Array.from(document.querySelectorAll(`[id^="submenu_"]`));
    for (let index = 0; index < subMenuItems.length; index++) {
        const menuItem = subMenuItems[index];
        const itemId = menuItem.getAttribute('id');
        if (`#${itemId}` === selector) {
            menuItem.removeEventListener('click', functionsAttached[selector]);
            continue;
        }
        menuItem.className = menuItem.className.replace('submenu-disabled', '').trim();
    }

    const menuItems = Array.from(document.querySelectorAll(`[id^="menu_"]`));
    for (let index = 0; index < menuItems.length; index++) {
        const menuItem = menuItems[index];
        const itemId = menuItem.getAttribute('id');
        if (`#${itemId}` === mainSelector) {
            continue;
        }
        menuItem.className = menuItem.className.replace('menu-disabled', '').trim();
    }
}
