import React from 'react';
import { Column } from 'components/simpleFlexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { IconNotes } from 'assets/icons';

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        fontSize: 36,
        fontWeight: '700',
        lineHeight: '57px',
        letterSpacing: '-0.473214px'
    },
    content: {
        textAlign: 'center'
    },
    iconContainer: {
        backgroundColor: theme.color.softOrange,
        borderRadius: 4,
        padding: '12px 12px 10px 16px',
        margin: '12px 0px'
    }
}));

export default function AIAssistWelcomeComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <Column horizontal='center' vertical='center' style={{ width: '100%' }}>
            <h2 className={classes.title} id='ai_assist_modal'>
                Welcome to AI Assist
            </h2>
            <p className={classes.content}>
                Get ready, you're almost ready to leverage AI to create fundraising content faster
                than ever before.
            </p>
            <p className={classes.content}>
                {' '}
                To get started, fill in your nonprofit details in AI Assist Settings.
            </p>
            <div className={classes.iconContainer}>
                <IconNotes />
            </div>
            <p className={classes.content}>
                Remember, all AI Assist content generation is available by selecting this icon in
                the menu bar on the left
            </p>
        </Column>
    );
}
