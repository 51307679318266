import React, { forwardRef } from 'react';

const Icon = (props = {}, ref) => (
    <svg
        width={props.width || 22}
        height={props.height || 22}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(4,15.8) scale(0.048,-0.05)'
            fill='#000000'
            stroke='none'
        >
            <path d='M69 185 c-14 -8 -34 -26 -43 -40 -15 -23 -15 -27 0 -51 19 -29 63 -54 94 -54 31 0 75 25 94 54 15 24 15 28 0 52 -33 49 -95 66 -145 39z m85 -31 c31 -30 9 -84 -34 -84 -10 0 -26 7 -34 16 -31 30 -9 84 34 84 10 0 26 -7 34 -16z' />
            <path d='M100 134 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3 32 -16 20 -24 20 -40 0z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
