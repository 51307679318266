import React, { forwardRef } from 'react';

const Icon = (props = {}, ref) => (
    <svg
        width={props.width || 18}
        height={props.height || 18}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ verticalAlign: 'middle' }}
    >
        <g
            xmlns='http://www.w3.org/2000/svg'
            transform='translate(4,15.8) scale(0.008,-0.008)'
            fill='#000000'
            stroke='none'
        >
            <path d='M189 1811 l-29 -29 0 -782 0 -782 29 -29 29 -29 782 0 782 0 29 29 29 29 0 407 0 407 -29 29 c-40 39 -82 39 -122 0 l-29 -29 0 -346 0 -346 -660 0 -660 0 0 660 0 660 346 0 346 0 29 29 c39 40 39 82 0 122 l-29 29 -407 0 -407 0 -29 -29z' />
            <path d='M1293 1830 c-45 -18 -59 -99 -24 -141 18 -22 27 -24 142 -27 l122 -4 -312 -312 c-282 -283 -311 -315 -311 -345 0 -45 46 -91 91 -91 30 0 62 29 345 311 l312 312 4 -122 c3 -115 5 -124 27 -142 30 -25 92 -25 122 0 24 19 24 20 27 265 l3 247 -30 29 -29 30 -234 -1 c-128 0 -243 -4 -255 -9z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
