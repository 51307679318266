const SECTIONS = {
    home: 'home',
    campaigns: 'campaigns',
    campaignSize: 'campaign_size',
    createCampaign: 'create_campaign',
    donorList: 'donor_list',
    connections: 'connections',
    predictions: 'predictions',
    performance: 'performance',
    fundraisingIntelligence: 'fundraising_intelligence',
    fundraisingIntelligenceDashboards: 'fundraising_intelligence_dashboards',
    fundraisingIntelligenceReports: 'fundraising_intelligence_reports',
    aiAnalyst: 'ai_analyst',
    settings: 'settings',
    support: 'support',
    dataInspector: 'data_inspector',
    dataRecordMapping: 'record_mapping',
    campaignTagging: 'campaign_tagging',
    dataHub: 'data_hub',
    predict: 'predict',
    donors: 'donors_view',
    aiAssist: 'ai_assist',
    savedDonorLists: 'saved_donor_lists',
    donorsAndSegments: 'donors_and_segments',
    donorsAndSegmentsMajorDonor: 'donors_and_segments_major_donor',
    donorsAndSegmentsMidValue: 'donors_and_segments_mid_value_donor',
    fundraisingInsights: 'fundraising_insights',
    campaignInsights: 'campaign_insights',
    donorsAndSegmentsSmartAudience: 'donors_and_segments_smart_audiences'
};

export default SECTIONS;
