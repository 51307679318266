import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Modal from 'react-modal';
import useClientSettings from 'hooks/useClientSettings';
import useExternalScripts from 'hooks/useExternalScripts';
import useUserActivity from 'hooks/useUserActivity';
import { Column, Row } from 'components/simpleFlexbox';
import ButtonComponent from 'components/form/ButtonComponent';

const useStyles = createUseStyles((theme) => ({
    container: {
        width: '100%',
        height: '680px'
    },
    title: {
        fontSize: 20,
        textAlign: 'justify',
        padding: '6px 22px 0px 22px'
    },
    firstColumn: {
        flexGrow: 1
    },
    buttonContainer: {
        width: '100%'
    },
    '@media screen and (max-width: 1200px), screen and (max-height: 800px)': {
        '#non_fi_free_modal_main_window': {
            width: '80%'
        }
    },
    link: {
        textAlign: 'center',
        textDecorationLine: 'underline',
        textUnderlineOffset: '2px',
        fontSize: 16,
        cursor: 'pointer'
    }
}));

function CalendarIframeModal({ onNext }) {
    const theme = useTheme();
    const classes = useStyles({ index: 0, theme });
    const { search } = useLocation();
    const { push } = useHistory();
    const { data = {} } = useClientSettings({ params: 'initial_product_preference' });
    const queryParsed = queryString.parse(search);
    const [isOpen, setIsOpen] = useState(true);

    const { addUserActivity } = useUserActivity();
    useEffect(() => {
        try {
            addUserActivity({
                event_name: 'onboarding_tour',
                event_payload: {
                    modal_name: 'book_meeting',
                    modal_component: 'CalendarIframeModal'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useExternalScripts({
        id: 'hs-meetings-script-loader',
        defer: true,
        url: 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
        include: isOpen
    });

    function onClickClose() {
        if (!data.initial_product_preference) {
            return;
        }
        delete queryParsed.showCalendar;
        push({
            search: queryString.stringify(queryParsed)
        });
        setIsOpen(false);
    }

    function onClickNext(e) {
        if (queryParsed.showCalendar) {
            delete queryParsed.showCalendar;
            push({
                search: queryString.stringify(queryParsed)
            });
            setIsOpen(false);
        } else {
            onNext(e);
        }
    }

    useEffect(() => {
        if (queryParsed.showCalendar) {
            setIsOpen(true);
        }
    }, [queryParsed]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClickClose}
            style={{
                overlay: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                },
                content: {
                    padding: 0,
                    maxWidth: 1060,
                    height: '800px',
                    borderRadius: 10,
                    position: 'relative',
                    top: '',
                    bottom: '',
                    left: '',
                    right: '',
                    width: '100%',
                    marginTop: 30,
                    marginBottom: 30
                }
            }}
            id='meeting_iframe'
            ariaHideApp={false}
        >
            <Column style={{ height: '100%', marginTop: 30 }}>
                <Row horizontal='space-between' className={classes.container}>
                    <div className={classes.firstColumn}> </div>
                    <div
                        className='meetings-iframe-container'
                        style={{ flexGrow: 1, width: '100%', minWidth: 880 }}
                        data-src='https://meetings.hubspot.com/timatdataro/book-a-demo-app?embed=true'
                    ></div>
                </Row>
                <Row horizontal='center' className={classes.buttonContainer}>
                    <Column>
                        <ButtonComponent width={164} onClick={onClickNext}>
                            Next
                        </ButtonComponent>
                        <span className={classes.link} onClick={onClickNext}>
                            Skip now
                        </span>
                    </Column>
                </Row>
            </Column>
        </Modal>
    );
}

export default CalendarIframeModal;
