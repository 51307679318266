import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || '27'}
        height={props.height || '19'}
        viewBox='0 0 27 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <path
            d='M25 16.4106L13.3524 2.32614L2 16.4106'
            stroke={props.fill || '#8564E7'}
            strokeWidth={props.strokeWidth || '4'}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default forwardRef(Icon);
