import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        fill='none'
        stroke='currentcolor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        viewBox='0 0 24 24'
        height='1em'
        width='1em'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
        {...props}
    >
        <path stroke='none' d='M0 0h24v24H0z' />
        <path d='M21 12v3a1 1 0 01-1 1H4a1 1 0 01-1-1V5a1 1 0 011-1h9M7 20h10M9 16v4M15 16v4M17 4h4v4M16 9l5-5' />
    </svg>
);

export default forwardRef(Icon);
