import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 30}
        height={props.height || 30}
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g transform='translate(10, 5)' fill={props.color || '#424147'} stroke='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.99532 6.59406C2.92988 7.41552 2.2133 8.03114 1.39027 7.97073C0.564066 7.9101 -0.0565478 7.19117 0.00409108 6.36496L1.50007 6.47476C0.00409102 6.36496 0.00414699 6.3642 0.00420457 6.36343L0.00432855 6.36177L0.00460857 6.35811L0.00530332 6.34937L0.0072785 6.32635C0.00887352 6.30859 0.0110517 6.286 0.0139577 6.25891C0.0197651 6.20477 0.0285065 6.13238 0.0413601 6.04433C0.0670018 5.86867 0.10939 5.62804 0.178437 5.3436C0.315115 4.78056 0.563942 4.0139 1.01779 3.23338C1.96496 1.60445 3.77541 0 6.92026 0C10.2806 0 12.3648 2.33694 13.0518 4.87524C13.7192 7.34132 13.1834 10.4023 10.9281 12.1784C9.72797 13.1236 8.98094 14.2882 8.53208 15.2371C8.30901 15.7088 8.16477 16.1164 8.07794 16.3987C8.03466 16.5394 8.00605 16.6477 7.98928 16.7157C7.98091 16.7496 7.97552 16.7733 7.97276 16.7858L7.97066 16.7956C7.80815 17.6046 7.0221 18.1309 6.21148 17.972C5.39853 17.8126 4.86871 17.0244 5.02809 16.2114L6.50007 16.5C5.02809 16.2114 5.02819 16.2109 5.0283 16.2103L5.02903 16.2067L5.03022 16.2007L5.0334 16.1852C5.03588 16.1733 5.03908 16.1583 5.04306 16.1402C5.05101 16.1041 5.06209 16.0559 5.07671 15.9967C5.10591 15.8784 5.14937 15.7156 5.21048 15.5169C5.33242 15.1204 5.5262 14.5759 5.82014 13.9544C6.40531 12.7172 7.40828 11.1318 9.07201 9.82156C10.1449 8.97664 10.5968 7.28761 10.156 5.65896C9.73475 4.10253 8.61502 3 6.92026 3C5.01004 3 4.11039 3.88292 3.61123 4.74138C3.34192 5.20454 3.18352 5.68157 3.09377 6.0513C3.0496 6.23325 3.02397 6.38127 3.0099 6.47767C3.0029 6.52564 2.99886 6.56014 2.99685 6.57887L2.99532 6.59406Z'
                fill={props.fill || '#424147'}
            />
            <circle cx='6.5' cy='21.5' r='2' fill={props.fill || '#424147'} />
        </g>
    </svg>
);

export default forwardRef(Icon);
