import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 32}
        height={props.height || 32}
        viewBox='0 0 39 39'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g
            transform='translate(0.000000,39.000000) scale(0.100000,-0.100000)'
            fill={props.fill || '#BFBFBF'}
            stroke='none'
        >
            <path
                d='M285 330 c-4 -6 3 -22 16 -36 l22 -24 -151 0 c-128 0 -152 -2 -152
-15 0 -13 24 -15 152 -15 l151 0 -23 -25 c-16 -17 -20 -29 -14 -35 7 -7 24 5
52 33 l42 43 -44 43 c-29 28 -46 39 -51 31z'
            />
            <path
                d='M52 177 l-42 -43 44 -43 c29 -28 46 -39 51 -31 4 6 -3 22 -16 36
l-22 24 151 0 c128 0 152 2 152 15 0 13 -24 15 -152 15 l-151 0 23 25 c16 17
20 29 14 35 -7 7 -24 -5 -52 -33z'
            />
        </g>
    </svg>
);

export default forwardRef(Icon);
