import React, { forwardRef } from 'react';

const Icon = (props, ref) => (
    <svg
        width={props.width || 24}
        height={props.height || 24}
        viewBox='0 0 512 512'
        fill={props.fill || '#000000'}
        stroke={props.fill || '#000000'}
        strokeWidth={8}
        xmlns='http://www.w3.org/2000/svg'
        ref={ref}
    >
        <g transform='matrix(0.9645410776138307, 0, 0, 0.9605860710144044, 2.409996032714851, 8.42669582366944)'>
            <path d='M442.539,73.946c-44.84-37.927-108.949-60.084-187.344-60.066c-74.388,0-137.905,20.981-183.328,58.411 c-22.702,18.686-40.758,41.492-53.06,67.428C6.489,165.648-0.019,194.641,0,225.422c-0.019,37.214,7.768,71.586,23.511,101.577 c14.99,28.664,37.297,53.04,65.699,72.103c-6.715,28.269-16.157,68.67-16.157,68.689c-0.432,1.815-0.658,3.706-0.639,5.624 c-0.018,7.59,3.518,14.897,9.667,19.608l0.038,0.028v0.01c4.402,3.348,9.705,5.06,15.01,5.06c3.988,0,8.031-0.969,11.68-2.934 l0.358-0.188l0.357-0.216c0.019-0.009,5.079-3.01,12.866-7.618c11.661-6.903,29.435-17.407,45.291-26.745 c7.919-4.665,15.367-9.038,21.31-12.508c5.924-3.46,10.476-6.103,12.17-7.034l0.075-0.047l0.112-0.065 c22.1-12.527,39.705-14.634,53.849-14.709c58.702-0.085,121.212-11.464,171.13-42.62c24.902-15.602,46.57-36.413,61.806-63.056 c15.272-26.624,23.886-58.853,23.868-96.16C512.112,165.545,487.416,111.78,442.539,73.946z M454.709,301.24 c-8.822,15.376-20.37,28.316-34.232,39.282c-20.784,16.429-46.871,28.288-75.479,35.934c-28.598,7.664-59.652,11.088-89.802,11.078 c-18.508-0.075-44.369,3.414-72.752,19.684v-0.01c-3.141,1.749-10.137,5.822-19.354,11.219 c-13.222,7.759-30.62,18.019-45.14,26.614c3.536-15.094,7.656-32.576,10.721-45.413v-0.019c0.508-2.144,0.884-4.468,0.884-7.11 c0.038-3.724-0.922-8.266-3.065-12.046c-1.6-2.831-3.65-5.06-5.436-6.583c-2.746-2.285-4.796-3.273-5.944-3.903l-1.166-0.592 l-0.132-0.075c-25.147-16.204-43.655-36.329-56.18-60.188c-12.527-23.897-19.11-51.781-19.11-83.69 c0-25.401,5.285-48.526,15.084-69.187c14.708-30.94,39.667-56.605,73.786-74.896c34.082-18.254,77.398-28.937,127.805-28.937 c71.284,0.019,125.933,20.031,162.46,50.952c36.47,31.025,55.692,72.799,55.824,120.864 C473.442,255.723,466.407,280.729,454.709,301.24z' />
            <path d='M135.055,197.171c-16.278,0-29.472,13.204-29.472,29.483c0,16.278,13.194,29.473,29.472,29.473 c16.288,0,29.482-13.194,29.482-29.473C164.538,210.375,151.344,197.171,135.055,197.171z' />
            <path d='M250.024,197.171c-16.28,0-29.474,13.204-29.474,29.483c0,16.278,13.194,29.473,29.474,29.473 c16.288,0,29.482-13.194,29.482-29.473C279.506,210.375,266.312,197.171,250.024,197.171z' />
            <path d='M365,197.171c-16.278,0-29.482,13.204-29.482,29.483c0,16.278,13.204,29.473,29.482,29.473 c16.288,0,29.484-13.194,29.484-29.473C394.484,210.375,381.288,197.171,365,197.171z' />
        </g>
    </svg>
);

export default forwardRef(Icon);
